import classNames from 'classnames';
import { ReactElement, forwardRef, useEffect, useState } from 'react';

import { Heading, HEADING_SIZE } from 'src/components/atoms/heading/Heading';
import { DynamicListItem } from 'src/components/common/dynamic-list/DynamicList';
import styles from 'src/components/common/dynamic-list/DynamicList.module.scss';
import { DynamicListSVG } from 'src/components/common/dynamic-list/DynamicListSVG';
import { HEADING_TAG } from 'src/data/enums/Heading';

type MobileHeaderProps = {
  heading: string;
  subtitle: string;
  activeSlide: number;
  items: Array<DynamicListItem>;
};

// This dummy component exists to improve code readability
// it is tested on DynamicList.test.tsx suite
export const MobileHeader = forwardRef<HTMLDivElement, MobileHeaderProps>(
  ({ heading, subtitle, activeSlide, items }, ref): ReactElement => {
    const [isHeaderHidden, setIsHeaderHidden] = useState(false);

    // useEffect for observing header visibility
    useEffect(() => {
      const header = document.getElementById('sc-navbar-container');

      if (!header) return;

      const updateHeaderState = () => {
        const currentIsHeaderHidden = header.getAttribute('aria-hidden') === 'true';
        setIsHeaderHidden(currentIsHeaderHidden);
      };

      // Initial call to set state
      updateHeaderState();

      // Create a MutationObserver to watch for changes in the header's aria-hidden attribute
      const observer = new MutationObserver(() => {
        updateHeaderState(); // Update the state when aria-hidden changes
      });
      observer.observe(header, { attributes: true, attributeFilter: ['aria-hidden'] });

      // Cleanup the observer on component unmount
      // eslint-disable-next-line consistent-return
      return () => {
        observer.disconnect();
      };
    }, []);

    return (
      <>
        {heading && (
          <Heading className={styles.heading} as={HEADING_TAG.H2} size={HEADING_SIZE.h2}>
            {heading}
          </Heading>
        )}
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        <div className={classNames(styles.svgMobile, { [styles.collapse]: isHeaderHidden })}>
          {DynamicListSVG && <DynamicListSVG role="img" activeSlide={activeSlide} />}
          <div ref={ref} className={styles.imageContainer}>
            <p className={styles.pageIndicator}>{`${activeSlide + 1} / ${items.length}`}</p>
          </div>
        </div>
      </>
    );
  }
);

MobileHeader.displayName = 'MobileHeader';

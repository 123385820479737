import { useState, useCallback, useRef, MutableRefObject } from 'react';

import { ScrollAnimator } from 'src/anim/scrollToAnim';

export function useBannerAnimation(
  isSmallDesktop: boolean,
  eligibilityCriteriaRef: MutableRefObject<HTMLDivElement | null>,
  triggerScrollOffset: number = 0
) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [hasTriggeredAnimationManually, setHasTriggeredAnimationManually] = useState(false);
  const [hasAnimationStarted, setHasAnimationStarted] = useState(false);
  const [hasAnimationFinished, setHasAnimationFinished] = useState(false);
  const [isAnimationResetInProgress, setIsAnimationResetInProgress] = useState(false);
  const [shouldShowQuote, setShouldShowQuote] = useState(false);

  const animationDuration = 1200;

  const brandTabsContainerRef = useRef<HTMLDivElement | null>(null);
  const quoteContainerRef = useRef<HTMLDivElement | null>(null);

  const getBody = () => document.getElementsByTagName('body')?.[0] as HTMLElement | undefined;
  const SCROLL_BAR_WIDTH = 20;

  // disables the ability to scroll in the site
  const lockScroll = useCallback(() => {
    const body = getBody();
    if (!body) return;

    body.style.overflow = 'hidden';
    body.style.height = `calc(100vh + ${SCROLL_BAR_WIDTH}px)`;
  }, []);

  // enables the ability to scroll in the site
  const unlockScroll = useCallback(() => {
    const body = getBody();
    if (!body) return;

    body.style.removeProperty('overflow');
    body.style.removeProperty('height');
  }, []);

  const handleTabChange = useCallback(
    // Our eligibility CTA should scroll directly to the eligibility criteria section
    // instead of seeing the whole animation of the banner so we add an option to skip it
    (index: number) => {
      setActiveTab(index);
      setHasAnimationStarted(true);

      if (!shouldShowQuote) {
        setShouldShowQuote(true);
      }

      // This makes it so our scroll "sticks" to our top of the page while the animation is playing
      if (quoteContainerRef.current) {
        ScrollAnimator.smoothScrollTo({
          top: 0,
          duration: animationDuration,
          onComplete: () => {
            setHasAnimationFinished(true);
          },
        });
      }
    },
    [shouldShowQuote]
  );

  // we need a different function for the initial animations with scroll locks.
  const handleTabChangeWithScrollLock = useCallback(
    (index: number, skipAnimation?: boolean) => {
      setActiveTab(index);
      setHasAnimationStarted(true);
      lockScroll();

      if (!shouldShowQuote) {
        setShouldShowQuote(true);
      }

      // This makes it so our scroll "sticks" to our top of the page while the animation is playing
      if (quoteContainerRef.current && !skipAnimation) {
        ScrollAnimator.smoothScrollTo({
          top: 0,
          duration: animationDuration,
          onComplete: () => {
            setHasAnimationFinished(true);
            unlockScroll();
          },
        });
      }
    },
    [shouldShowQuote]
  );

  // This is our tab click handler, this one is triggered by clicking directly on a tab
  const handleTabClick = useCallback(
    (index: number) => {
      setActiveTab(index);
      setHasTriggeredAnimationManually(true);

      handleTabChange(index);
    },
    [handleTabChange]
  );

  // This is our eligibility CTA click handler, since our animation hijacks the scroll we need to make sure everything plays correctly
  const handleEligibilityCtaClick = useCallback(() => {
    if (isSmallDesktop) {
      const desktopNavbarHeight = 96;
      const marginTopOffset = 50;
      handleTabChangeWithScrollLock(activeTab, true);
      ScrollAnimator.smoothScrollToElement(eligibilityCriteriaRef.current, {
        duration: animationDuration,
        // Since our first section is just a static parent which inner content scrolls we have to offset for exactly
        // our viewport's height (our top section is 100vh) plus the navbar (which is 96px in desktop)
        offsetTop: -window.innerHeight + desktopNavbarHeight + marginTopOffset,
        onComplete: () => {
          setHasAnimationFinished(true);
          unlockScroll();
        },
      });
    } else {
      ScrollAnimator.smoothScrollToElement(eligibilityCriteriaRef.current, {
        duration: animationDuration,
        onComplete: () => {
          unlockScroll();
        },
      });
    }
  }, [activeTab, isSmallDesktop, eligibilityCriteriaRef, handleTabChangeWithScrollLock]);

  const resetAnimation = useCallback(() => {
    // We basically restore all states and everything to their initial values so we can trigger the animation from the beginning
    // if needed
    if (!isAnimationResetInProgress && quoteContainerRef.current) {
      setIsAnimationResetInProgress(true);
      setShouldShowQuote(false);

      // Scroll to our top section
      ScrollAnimator.smoothScrollTo({
        top: 0,
        duration: animationDuration,
        onComplete: () => {
          setHasTriggeredAnimationManually(false);
          setHasAnimationStarted(false);
          setHasAnimationFinished(false);
          setIsAnimationResetInProgress(false);
        },
      });
    }
  }, [isAnimationResetInProgress]);

  const handleScroll = useCallback(() => {
    // We don't have animations on mobile
    if (!isSmallDesktop) return;

    if (!hasTriggeredAnimationManually && !hasAnimationStarted && window.scrollY >= triggerScrollOffset) {
      handleTabChangeWithScrollLock(0);
    }
  }, [
    isSmallDesktop,
    hasTriggeredAnimationManually,
    hasAnimationStarted,
    triggerScrollOffset,
    handleTabChangeWithScrollLock,
  ]);

  const handleWheelEvent = useCallback(
    (event: WheelEvent) => {
      if (!isSmallDesktop) return;

      // Since our container now sticks to the top of the page, we want to know if we're at the top of the page
      // and trying to scroll back into our first section
      if (hasAnimationFinished && !isAnimationResetInProgress && window.scrollY === 0 && event.deltaY < 0) {
        resetAnimation();
      }
    },
    [hasAnimationFinished, isAnimationResetInProgress, isSmallDesktop, resetAnimation]
  );

  return {
    activeTab,
    shouldShowQuote,
    brandTabsContainerRef,
    quoteContainerRef,
    handleScroll,
    handleWheelEvent,
    handleTabChange,
    handleTabClick,
    handleEligibilityCtaClick,
  };
}

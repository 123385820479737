import classNames from 'classnames';
import React from 'react';
import Markdown from 'react-markdown';

import { Icon } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './AsxMarkdownContent.module.scss';

interface AsxMarkdownContentProps {
  copy: string;
  className?: string;
  tabIndex?: number;
  theme?: SECTION_THEME;
  renderAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
}

export const AsxMarkdownContent: React.FC<AsxMarkdownContentProps> = ({
  copy,
  className,
  tabIndex = 0,
  theme = SECTION_THEME.Light,
  renderAs: WrapperTag = 'p',
}) => {
  const renderLink = (props: any) => {
    const { children } = props;
    return (
      <a className={styles[theme]} {...props} tabIndex={tabIndex} target="_blank" rel="noreferrer">
        {children}
        <Icon className={styles.icon} name="chevronTopRight" />
      </a>
    );
  };

  const renderParagraph = (props: any) => {
    const { children } = props;
    return <WrapperTag>{children}</WrapperTag>;
  };

  return (
    <Markdown
      className={classNames(styles['markdown-copy'], className)}
      components={{
        a: renderLink,
        p: renderParagraph,
      }}
    >
      {copy}
    </Markdown>
  );
};

import { FunctionComponent, ImgHTMLAttributes, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PAGE_INGRESS_BY_ROUTE } from 'src/constants';
import { getFallbackImage } from 'src/images/fallback/fallbackProvider';
import { emitCountMetric, emitTimeMetric, logError, logger } from 'src/logger';
import { IMAGE_LOAD_TIME, IMAGE_LOAD_FAILURE } from 'src/logging-helpers/metrics-constants';

const IMAGE_LOAD_EMIT_THRESHOLD_MS = 1000;

interface AsxImageProps extends ImgHTMLAttributes<any> {
  /**
   * Name of the component that the image is from
   */
  componentName: string;

  /**
   * Fallback image URL if requested image cannot be rendered
   */
  fallback?: string;
}

/**
 * Component for images on Amazon Sustainability Exchange (ASX). The fallback image will be returning if the src image
 * is not working. Metrics are emitted for image load and image latency.
 *
 * @param fallback fallback image
 * @param src src image
 * @param alt alt text for image
 * @param componentName name of the component where the image is called
 * @param props any other props
 */
export const AsxImage: FunctionComponent<AsxImageProps> = ({
  src,
  alt = '',
  fallback = getFallbackImage(),
  componentName,
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);
  const location = useLocation();
  const handleLoad = () => {
    const entry = performance.getEntriesByType('resource').find((e) => e.name === src);
    if (entry) {
      const { duration } = entry;
      if (duration > IMAGE_LOAD_EMIT_THRESHOLD_MS) {
        emitTimeMetric(IMAGE_LOAD_TIME, duration);
        logger.info(`Image loaded successfully with high latency, src: ${src}, duration: ${duration}ms`);
      }
    }
  };
  const handleError = (error: any) => {
    const pageIngress = PAGE_INGRESS_BY_ROUTE[location.pathname].toString();
    setImgSrc(fallback);
    emitCountMetric(IMAGE_LOAD_FAILURE, 1);
    logError(
      'AsxImage',
      `Unable to load image with src ${src} alt ${alt} in ${componentName} component from ${pageIngress} page. Loaded fallback ${fallback} instead.`,
      error
    );
  };

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return <img src={imgSrc} onError={(error) => handleError(error)} onLoad={handleLoad} alt={alt} {...props} />;
};

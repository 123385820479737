import classNames from 'classnames';
import Lottie from 'lottie-react';
import { forwardRef, useEffect, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { Icon, AsxImage, Tagline } from 'src/components/atoms';
import { NativeVideoPlayer, TranscriptionFragments, VideoWithPoster } from 'src/components/common';
import { CREDITS_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import { useLottieController } from 'src/hooks/useLottiePlayer.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './DynamicMedia.module.scss';

interface FixedTaglineAndTitleProps {
  mediaTagline: string;
  mediaTitle: string;
}

export const FixedTaglineAndTitle: React.FC<FixedTaglineAndTitleProps> = ({ mediaTagline, mediaTitle }) => (
  <div className={styles.fixedTaglineAndTitleWrapper}>
    <Tagline>{mediaTagline}</Tagline>
    <h3>{mediaTitle}</h3>
  </div>
);

interface SlideCounterProps {
  activeSlide: number;
  totalSlides: number;
}

export const SlideCounter: React.FC<SlideCounterProps> = ({ activeSlide, totalSlides }) => (
  <p className={styles.counter}>{`${activeSlide + 1}/${totalSlides}`}</p>
);

export interface MediaItem {
  image?: ImageAttributes;
  lottie?: { animationData: object; alt: string };
  video?: {
    thumbnail: ImageAttributes;
    videoSrc: string;
    captionsSrc?: string;
    transcription?: Array<TranscriptionFragments>;
  };
  quote?: string;
  byLine?: string;
}

export interface DynamicMediaProps {
  media: MediaItem[];
  activeSlide: number;
  mediaTagline: string;
  mediaTitle: string;
}

export const DynamicMedia = forwardRef<HTMLDivElement, DynamicMediaProps>(
  ({ media, activeSlide, mediaTagline, mediaTitle }, ref) => {
    const { lottieRef, isPlaying, togglePlayPause, onLoopComplete } = useLottieController(activeSlide === 1);
    const [creditsBundle] = useBundle(CREDITS_BUNDLE_NAME);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const { isDesktop } = useDeviceTracker();
    const { isSmallDesktop } = useDeviceSize();

    useEffect(() => {
      setIsVideoPlaying(false);
    }, [isSmallDesktop]);

    return (
      <div className={styles.imageHeader}>
        {!isDesktop && <FixedTaglineAndTitle mediaTagline={mediaTagline} mediaTitle={mediaTitle} />}
        <div className={styles.imageWrapper} ref={ref}>
          {media?.map((mediaItem, index) => (
            <div
              key={index}
              className={classNames(
                styles.slide,
                mediaItem.quote && styles.hasQuote,
                mediaItem.lottie && styles.hasLottie,
                { [styles.active]: activeSlide === index }
              )}
            >
              <div
                className={classNames(
                  styles.byLineWrapper,
                  mediaItem.quote && styles.hasQuote,
                  mediaItem.video && styles.hasVideo
                )}
              >
                <p className={styles.quote}>{mediaItem.quote}</p>
                <p className={styles.byLine}>{mediaItem.byLine}</p>
              </div>
              {mediaItem.image && (
                <AsxImage
                  className={styles.image}
                  src={mediaItem.image.src}
                  alt={mediaItem.image.alt}
                  componentName="DynamicList"
                />
              )}
              {mediaItem.lottie && (
                <div className={styles.lottieContent}>
                  <button
                    data-testid="pauseButton"
                    className={styles.pauseButton}
                    onClick={togglePlayPause}
                    type="button"
                    aria-label={
                      isPlaying
                        ? t(creditsBundle, 'creditsPage_customGraphic_lottie_pause')
                        : t(creditsBundle, 'creditsPage_customGraphic_lottie_play')
                    }
                  >
                    <Icon name={isPlaying ? 'pause' : 'play'} className={styles.pauseIcon} />
                  </button>
                  <div className={styles.lottieWrapper}>
                    <Lottie
                      animationData={mediaItem.lottie.animationData}
                      lottieRef={lottieRef}
                      onComplete={onLoopComplete}
                      loop={false}
                      alt={mediaItem.lottie.alt}
                      data-testid="lottie"
                    />
                  </div>
                </div>
              )}
              {mediaItem.video && (
                <>
                  {!isSmallDesktop && (
                    <NativeVideoPlayer
                      src={mediaItem.video.videoSrc}
                      onVideoClose={() => setIsVideoPlaying(false)}
                      isVideoPlaying={!isSmallDesktop && isVideoPlaying}
                    />
                  )}

                  {(isSmallDesktop || (!isSmallDesktop && !isVideoPlaying)) && (
                    <div className={styles.videoWrapper}>
                      <VideoWithPoster
                        isVideoPlaying={isVideoPlaying}
                        setIsVideoPlaying={setIsVideoPlaying}
                        thumbnail={mediaItem.video.thumbnail}
                        video={mediaItem.video}
                        onVideoPlay={() => setIsVideoPlaying(true)}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        {!isDesktop && <SlideCounter activeSlide={activeSlide} totalSlides={media.length} />}
      </div>
    );
  }
);
DynamicMedia.displayName = 'DynamicMedia';

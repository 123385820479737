import classNames from 'classnames';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

import { AsxImage, Icon } from 'src/components/atoms';
import { NAVIGATION_TABS_ELEMENT_ID, NavigationTabsProps } from 'src/components/atoms/navigation-tabs/NavigationTabs';
import commonStyles from 'src/components/atoms/navigation-tabs/NavigationTabs.module.scss';
import { MODAL_VARIANT, ModalWrapper } from 'src/components/common';
import { convertToKebabCase } from 'src/helpers';
import { useHeaderVisibility } from 'src/hooks/useHeaderVisibility';

import styles from './NavigationTabsMobile.module.scss';

const MOBILE_NAVBAR_HEIGHT = 72;
const TOP_INTERSECTION_TRIGGER = 2 * MOBILE_NAVBAR_HEIGHT + 2;

interface NavigationTabsMobileProps extends NavigationTabsProps {
  /*
     String title that will be shown when the mobile dropdown modal is opened
    */
  modalTitle: string;
}

export const NavigationTabsMobile: FunctionComponent<NavigationTabsMobileProps> = ({
  activeTab,
  modalTitle,
  tabs,
  shouldDisplayBelow = false,
  onTabChange,
}) => {
  const [isExtended, setIsExtended] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const self = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const activeTabContent = tabs[activeTab];
  const { isHeaderVisible } = useHeaderVisibility();

  const handleOpenMenu = () => setIsOpened(true);
  const handleCloseMenu = () => setIsOpened(false);
  const handleTabChange = (index: number) => {
    onTabChange(index);
    handleCloseMenu();
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsExtended(!entry.isIntersecting && entry.boundingClientRect.top < window.innerHeight),
      {
        rootMargin: `-${TOP_INTERSECTION_TRIGGER}px 0px 0px 0px`,
        threshold: 0,
      }
    );

    observer.observe(self.current);

    return () => observer.disconnect();
  }, [self]);

  return (
    <>
      <button
        ref={self}
        className={classNames(
          styles.navigationTabsStickyContainer,
          commonStyles.navigationTabsStickyContainer,
          isHeaderVisible && commonStyles.withNavbar,
          isExtended && styles.extended,
          shouldDisplayBelow && styles.belowPosition
        )}
        type="button"
        onClick={handleOpenMenu}
        data-testid="navigation-tab"
        aria-haspopup="dialog"
        id={NAVIGATION_TABS_ELEMENT_ID}
      >
        <div className={classNames(commonStyles.navigationTabs, styles.navigationTabs)}>
          <div className={classNames(commonStyles.tab, styles.tab)}>
            <AsxImage className={commonStyles.image} {...activeTabContent.image} componentName="NavigationTabsMobile" />
            <p className={commonStyles.label}>{activeTabContent.label}</p>
            <Icon className={styles.icon} name="chevronDown" />
          </div>
        </div>
      </button>
      <ModalWrapper
        variant={MODAL_VARIANT.tabs}
        title={modalTitle}
        isVisible={isOpened}
        close={handleCloseMenu}
        shouldOverrideFocus
      >
        <div role="radiogroup" aria-labelledby="navigation-tabs-radio-group" tabIndex={0}>
          <p id="navigation-tabs-radio-group" className="accesibilityHelper">
            {modalTitle}
          </p>
          <div>
            {tabs.map((option, index) => (
              <label
                className={styles.option}
                key={`${convertToKebabCase(option.label)}_${index}`}
                htmlFor={option.label}
              >
                <AsxImage className={commonStyles.image} {...option.image} componentName="NavigationTabsMobile" />
                <input
                  id={option.label}
                  className={styles.radio}
                  type="radio"
                  name="radio-button"
                  data-testid="option"
                  onChange={() => handleTabChange(index)}
                  onClick={() => handleTabChange(index)}
                  checked={index === activeTab}
                  aria-labelledby={convertToKebabCase(option.label)}
                />
                <p className={styles.label} id={convertToKebabCase(option.label)}>
                  {option.label}
                </p>
                <div className={styles.circle}>
                  <Icon name="check" className={styles.checkIcon} />
                </div>
              </label>
            ))}
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

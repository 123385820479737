import classNames from 'classnames';
import type { APITypes } from 'plyr-react';
import React, { useState, useRef, Dispatch, SetStateAction } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { Icon, PRIMARY_BUTTON_V2_ICON_POSITION, PrimaryButtonV2 } from 'src/components/atoms';
import { TranscriptionFragments, VideoPlayer } from 'src/components/common';
import { CREDITS_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './VideoWithPoster.module.scss';

interface VideoWithPosterProps {
  videoHeadline?: string;
  thumbnail?: ImageAttributes;
  previewVideoSrc?: string;
  ctaLabel?: string;
  playDataTrackLabel?: string;
  video: { videoSrc: string; captionsSrc?: string; transcription?: Array<TranscriptionFragments> };
  isVideoPlaying: boolean;
  setIsVideoPlaying: Dispatch<SetStateAction<boolean>>;
  onVideoPlay: () => void;
}

export const VideoWithPoster: React.FunctionComponent<VideoWithPosterProps> = ({
  videoHeadline,
  thumbnail,
  previewVideoSrc,
  ctaLabel,
  playDataTrackLabel,
  video,
  isVideoPlaying,
  onVideoPlay,
  setIsVideoPlaying,
}) => {
  const videoPlayerRef = useRef<APITypes>(null);
  const videoPlayerContainerRef = useRef<HTMLDivElement>(null);
  const previewVideoRef = useRef<HTMLVideoElement>(null);
  const { isSmallDesktop } = useDeviceSize();

  const [isPreviewPlaying, setIsPreviewPlaying] = useState(true);

  const [creditsBundle] = useBundle(CREDITS_BUNDLE_NAME);

  const handleFullScreenEnter = () => {
    if (videoPlayerContainerRef.current) {
      videoPlayerContainerRef.current.requestFullscreen();
    }
  };

  const handleFullScreenExit = () => {
    // TODO test these lines https://issues.amazon.com/issues/ASX-446
    // istanbul ignore next
    if (videoPlayerRef.current) {
      setIsVideoPlaying(false);
      videoPlayerRef.current.plyr.pause();
    }
  };

  const handlePlayVideoClick = () => {
    onVideoPlay();
    setIsVideoPlaying(true);

    if (videoPlayerRef.current) {
      handleFullScreenEnter();
      // TODO test these lines https://issues.amazon.com/issues/ASX-446
      // istanbul ignore next
      videoPlayerRef.current.plyr.play();
    }
  };

  const togglePreviewPlay = () => {
    // TODO test these lines https://issues.amazon.com/issues/ASX-446
    // istanbul ignore next
    if (previewVideoRef.current) {
      if (isPreviewPlaying) {
        previewVideoRef.current.pause();
      } else {
        previewVideoRef.current.play();
      }
      setIsPreviewPlaying(!isPreviewPlaying);
    }
  };

  return (
    <div className={styles.videoContainer}>
      {isSmallDesktop && (
        <VideoPlayer
          key={video.videoSrc}
          ref={videoPlayerRef}
          onFullscreenExit={handleFullScreenExit}
          containerRef={videoPlayerContainerRef}
          isVideoPlaying={isVideoPlaying}
          transcription={video.transcription}
          videoSrc={video.videoSrc}
          captionsSrc={video.captionsSrc}
        />
      )}
      {!isVideoPlaying && (
        <>
          {previewVideoSrc && isSmallDesktop && (
            <>
              <video
                ref={previewVideoRef}
                className={styles.videoPreview}
                src={previewVideoSrc}
                autoPlay={isPreviewPlaying}
                loop
                muted
              />
              <button
                data-testid="pauseButton"
                className={styles.pauseButton}
                onClick={togglePreviewPlay}
                type="button"
                aria-label={
                  isPreviewPlaying
                    ? t(creditsBundle, 'creditsPage_customGraphic_lottie_pause')
                    : t(creditsBundle, 'creditsPage_customGraphic_lottie_play')
                }
              >
                <Icon name={isPreviewPlaying ? 'pause' : 'play'} className={styles.pauseIcon} />
              </button>
            </>
          )}
          {thumbnail && (
            <img className={styles.videoPoster} src={thumbnail.src} alt={thumbnail.alt} data-testid="active-poster" />
          )}
        </>
      )}
      {!isVideoPlaying && (
        <div
          data-track-label={playDataTrackLabel}
          data-track-action="click"
          className={classNames(styles.videoTextContainer, ctaLabel && styles.hasLabel)}
        >
          {videoHeadline && <h5 className={styles.videoTextHeader}>{videoHeadline}</h5>}
          <PrimaryButtonV2
            label={ctaLabel}
            iconPosition={PRIMARY_BUTTON_V2_ICON_POSITION.Before}
            icon="play"
            theme={SECTION_THEME.Dark}
            className={classNames(styles.videoPlayCta, ctaLabel && styles.hasLabel)}
            onClick={handlePlayVideoClick}
            testId="play-video-button"
          />
        </div>
      )}
    </div>
  );
};

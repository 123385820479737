import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { BackButton, Heading, HEADING_SIZE } from 'src/components/atoms';
import { FaqItem } from 'src/components/common/faq-item/FaqItem';
import { CREDITS_FAQ_BUNDLE_NAME } from 'src/constants';
import { HEADING_TAG } from 'src/data/enums/Heading';
import { ROUTES } from 'src/data/enums/Routes';
import { t } from 'src/helpers';

import styles from './CreditsFaqPage.module.scss';
import { faqBlocks } from './FaqBlocks';

export const CreditsFaqPage: FunctionComponent = () => {
  const [creditsFaqBundle] = useBundle(CREDITS_FAQ_BUNDLE_NAME);

  return (
    <div>
      <div className={styles.faqPageHeader} data-testid="faq-page-header">
        <BackButton
          variant="default"
          label={t(creditsFaqBundle, 'creditsPageFaq_backButton_label')}
          url={ROUTES.DEBORAH}
        />
        <Heading as={HEADING_TAG.H2} size={HEADING_SIZE.h2} className={styles.title}>
          {t(creditsFaqBundle, 'creditsPageFaq_title')}
        </Heading>
      </div>
      <div className={styles.faqPageQuestions}>
        {faqBlocks.map((block, index) => (
          <FaqItem
            key={index}
            index={index}
            question={t(creditsFaqBundle, block.question)}
            answer={t(creditsFaqBundle, block.answer)}
            link={
              block.link && {
                label: t(creditsFaqBundle, block.link?.label),
                url: block.link?.url,
                icon: block.link.icon,
              }
            }
          />
        ))}
      </div>
    </div>
  );
};

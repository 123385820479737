import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { AsxImage } from 'src/components/atoms';
import { NAVIGATION_TABS_ELEMENT_ID, NavigationTabsProps } from 'src/components/atoms/navigation-tabs/NavigationTabs';
import styles from 'src/components/atoms/navigation-tabs/NavigationTabs.module.scss';
import { convertToKebabCase } from 'src/helpers';
import { useHeaderVisibility } from 'src/hooks/useHeaderVisibility';

export const NavigationTabsDesktop: FunctionComponent<NavigationTabsProps> = ({
  activeTab,
  tabs,
  shouldDisplayBelow = false,
  onTabChange,
}) => {
  const handleTabClick = (index: number) => onTabChange(index);
  const { isHeaderVisible } = useHeaderVisibility();

  return (
    <div
      id={NAVIGATION_TABS_ELEMENT_ID}
      className={classNames(
        styles.navigationTabsStickyContainer,
        isHeaderVisible && styles.withNavbar,
        shouldDisplayBelow && styles.belowPosition
      )}
    >
      <div role="tablist" aria-controls="navigation-tabs-content" className={styles.navigationTabs}>
        {tabs.map(({ image, label }, index) => (
          <button
            role="tab"
            aria-selected={activeTab === index}
            id={`navigation-tab-${index + 1}`}
            aria-controls={`navigation-tab-content-${index + 1}`}
            type="button"
            data-testid="navigation-tab"
            className={classNames(styles.tab, activeTab === index && styles.isActive)}
            key={`${convertToKebabCase(label)}_${index}`}
            onClick={() => handleTabClick(index)}
          >
            {activeTab === index && (
              <AsxImage className={styles.image} {...image} componentName="NavigationTabsDesktop" />
            )}
            <p className={styles.label}>{label}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

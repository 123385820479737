import classnames from 'classnames';
import React, { FunctionComponent, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { AsxImage, AsxMarkdownContent, FormattedLink, IconType, PrimaryButton, Tagline } from 'src/components/atoms';
import { VideoWithPoster } from 'src/components/common';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { HEADING_TAG } from 'src/data/enums/Heading';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { shouldRenderFluidPageSection, t } from 'src/helpers';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './ListComponent.module.scss';

export enum CTA_TYPE {
  Pdf = 'pdf',
  Link = 'link',
}

export enum CTA_POSITION {
  bottom = 'bottom',
}

export enum LINKS_POSITION {
  UnderCta = 'underCta',
}

export enum LIST_VARIANT {
  List = 'list',
  Accordion = 'accordion',
}

export type ListItemProps = {
  theme?: SECTION_THEME;
  title?: string;
  headingSize?: HEADING_TAG;
  subtitle?: string;
  image?: ImageAttributes;
  taglines?: Array<string | undefined>;
  cta?: {
    href: string;
    icon?: IconType;
    type?: CTA_TYPE;
    label?: string;
    position?: CTA_POSITION;
  };
  label?: string;
  links?: { label: string; url: string; icon?: IconType }[];
  linksPosition?: LINKS_POSITION;
  video?: {
    url: string;
    thumbnail: ImageAttributes;
    caption: string;
  };
  isOpen?: boolean;
  variant?: LIST_VARIANT;
};

export const ListItem: FunctionComponent<ListItemProps> = ({
  theme = SECTION_THEME.Light,
  image,
  title,
  headingSize = HEADING_TAG.H5,
  subtitle,
  taglines,
  cta = { href: undefined, icon: undefined, type: CTA_TYPE.Link, label: undefined, position: undefined },
  label,
  isOpen = false,
  links,
  linksPosition,
  video,
  variant = LIST_VARIANT.List,
}) => {
  const { isDesktop } = useDeviceTracker();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const HeadingTag = React.createElement(headingSize, { className: classnames(styles[theme], styles.title) }, title);
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);
  const renderFluidPageSection = shouldRenderFluidPageSection();

  const tabIndexHidden = -1;
  const tabIndexVisible = 0;

  const getAriaLabel = () => {
    const exploreText = t(commonBundle, 'explore');
    const newTabText = t(commonBundle, 'new_tab');
    if (cta.type === CTA_TYPE.Pdf) {
      return `${exploreText} ${title} ${t(commonBundle, 'pdf')} ${newTabText}`;
    }
    return `${exploreText} ${title} ${newTabText}`;
  };

  return (
    <div
      key={`${title}-${label}`}
      className={classnames(
        styles.listItem,
        image && styles.hasImage,
        renderFluidPageSection && styles.hasFluidPaddings,
        styles[theme],
        styles[variant],
        video && styles.hasVideo
      )}
    >
      <div className={styles.cardHeader}>
        {isDesktop && image && (
          <div className={styles.imageContainer}>
            <AsxImage
              className={styles.image}
              alt={image.alt}
              src={image.src}
              componentName="ListCompoonent"
              data-testid="listComponentImage"
            />
          </div>
        )}
        <div className={classnames(styles.textContentContainer, styles[theme])}>
          {taglines && taglines.length > 0 && (
            <div className={styles.taglines}>
              {taglines?.map((tagline) => tagline && <Tagline key={tagline}>{tagline}</Tagline>)}
            </div>
          )}
          {title && HeadingTag}
          {subtitle && (
            <AsxMarkdownContent
              tabIndex={variant === LIST_VARIANT.Accordion && !isOpen ? tabIndexHidden : tabIndexVisible}
              className={classnames(styles.text, video && styles.hasVideo)}
              theme={theme}
              copy={subtitle}
            />
          )}
          {links &&
            !linksPosition &&
            links.map((link) => (
              <FormattedLink
                key={link.label}
                link={link.url}
                text={link.label}
                className={classnames(styles.link, styles[variant])}
                iconName={link.icon}
                tabIndex={variant === LIST_VARIANT.Accordion && !isOpen ? tabIndexHidden : tabIndexVisible}
                openNewTab
              />
            ))}
          {cta.href && cta.position === CTA_POSITION.bottom && (
            <PrimaryButton
              className={styles.buttonLeft}
              data-testid="primaryButton"
              icon={cta.icon ?? 'chevronTopRight'}
              theme={theme}
              label={cta.label}
              link={cta.href}
              download={cta.icon === 'download'}
              aria-label={getAriaLabel()}
              tabIndex={variant === LIST_VARIANT.Accordion && !isOpen ? tabIndexHidden : tabIndexVisible}
            />
          )}
          {links &&
            linksPosition === LINKS_POSITION.UnderCta &&
            isDesktop &&
            links.map((link) => (
              <FormattedLink
                key={link.label}
                link={link.url}
                text={link.label}
                className={classnames(
                  styles.link,
                  styles[variant],
                  linksPosition === LINKS_POSITION.UnderCta && styles.underCtaLink
                )}
                iconName={link.icon}
                tabIndex={variant === LIST_VARIANT.Accordion && !isOpen ? tabIndexHidden : tabIndexVisible}
                openNewTab
              />
            ))}
        </div>
      </div>
      {video && (
        <div className={styles.videoContainer}>
          <div className={styles.videoWrapper}>
            <VideoWithPoster
              isVideoPlaying={isVideoPlaying}
              setIsVideoPlaying={setIsVideoPlaying}
              onVideoPlay={() => setIsVideoPlaying(true)}
              video={{ videoSrc: video.url }}
              thumbnail={video.thumbnail}
            />
          </div>
          <p className={styles.videoCaption}>{video.caption}</p>
        </div>
      )}
      {cta.href && !cta.position && (
        <div className={styles.cardCta}>
          {label && <span className={classnames(styles.label)}>{label}</span>}
          <PrimaryButton
            className={styles.button}
            data-testid="primaryButton"
            icon={cta.icon ?? 'chevronTopRight'}
            theme={theme}
            label={cta.label}
            link={cta.href}
            download={cta.icon === 'download'}
            aria-label={getAriaLabel()}
            tabIndex={variant === LIST_VARIANT.Accordion && !isOpen ? tabIndexHidden : tabIndexVisible}
          />
        </div>
      )}
    </div>
  );
};

interface ListComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  items: Array<ListItemProps>;
}

export const ListComponent: FunctionComponent<ListComponentProps> = ({ items, ...props }) => (
  <div className={styles.listComponent} id={styles.listComponent} {...(props as React.HTMLAttributes<HTMLDivElement>)}>
    <div className={styles.list}>
      {items.map((item) => (
        <ListItem key={`${item.title}-${item.label}`} {...item} />
      ))}
    </div>
  </div>
);

import Lottie from 'lottie-react';
import { FunctionComponent, useCallback, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import {
  AsxMarkdownContent,
  FormattedLink,
  Icon,
  IconType,
  PRIMARY_BUTTON_V2_VARIANT,
  PrimaryButtonV2,
  Tagline,
} from 'src/components/atoms';
import { ImageContent, ModalImageContent } from 'src/components/common';
import { CREDITS_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import { useLottieController } from 'src/hooks/useLottiePlayer.hook';

import styles from './CustomGraphic.module.scss';

interface DetailsProps {
  backgroundColor?: string;
  line?: boolean;
  copy: string;
}

export const Details: FunctionComponent<DetailsProps> = ({ backgroundColor, line, copy }) => (
  <div className={styles.details}>
    {line ? <div className={styles.line} /> : <div className={styles.circle} style={{ backgroundColor }} />}
    <p className={styles.copy}>{copy}</p>
  </div>
);
export interface CustomGraphicProps {
  tagline: string;
  heading: string;
  copy: string;
  emissionsCopy?: string;
  lottieAnimation: object;
  lottieAlt: string;
  details: {
    detailFirst: string;
    detailSecond: string;
    detailThird: string;
    detailFourth: string;
    detailFifth: string;
  };
  footerContent: { footerParagraph: string; footerLinkLabel: string; footerLinkHref: string; footerCTA: string };
  modalContent: ImageContent;
}
export const CustomGraphic: FunctionComponent<CustomGraphicProps> = ({
  tagline,
  heading,
  copy,
  emissionsCopy,
  lottieAnimation,
  lottieAlt,
  details,
  footerContent,
  modalContent,
}) => {
  // TODO add stories for for variants https://issues.amazon.com/issues/ASX-314

  const [creditsBundle] = useBundle(CREDITS_BUNDLE_NAME);

  const { isMedium } = useDeviceTracker();

  const { lottieRef, lottieWrapperRef, isPlaying, togglePlayPause, onLoopComplete } = useLottieController();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const firstDetailCircle = '#DFEBEE';
  const secondtDetailCircle = '#91DCFA';
  const thirdDetailCircle = '#ADEDC4';
  const fourthtDetailCircle = '#D9F4E8';

  const handleModalOpen = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  // istanbul ignore next
  const handleModalClose = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const YEAR_2010 = 2010;
  const YEAR_2020 = 2020;
  const YEAR_2030 = 2030;
  const YEAR_2040 = 2040;
  const YEAR_2050 = 2050;
  const YEAR_2060 = 2060;
  const YEAR_2070 = 2070;
  const YEAR_2080 = 2080;
  const YEAR_2090 = 2090;
  const YEAR_2100 = 2100;

  const EMISSIONS_40 = 40;
  const EMISSIONS_30 = 30;
  const EMISSIONS_20 = 20;
  const EMISSIONS_10 = 10;
  const EMISSIONS_0 = 0;
  const EMISSIONS_NEG_10 = -10;
  const EMISSIONS_NEG_20 = -20;

  const years = isMedium
    ? [YEAR_2010, YEAR_2020, YEAR_2030, YEAR_2040, YEAR_2050, YEAR_2060, YEAR_2070, YEAR_2080, YEAR_2090, YEAR_2100]
    : [YEAR_2010, YEAR_2050, YEAR_2100];

  const emissions = [
    EMISSIONS_40,
    EMISSIONS_30,
    EMISSIONS_20,
    EMISSIONS_10,
    EMISSIONS_0,
    EMISSIONS_NEG_10,
    EMISSIONS_NEG_20,
  ];

  return (
    <div className={styles.customGraphic}>
      <div className={styles.heading}>
        <Tagline className={styles.tagline}>{tagline}</Tagline>
        <div className={styles.titleWrapper}>
          <AsxMarkdownContent className={styles.title} copy={heading} renderAs="h3" />
          <AsxMarkdownContent className={styles.description} copy={copy} />
        </div>
      </div>

      <div className={styles.lottieWrapper} ref={lottieWrapperRef}>
        {!isMedium && <span className={styles.emissionsCopy}>{emissionsCopy}</span>}
        <button
          data-testid="pauseButton"
          className={styles.pauseButton}
          onClick={togglePlayPause}
          type="button"
          aria-label={
            isPlaying
              ? t(creditsBundle, 'creditsPage_customGraphic_lottie_pause')
              : t(creditsBundle, 'creditsPage_customGraphic_lottie_play')
          }
        >
          <Icon name={isPlaying ? 'pause' : 'play'} className={styles.pauseIcon} />
        </button>
        <div className={styles.emissionsAndLottie}>
          <div className={styles.emissions}>
            {isMedium && <span className={styles.emissionsCopy}>{emissionsCopy}</span>}
            <div className={styles.emissionsItems}>
              {emissions.map((item) => (
                <span key={item}>{item}</span>
              ))}
            </div>
          </div>
          <Lottie
            animationData={lottieAnimation}
            lottieRef={lottieRef}
            onComplete={onLoopComplete}
            autoPlay={false}
            loop={false}
            alt={lottieAlt}
          />
        </div>
        <div className={styles.years}>
          {years.map((year) => (
            <span key={year}>{year}</span>
          ))}
        </div>
      </div>
      <div className={styles.detailsContent}>
        <div className={styles.detailsWrapper}>
          <Details backgroundColor={firstDetailCircle} copy={details.detailFirst} />
          <Details backgroundColor={secondtDetailCircle} copy={details.detailSecond} />
          <Details backgroundColor={thirdDetailCircle} copy={details.detailThird} />
          <Details backgroundColor={fourthtDetailCircle} copy={details.detailFourth} />
          <Details line copy={details.detailFifth} />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLinkWrapper}>
          <p className={styles.footerParagraph}>
            {footerContent.footerParagraph}{' '}
            <FormattedLink
              className={styles.footerLink}
              text={footerContent.footerLinkLabel}
              link={footerContent.footerLinkHref}
              openNewTab
            />
          </p>
        </div>
        <PrimaryButtonV2
          label={footerContent.footerCTA}
          icon="plus"
          variant={PRIMARY_BUTTON_V2_VARIANT.Secondary}
          className={styles.footerButton}
          onClick={handleModalOpen}
          aria-label={t(creditsBundle, 'creditsPage_customGraphic_footer_cta_ariaLabel')}
          data-testid="modalButton"
        />
      </div>
      <ModalImageContent
        imageContent={{
          image: { src: modalContent.image.src, alt: modalContent.image.alt },
          title: modalContent.title,
          tagline: modalContent.tagline,
          copy: modalContent.copy,
          isSmallVariant: true,
          footerContent: {
            iconName: modalContent.footerContent?.iconName as IconType,
            copy: modalContent.footerContent?.copy,
            link: modalContent.footerContent?.link
              ? {
                  linkCopy: modalContent.footerContent.link.linkCopy,
                  linkHref: modalContent.footerContent.link.linkHref,
                }
              : undefined,
          },
        }}
        isVisible={isModalVisible}
        close={handleModalClose}
      />
    </div>
  );
};

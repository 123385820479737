// This is a temporary file to overcome an issue in Chrome where captions (and thus transcriptions) do not load
// once we solve that we can get rid of this file, otherwise we don't have anything to build the transcriptions from

export const featuredBannerCaptions = `00:00:02:25 - 00:00:05:26
We don't have time for incremental steps anymore.

00:00:05:34 - 00:00:09:34
We need to both decarbonize
in our corporate value chains,

00:00:09:34 - 00:00:13:43
but we also need
to stabilize our tropical forests,

00:00:13:43 - 00:00:15:18
and we need to invest in nature.

00:00:16:56 - 00:00:19:77
The Intergovernmental Panel on Climate
Change have said that we have to actually

00:00:19:77 - 00:00:23:98
bring global emissions down as close
to zero as possible by mid-century,

00:00:23:98 - 00:00:28:57
and then balance all remaining emissions
with the removal of CO2 from the atmosphere.

00:00:29:07 - 00:00:34:78
To stabilize temperatures
at 1.5Â°C by the end of the century,

00:00:34:91 - 00:00:39:21
there is no doubt that we're going
to have to offset all remaining emissions

00:00:39:29 - 00:00:42:46
by removing CO2
from the atmosphere.

00:00:43:29 - 00:00:45:34
There are climate solutions

00:00:45:34 - 00:00:48:59
that are available but require more investment right now.

00:00:49:01 - 00:00:50:01
So we at Amazon

00:00:50:01 - 00:00:54:14
are focused on three primary segments:
reducing tropical deforestation,

00:00:54:47 - 00:00:57:14
forest restoration
and technological carbon removal.

00:00:57:14 - 00:00:59:31
We're not aiming for incremental progress.

00:00:59:56 - 00:01:03:35
We're using carbon credits as a tool
to achieve climate breakthroughs.

00:01:04:19 - 00:01:06:65
So 30% of the emissions we have on the planet

00:01:06:65 - 00:01:08:40
comes from our destruction of nature.

00:01:08:57 - 00:01:11:53
And, really, deforestation is the lead
cause there.

00:01:11:53 - 00:01:14:53
There's no way to avoid runaway
climate change without

00:01:14:53 - 00:01:15:87
putting an end to tropical deforestation.

00:01:17:83 - 00:01:20:46
So the LEAF Coalition works
at a jurisdictional scale,

00:01:20:46 - 00:01:24:13
enables a higher quality solution where
we're leveraging public sector mechanisms

00:01:24:17 - 00:01:26:13
to address the drivers of deforestation.

00:01:26:13 - 00:01:28:34
It's legal protections for the forest,

00:01:28:34 - 00:01:29:76
enforcement of those protections,

00:01:29:76 - 00:01:31:34
recognition of indigenous rights,

00:01:31:34 - 00:01:33:01
partnerships with indigenous peoples.

00:01:33:18 - 00:01:37:85
We have laws, rules, principles, safeguards,

00:01:37:85 - 00:01:42:06
a transparency program that
can receive resources

00:01:42:06 - 00:01:46:23
and invite indigenous people to share the benefits.

00:01:48:78 - 00:01:49:78
The global need for carbon

00:01:49:78 - 00:01:53:45
removal is on the order of several billion
tons of CO2 per year.

00:01:53:74 - 00:01:57:24
We want to do as much of that as possible
through natural means, which are a lower cost,

00:01:57:24 - 00:01:59:70
higher co-benefits
for people on the planet.

00:01:59:83 - 00:02:05:33
We've collaborated with external experts
to really advance the benchmark for

00:02:05:33 - 00:02:10:51
how monitoring and verification is done
in forest restoration projects.

00:02:11:09 - 00:02:13:72
Restoring degraded areas recovers

00:02:13:72 - 00:02:17:30
80% of carbon storage within 20 years,

00:02:17:64 - 00:02:22:18
meaning it is one of the most cost
effective solutions for climate goals.

00:02:22:43 - 00:02:24:77
Nature-based solutions
can only get us so far.

00:02:24:77 - 00:02:26:02
We have to also develop

00:02:26:02 - 00:02:29:02
technological means for removing carbon
from the atmosphere.

00:02:29:48 - 00:02:30:94
We're investing in

00:02:30:94 - 00:02:33:82
those technologies that we think
have the greatest potential to scale

00:02:33:82 - 00:02:36:20
and that are ready for
commercial scale deployment.

00:02:36:20 - 00:02:37:70
And right now that's direct air capture.

00:02:39:41 - 00:02:40:70
We're now making

00:02:40:70 - 00:02:44:58
available our carbon credits
that we have originated to companies

00:02:44:58 - 00:02:46:21
who are in a similar journey to us.

00:02:46:29 - 00:02:50:50
We've put the due diligence in,
we put all of our scientific investigation in.

00:02:50:88 - 00:02:54:47
We want to offer these solutions
to everyone in our value

00:02:54:47 - 00:02:55:59
chain who's aligned with us.

00:02:55:59 - 00:03:00:68
That means Amazon business customers, our
suppliers and Climate Pledge signatories.

00:03:00:68 - 00:03:04:43
Those companies also need to meet
some buyer qualification criteria,

00:03:04:43 - 00:03:07:56
which means that they need to have a goal
for net zero carbon

00:03:07:60 - 00:03:11:48
no later than 2050, be decarbonizing
across all three scopes,

00:03:11:57 - 00:03:13:36
Be reporting their emissions,

00:03:13:36 - 00:03:16:40
and have goals in line with the latest climate science.

00:03:18:36 - 00:03:19:41
There's been a lot of attention on

00:03:19:41 - 00:03:22:62
the voluntary carbon market on the quality
of the credit, and rightly so.

00:03:22:62 - 00:03:25:58
What really matters
is the quality of the solution.

00:03:25:58 - 00:03:26:96
Can these things actually add up?

00:03:26:96 - 00:03:28:54
And what we're offering is both.

00:03:30:54 - 00:03:34:88
This is the biggest challenge
that we have ever been faced with.

00:03:34:88 - 00:03:37:68
We have the opportunity
to involve and engage the private sector.

00:03:37:68 - 00:03:42:47
And that, I think, is the most interesting
innovation that's happening right now.

00:03:42:47 - 00:03:47:48
We need everyone in this effort.

00:03:47:48 - 00:03:52:47
This is the time to join us in these
solutions to lean in and invest in nature.`;

import { FC, useEffect, useRef } from 'react';

import { NAVIGATION_TABS_ELEMENT_ID } from 'src/components/atoms/navigation-tabs/NavigationTabs';

import styles from './NativeVideoPlayer.module.scss';

export enum NAVIGATION_TABS_Z_INDEX {
  VISIBLE = '5',
  INVISIBLE = '-1',
}

interface NativeVieoPlayerProps {
  src: string;
  isVideoPlaying: boolean;
  onVideoClose: () => void;
}

export const NativeVideoPlayer: FC<NativeVieoPlayerProps> = ({ src, isVideoPlaying, onVideoClose }) => {
  const self = useRef<HTMLDivElement>(null);

  // istanbul ignore next since this is a temporal component
  useEffect(() => {
    setTimeout(() => {
      const navigationTabsCta = document.getElementById(NAVIGATION_TABS_ELEMENT_ID);
      const navbar = document.getElementById('sc-navbar-container');
      if (!navbar || !navigationTabsCta) return;

      if (isVideoPlaying) {
        // close navbar to ensure it doesn't overlap on mobile devices
        navbar.classList.remove('show');
        navbar.classList.add('hide');
        navbar.setAttribute('aria-hidden', 'false');
        // change navigation tabs cta zindex to avoid overlaping
        navigationTabsCta.style.zIndex = NAVIGATION_TABS_Z_INDEX.INVISIBLE;
      } else {
        navigationTabsCta.style.zIndex = NAVIGATION_TABS_Z_INDEX.VISIBLE;
      }
    }, 100);

    return () => {
      setTimeout(() => {
        const navigationTabsCta = document.getElementById(NAVIGATION_TABS_ELEMENT_ID);

        if (!navigationTabsCta) return;

        navigationTabsCta.style.zIndex = NAVIGATION_TABS_Z_INDEX.VISIBLE;
      }, 100);
    };
  }, [isVideoPlaying]);

  useEffect(() => {
    if (!isVideoPlaying || !self.current) return undefined;

    const player = self.current.getElementsByTagName('video')[0];
    player.addEventListener('webkitendfullscreen', onVideoClose, false);

    return () => {
      player.removeEventListener('webkitendfullscreen', onVideoClose);
    };
  }, [isVideoPlaying, self, onVideoClose]);

  return (
    <div ref={self} className={styles.videoPlayerWrapper}>
      {isVideoPlaying && <video className={styles.nativeVideoPlayer} src={src} autoPlay muted controls />}
    </div>
  );
};

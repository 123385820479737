import { IconType } from 'src/components/atoms';

export const faqBlocks = [
  {
    question: `creditsPageFaq_question_1`,
    answer: `creditsPageFaq_answer_1`,
  },
  {
    question: `creditsPageFaq_question_2`,
    answer: `creditsPageFaq_answer_2`,
  },
  {
    question: `creditsPageFaq_question_3`,
    answer: `creditsPageFaq_answer_3`,
    link: {
      label: `creditsPageFaq_answer_linkLabel_3`,
      url: `https://sustainability.aboutamazon.com/carbon-neutralization.pdf`,
      icon: 'download' as IconType,
    },
  },
  {
    question: `creditsPageFaq_question_4`,
    answer: `creditsPageFaq_answer_4`,
    link: {
      label: `creditsPageFaq_answer_linkLabel_4`,
      url: `https://exchange.aboutamazon.com/credits/interest`,
      icon: 'chevronRight' as IconType,
    },
  },
  {
    question: `creditsPageFaq_question_5`,
    answer: `creditsPageFaq_answer_5`,
  },
  {
    question: `creditsPageFaq_question_6`,
    answer: `creditsPageFaq_answer_6`,
  },
  {
    question: `creditsPageFaq_question_7`,
    answer: `creditsPageFaq_answer_7`,
  },
];

/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useBundle } from '@amzn/react-arb-tools';

import { AsxImage, Heading, HEADING_SIZE, Tagline } from 'src/components/atoms';
import { NAVIGATION_TABS_ELEMENT_ID } from 'src/components/atoms/navigation-tabs/NavigationTabs';
import navigationTabsStyles from 'src/components/atoms/navigation-tabs/NavigationTabs.module.scss';
import { BentoCard, ModalFooterContentProps, ModalImageContent, Slider, Tooltip } from 'src/components/common';
import { BentoImage } from 'src/components/common/bento-image/BentoImage';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { HEADING_TAG } from 'src/data/enums/Heading';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import {
  CtaAttributes,
  ImageAttributes,
  ImageWithTaglinesAttributes,
  TooltipAttributes,
} from 'src/interfaces/CommonAttributes';

import styles from './BentoCards.module.scss';

/**
 * Represents the content of a card.
 */
export interface BentoCardContent {
  /** The label of the card. */
  label: string;
  /** The title of the card. */
  title: string;
  /** The main image attributes for the card. */
  image?: ImageAttributes;
  /** Optional description string to be displayed in the card. */
  description?: string;
  /** Optional modal content associated with the card. */
  modal?: {
    /** The label of the modal. */
    label: string;
    /** The title of the modal. */
    title: string;
    /** The main image attributes for the modal. */
    image: ImageWithTaglinesAttributes;
    /** The main content text of the modal. */
    modalContent: string;
    /** Optional call-to-action attributes for the modal. */
    link?: CtaAttributes;
  };
}

/**
 * Props for the BentoCards component.
 */
interface BentoCardsProps {
  /** The description of the component. */
  description: string;
  /** The label for the component. */
  label: string;
  /** The main title for the component. */
  title: string;
  /** Optional size of the title. */
  titleSize?: HEADING_TAG;
  /** Optional theme for tooltips. */
  tooltipTheme?: SECTION_THEME;
  /** Optional array of tooltip attributes. */
  tooltipAttributes?: TooltipAttributes[];
  /** Content for the first card. */
  firstCard: BentoCardContent;
  /** Content for the second card, can be BentoCardContent or ImageAttributes, since on the third tab the second card is an image */
  secondCard: BentoCardContent | ImageAttributes;
  /** Optional flag that will avoid rendering image-type cards on mobile if set */
  hideImageCardsOnMobile?: boolean;
  /** Callback when a modal is opened or closed within the BentoCards component */
  onBentoModalChange?(newStatus: BENTO_CARD_MODAL_STATUS): void;
}

const CardsWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { isSmallDesktop } = useDeviceSize();
  // set a custom breakpoint only for this specific use case, since it's hard to adapt with
  // the breakpoints that we standarly have
  const isTabletPortrait = useMediaQuery({ query: `(min-width: ${styles.tabletPortraitBreakpoint})` });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isSmallDesktop && children) return <>{children}</>;

  if (isTabletPortrait && children) return <div className={styles.bentoCardsWrapper}>{children}</div>;

  return (
    <Slider className={styles.slider} size="small">
      {children}
    </Slider>
  );
};

export enum BENTO_CARD_MODAL_STATUS {
  None = 0,
  FIRST_CARD_INDEX = 1,
  SECOND_CARD_INDEX = 2,
}

enum NAVIGATION_TABS_Z_INDEX {
  VISIBLE = '5',
  INVISIBLE = '2',
}

export const BentoCards: FunctionComponent<BentoCardsProps> = ({
  description,
  label,
  title,
  tooltipTheme = SECTION_THEME.Dark,
  tooltipAttributes,
  firstCard,
  secondCard,
  titleSize = HEADING_TAG.H4,
  hideImageCardsOnMobile = false,
  onBentoModalChange,
}) => {
  const { isSmallDesktop } = useDeviceSize();
  const [activeBentoCard, setActiveBentoCard] = useState<BENTO_CARD_MODAL_STATUS>(BENTO_CARD_MODAL_STATUS.None);

  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const secondCardIsImage = useMemo(() => Object.hasOwn(secondCard, 'src'), [secondCard]);

  // TODO: We cannot read components that are not renedered when unit testing this
  // besides, in order to trigger the modal close we need to access shadow root elements
  // which is not possible within jsdom, so a mock will need to be in place.
  // Eventually we should find a way around this and we're tracking that in this ticket:
  // https://issues.amazon.com/issues/ASX-462
  // istanbul ignore next

  const handleModalClose = useCallback(() => {
    setActiveBentoCard(BENTO_CARD_MODAL_STATUS.None);
    if (onBentoModalChange) onBentoModalChange(BENTO_CARD_MODAL_STATUS.None);

    const navigationTabsCta = document.getElementById(NAVIGATION_TABS_ELEMENT_ID);

    if (navigationTabsCta) {
      // change navigation tabs cta zindex back to normal to avoid undesired overlaps
      navigationTabsCta.style.zIndex = NAVIGATION_TABS_Z_INDEX.VISIBLE;
    }
  }, []);

  // TODO: We cannot read components that are not renedered when unit testing this
  // while we still have a test for the bento card click it will not pass coverage
  // eventually we should find a way around this and we're tracking that in this ticket:
  // https://issues.amazon.com/issues/ASX-462
  // istanbul ignore next
  const handleBentoCardClick = useCallback((newActiveBentoCard: BENTO_CARD_MODAL_STATUS) => {
    setActiveBentoCard(newActiveBentoCard);
    if (onBentoModalChange) onBentoModalChange(newActiveBentoCard);

    setTimeout(() => {
      // close navbar to ensure it doesn't overlap
      const navbar = document.getElementById('sc-navbar-container');
      if (navbar) {
        navbar.classList.remove('show');
        navbar.classList.add('hide');
        navbar.setAttribute('aria-hidden', 'false');
      }

      const navigationTabsCta = document.getElementById(NAVIGATION_TABS_ELEMENT_ID);

      if (navigationTabsCta) {
        // change navigation tabs cta zindex to avoid overlaping
        navigationTabsCta.style.zIndex = NAVIGATION_TABS_Z_INDEX.INVISIBLE;

        // and manage styling to keep it consistent with the navbar hiding
        window.requestAnimationFrame(() => navigationTabsCta.classList.remove(navigationTabsStyles.withNavbar));
      }
    }, 100);
  }, []);

  const secondBentoCard = secondCard as BentoCardContent;

  // Rendering helper to display the expected Bento card variaton
  const renderBentoVariation = (
    bentoCard: BentoCardContent,
    cardIndex: number,
    theme: SECTION_THEME = SECTION_THEME.Dark
  ) =>
    bentoCard.image ? (
      <BentoImage
        image={bentoCard.image}
        className={classNames(styles.card, styles.gridElement)}
        contentClassName={styles.bentoCardContainer}
        theme={theme}
        onClick={() => (bentoCard.modal ? handleBentoCardClick(cardIndex) : null)}
      >
        <div className={styles.titleCardContainer}>
          <Tagline className={classNames(styles.tagline, styles.firstTagline)}>{bentoCard.label}</Tagline>
          <Heading className={styles.cardHeading} as={titleSize} size={titleSize as unknown as HEADING_SIZE}>
            {bentoCard.title}
          </Heading>
        </div>
      </BentoImage>
    ) : (
      <BentoCard className={classNames(styles.card, styles.gridElement)} label={bentoCard.label}>
        <div className={styles.content}>
          <Heading className={styles.heading} as={HEADING_TAG.H3} size={HEADING_SIZE.h2}>
            {bentoCard.title}
          </Heading>

          <p className={styles.description}>{bentoCard.description}</p>
        </div>
      </BentoCard>
    );

  return (
    <div className={styles.bentoCards}>
      <div className={classNames(styles.textContent, styles.gridElement)}>
        <Tagline className={styles.tagline}>{label}</Tagline>
        <Heading className={styles.heading} as={HEADING_TAG.H3} size={HEADING_SIZE.h3}>
          {title}
        </Heading>
        <p data-track-label={`${label}-${title}`} className={styles.description}>
          <Tooltip
            copy={description}
            tooltipAttributes={tooltipAttributes}
            tooltipTheme={tooltipTheme}
            canBeOpened={activeBentoCard === BENTO_CARD_MODAL_STATUS.None}
          />
        </p>
      </div>

      {/* Render card elements based on the Bento variation */}
      <CardsWrapper key={title}>
        {renderBentoVariation(firstCard, BENTO_CARD_MODAL_STATUS.FIRST_CARD_INDEX)}

        {/* special case for second card in case it is an image */}
        {secondCardIsImage && !(!isSmallDesktop && hideImageCardsOnMobile) && (
          <AsxImage
            className={classNames(styles.card, styles.gridElement, styles.imageCard)}
            componentName="BentoCards"
            {...(secondCard as ImageAttributes)}
          />
        )}
        {!secondCardIsImage &&
          renderBentoVariation(secondBentoCard, BENTO_CARD_MODAL_STATUS.SECOND_CARD_INDEX, SECTION_THEME.Light)}
      </CardsWrapper>

      {firstCard.modal && (
        <ModalImageContent
          isVisible={activeBentoCard === BENTO_CARD_MODAL_STATUS.FIRST_CARD_INDEX}
          close={handleModalClose}
          imageContent={{
            isSmallVariant: true,
            invertedColor: true,
            tagline: firstCard.label,
            title: firstCard.title,
            image: firstCard.modal.image,
            copy: firstCard.modal.modalContent,
            ...(firstCard.modal.link?.label &&
              firstCard.modal.link?.url && {
                footerContent: {
                  hasInvertedColors: true,
                  iconName: firstCard.modal.link.icon ?? undefined,
                  copy: firstCard.modal.link.isSource ?? true ? t(commonBundle, 'source') : undefined,
                  link: {
                    linkCopy: firstCard.modal.link.label,
                    linkHref: firstCard.modal.link.url,
                  },
                },
              }),
          }}
        />
      )}

      {!secondCardIsImage && secondBentoCard.modal && (
        <ModalImageContent
          isVisible={activeBentoCard === BENTO_CARD_MODAL_STATUS.SECOND_CARD_INDEX}
          close={handleModalClose}
          imageContent={{
            isSmallVariant: true,
            invertedColor: true,
            tagline: secondBentoCard.label,
            title: secondBentoCard.title,
            image: secondBentoCard.modal.image,
            copy: secondBentoCard.modal.modalContent,
            ...(secondBentoCard.modal.link?.label &&
              secondBentoCard.modal.link?.url && {
                footerContent: {
                  hasInvertedColors: true,
                  iconName: secondBentoCard.modal.link?.icon ?? undefined,
                  copy: secondBentoCard.modal.link.isSource ?? true ? t(commonBundle, 'source') : undefined,
                  link: {
                    linkCopy: secondBentoCard.modal.link!.label,
                    linkHref: secondBentoCard.modal.link!.url,
                  },
                } as ModalFooterContentProps,
              }),
          }}
        />
      )}
    </div>
  );
};

import classNames from 'classnames';
import { HTMLAttributes, HTMLInputTypeAttribute, ReactElement, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { Icon, IconType } from 'src/components/atoms';

import styles from './TextInput.module.scss';

interface TextInputProps extends HTMLAttributes<HTMLInputElement> {
  label: string;
  inputType: HTMLInputTypeAttribute;
  id: string;
  value: string;
  errors?: FieldError;
  disabled?: boolean;
  iconName?: IconType;
  placeholder?: string;
}

export function TextInput({
  label,
  inputType,
  id,
  value,
  errors,
  disabled,
  iconName,
  placeholder,
  ...props
}: TextInputProps): ReactElement {
  const [isFocused, setIsFocused] = useState(false);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [labelOffset, setLabelOffset] = useState<number>(-16);

  const updateLabelOffset = () => {
    // This block dynamically handles the height of labels, we've come across a label that can span 2 lines on mobile
    // for that reason we want to scan and dynamically adjust it's height to be able to contain n number of lines
    // while also adjusting the height of other elements to keep consistency
    if (labelRef.current) {
      const lineHeight = parseFloat(getComputedStyle(labelRef.current).lineHeight);
      const height = labelRef.current.clientHeight;

      const numberOfLines = Math.floor(height / lineHeight);

      // We want to make sure that we always have at least -16px of offset
      setLabelOffset(Math.min(-16 * numberOfLines, -16));
    }
  };

  useEffect(() => {
    updateLabelOffset();

    window.addEventListener('resize', updateLabelOffset);
    return () => {
      window.removeEventListener('resize', updateLabelOffset);
    };
  }, [label]);

  const adjustedInputWrapperHeight = `${24 + Math.abs(labelOffset)}px`;
  const adjustedLabelOffset = `${labelOffset}px`;
  const adjustedInputTopMargin = `${Math.abs(labelOffset) - 16}px`;

  return (
    <div onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
      <div
        className={classNames(styles.inputWrapper, errors && styles.error, disabled && styles.disabled)}
        style={{ height: adjustedInputWrapperHeight }}
      >
        <label
          ref={labelRef}
          htmlFor={id}
          data-testid="text-input-label"
          className={classNames(
            styles.label,
            errors && styles.error,
            disabled && styles.disabled,
            (isFocused || value) && styles.active
          )}
          style={{ top: (isFocused || value) && adjustedLabelOffset }}
        >
          {label}
        </label>
        <input
          className={classNames(styles.textInput, errors && styles.error, disabled && styles.disabled)}
          data-testid="text-input"
          type={inputType}
          id={id}
          name={id}
          disabled={disabled}
          aria-disabled={disabled}
          style={{ marginTop: adjustedInputTopMargin }}
          placeholder={isFocused ? placeholder : undefined}
          {...props}
        />
        {iconName && (
          <Icon
            name={iconName}
            className={classNames(styles.textInputIcon, errors && styles.error, disabled && styles.disabled)}
          />
        )}
      </div>
      {errors && !disabled && (
        <div className={styles.errorWrapper}>
          <Icon name="error" className={styles.errorIcon} />
          <p className={styles.errorMessage}>{errors.message}</p>
        </div>
      )}
    </div>
  );
}

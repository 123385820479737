import { useState, useEffect, useCallback, RefObject } from 'react';

export const useEnterKeyHandler = (modalRef: RefObject<HTMLElement>, isVisible: boolean) => {
  useEffect(() => {
    if (!isVisible || !modalRef.current) return undefined;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return undefined;

      const activeElement = document.activeElement as HTMLElement | null;
      if (!modalRef.current?.contains(activeElement)) return undefined;

      // In case the element is an interactable radio button allow its selection with the enter key
      if (activeElement?.matches('input[type="radio"]')) activeElement.click();

      if (activeElement?.getAttribute('aria-haspopup') === 'true') {
        event.preventDefault();

        activeElement.click();

        const isExpanded = activeElement.getAttribute('aria-expanded') === 'true';

        // Focus on the first dropdown option if expanding
        if (!isExpanded) {
          setTimeout(() => {
            const dropdownOption = activeElement.nextElementSibling?.querySelector('button, input[type="checkbox"]');
            if (dropdownOption instanceof HTMLElement) {
              dropdownOption.focus();
            }
          }, 0);
        }
      }
      return undefined;
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [modalRef, isVisible]);
  return undefined;
};

export const useFocusableElements = (
  modalRef: RefObject<HTMLElement>,
  isVisible: boolean,
  shouldOverrideFocus: boolean
) => {
  const [focusableElements, setFocusableElements] = useState<HTMLElement[]>([]);

  const updateFocusableElements = useCallback(() => {
    if (!isVisible || !modalRef.current) return;

    const modal = modalRef.current;
    const modalShadowRoot = modal.shadowRoot;
    const modalBody = modalShadowRoot?.querySelector('.body') as HTMLDivElement | null;
    const modalFooter = modalShadowRoot?.querySelector('.footer') as HTMLDivElement | null;
    const closeButton = modalShadowRoot?.querySelector('button.close') as HTMLButtonElement | null;
    const submitButton = modal.querySelector('button[type="submit"]') as HTMLButtonElement | null;

    if (!modalBody || !closeButton || !modalFooter) return;

    const elements: HTMLElement[] = [closeButton, modalBody];
    const slot = modalBody.querySelector('slot');
    const slotFooter = modalFooter.querySelector('slot');

    // istanbul ignore next
    if (slot && shouldOverrideFocus && slotFooter) {
      const slottedContent = slot.assignedNodes();
      const slottedFooterContent = slotFooter.assignedNodes();

      // adding interactive elements inside the footer
      const footerInteractiveElements = Array.from(slottedFooterContent).flatMap((node) =>
        node instanceof HTMLElement
          ? Array.from(
              node.querySelectorAll<HTMLElement>(
                'input[type="radio"], button[aria-haspopup="true"],  a[href],input[type="checkbox"]'
              )
            )
          : []
      );

      const interactiveElements = Array.from(slottedContent).flatMap((node) =>
        node instanceof HTMLElement
          ? Array.from(
              node.querySelectorAll<HTMLElement>(
                'input[type="radio"], button[aria-haspopup="true"], a[href], input[type="checkbox"]'
              )
            )
          : []
      );

      elements.push(...interactiveElements, ...footerInteractiveElements);

      if (submitButton) elements.push(submitButton);
    }

    setFocusableElements(elements);
  }, [isVisible, modalRef, shouldOverrideFocus]);

  useEffect(() => {
    updateFocusableElements();

    const observer = new MutationObserver(updateFocusableElements);
    const modalElement = modalRef.current;
    if (modalElement) {
      observer.observe(modalElement, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['aria-expanded'],
      });
    }

    return () => observer.disconnect();
  }, [updateFocusableElements, modalRef]);

  return { focusableElements, updateFocusableElements };
};

const useFocusTrap = (focusableElements: HTMLElement[], isVisible: boolean, modalRef: RefObject<HTMLElement>) => {
  const [currentFocusIndex, setCurrentFocusIndex] = useState(0);

  useEffect(() => {
    if (!isVisible) {
      setCurrentFocusIndex(0);
    }
  }, [isVisible]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!isVisible || !modalRef.current?.contains(event.target as Node)) return;

      if (event.key === 'Tab') {
        event.preventDefault();

        const direction = event.shiftKey ? -1 : 1;
        let newIndex = (currentFocusIndex + direction + focusableElements.length) % focusableElements.length;

        while (focusableElements[newIndex]?.hasAttribute('disabled')) {
          newIndex = (newIndex + direction + focusableElements.length) % focusableElements.length;
        }

        focusableElements[newIndex]?.focus();
        setCurrentFocusIndex(newIndex);
      }
    },
    [focusableElements, currentFocusIndex, isVisible, modalRef]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);
};

export const useModalAccessibility = (
  modalRef: RefObject<HTMLElement>,
  isVisible: boolean,
  shouldOverrideFocus: boolean
) => {
  const { focusableElements, updateFocusableElements } = useFocusableElements(modalRef, isVisible, shouldOverrideFocus);
  useFocusTrap(focusableElements, isVisible, modalRef);
  useEnterKeyHandler(modalRef, isVisible);

  useEffect(() => {
    if (!isVisible) {
      updateFocusableElements();
    }
  }, [isVisible, updateFocusableElements]);

  useEffect(() => {
    if (!isVisible || !modalRef.current) return undefined;

    const modal = modalRef.current;
    if (modal) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible, modalRef]);

  useEffect(() => {
    if (!isVisible || !modalRef.current) return undefined;

    const modal = modalRef.current;
    const modalShadowRoot = modal.shadowRoot;
    const modalBody = modalShadowRoot?.querySelector('.body') as HTMLDivElement | null;
    const closeButton = modalShadowRoot?.querySelector('button.close') as HTMLButtonElement | null;

    if (modalBody && closeButton) {
      modalBody.setAttribute('tabindex', '0');
      closeButton.focus();

      return () => {
        modalBody.setAttribute('tabindex', '-1');
      };
    }
    return undefined;
  }, [isVisible, modalRef]);
};

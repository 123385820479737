import classNames from 'classnames';
import { ElementRef, FunctionComponent, PropsWithChildren, ReactNode, useEffect, useRef } from 'react';

import { KatModal } from '@amzn/katal-react';

import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { useModalAccessibility } from 'src/hooks/useModalAccessibility';

import styles from './ModalWrapper.module.scss';

export enum MODAL_VARIANT {
  info = 'info',
  form = 'form',
  stats = 'stats',
  quote = 'quote',
  image = 'image',
  tabs = 'tabs',
}

export interface ModalProps extends PropsWithChildren {
  isVisible: boolean;
  close: () => void;
  title?: string;
  subtitle?: string;
  variant?: MODAL_VARIANT;
  footer?: ReactNode;
  className?: string;
  shouldOverrideFocus?: boolean;
  invertedColor?: boolean;
  onDialogHeightChange?: (height: number) => void;
  onFooterHeightChange?: (height: number) => void;
}

/**
 * This component is a wrapper around KatModal. It includes styling and accessibility for the Modal container,
 * and can accept the inner modal content as children.
 * @param isVisible - boolean value indicating if the modal is visible
 * @param close - function that is triggered onClose
 * @param title - string value for the title of the modal
 * @param subtitle - string value for the subtitle of the modal
 * @param children - inner content of the modal
 * @param variant - type of modal container styling that applies
 * @param footer - inner content of the modal footer
 * @param className - class name to be applied to modal
 */
// TODO create storybook stories for new Modal components https://i.amazon.com/issues/ASX-244
export const ModalWrapper: FunctionComponent<ModalProps> = ({
  isVisible,
  close,
  title,
  subtitle,
  children,
  variant = MODAL_VARIANT.info,
  shouldOverrideFocus = variant !== MODAL_VARIANT.info,
  footer,
  className,
  invertedColor,
  onDialogHeightChange,
  onFooterHeightChange,
}) => {
  const { isMediumDevice, isLargeDevice } = useDeviceSize();

  const modalRef = useRef<ElementRef<typeof KatModal>>(null);

  // istanbul ignore next
  useEffect(() => {
    const applyStyles = () => {
      const modal = modalRef.current;
      const modalShadowRoot = modal?.shadowRoot;

      const container = modalShadowRoot?.querySelector('.container') as HTMLElement | null;
      const dialog = modalShadowRoot?.querySelector('.dialog') as HTMLElement | null;
      const header = modalShadowRoot?.querySelector('.header') as HTMLElement | null;
      const body = modalShadowRoot?.querySelector('.body') as HTMLElement | null;
      const closeButton = modalShadowRoot?.querySelector('.close') as HTMLElement | null;
      const footerElement = modalShadowRoot?.querySelector('.footer') as HTMLElement | null;

      if (dialog && container && variant === MODAL_VARIANT.quote) {
        if (isMediumDevice) {
          dialog.style.height = 'auto';
        } else {
          dialog.style.height = '100%';
        }
      }

      if (dialog) {
        const { height } = dialog.getBoundingClientRect();
        if (onDialogHeightChange) {
          onDialogHeightChange(height);
        }
      }

      if (dialog && container && variant === MODAL_VARIANT.tabs) {
        if (isLargeDevice) {
          dialog.style.removeProperty('width');
          dialog.style.removeProperty('height');
          container.style.removeProperty('justify-content');
        } else {
          dialog.style.width = '100%';
          dialog.style.height = 'auto';
          container.style.justifyContent = 'flex-end';
        }
      }

      if (footerElement) {
        const { height } = footerElement.getBoundingClientRect();
        if (onFooterHeightChange) {
          onFooterHeightChange(height);
        }
        if (variant === MODAL_VARIANT.stats) {
          footerElement.style.display = 'block';
        }
      }

      if (header && variant === MODAL_VARIANT.image) {
        header.style.position = 'absolute';
        header.style.width = '100%';
      }

      if (body) {
        if (variant !== MODAL_VARIANT.info && variant !== MODAL_VARIANT.form && variant !== MODAL_VARIANT.tabs) {
          body.style.overflow = 'hidden';
          body.style.borderBottomLeftRadius = '0';
          body.style.borderBottomRightRadius = '0';
        }
      }

      if (closeButton && variant === MODAL_VARIANT.image) {
        closeButton.style.zIndex = '2';
      }

      if (modalShadowRoot) {
        const modalContainer: HTMLDivElement | null = modalShadowRoot.querySelector('.container');
        if (modalContainer) {
          modalContainer.style.backdropFilter = 'blur(12px)';
          if (variant === MODAL_VARIANT.form) {
            modalContainer.style.alignItems = 'center';
            modalContainer.style.justifyContent = isMediumDevice ? 'flex-end' : 'center';
          } else {
            modalContainer.style.alignItems = 'flex-start';
            if (isMediumDevice) {
              // Since until 768px our modal is essentially in "mobile" layout, we want to align it at the bottom of the screen
              modalContainer.style.justifyContent = 'flex-end';
            }
          }
          modalContainer.style.padding = '8px';

          if (variant === MODAL_VARIANT.tabs) {
            modalContainer.style.paddingBottom = '0';
          }
        }
      }
    };

    const handleResize = () => {
      applyStyles();
      close();
    };

    applyStyles();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize); // Cleanup
  }, [isMediumDevice, variant, onDialogHeightChange, onFooterHeightChange, close, isVisible]);

  useEffect(() => {
    const modal = modalRef.current;

    if (modal) {
      const titleElement = document.getElementById('modal-title');

      if (titleElement) {
        // Use a minimal timeout to ensure the content is ready
        const timeoutId = setTimeout(() => {
          modal.setAttribute('aria-labelledby', titleElement.innerText);
        }, 0);

        return () => clearTimeout(timeoutId); // Clean up to avoid memory leaks
      }
    }
    // Explicitly return undefined if no cleanup is required
    return undefined;
  }, [isVisible]);

  useModalAccessibility(modalRef, isVisible, shouldOverrideFocus);

  return (
    <KatModal
      className={classNames(styles[variant], invertedColor && styles.invertedColor, className)}
      ref={modalRef}
      visible={isVisible}
      onClose={close}
      footer={footer}
    >
      <span slot="title">{variant === MODAL_VARIANT.tabs && <h2 className={styles.Title}>{title}</h2>}</span>

      {variant !== MODAL_VARIANT.tabs && title && (
        <h2 className={styles.Title} id="modal-title">
          {title}
        </h2>
      )}

      {subtitle && <p className={styles.Subtitle}>{subtitle}</p>}
      {children}
    </KatModal>
  );
};

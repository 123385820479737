import classNames from 'classnames';
import { FC, useState } from 'react';

import { AsxImage, Tagline } from 'src/components/atoms';
import {
  MODAL_VARIANT,
  ModalBodyScroll,
  ModalFooterContent,
  ModalFooterContentProps,
  ModalWrapper,
} from 'src/components/common';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './ModalStatsContent.module.scss';

interface ModalStats {
  heading: string;
  copy: string;
  image: ImageAttributes;
}

export interface StatsContent {
  stats: Array<ModalStats>;
  title: string;
  tagline: string;
  footerContent?: ModalFooterContentProps;
}

export interface ModalStatsContentProps {
  statsContent: StatsContent;
  isVisible: boolean;
  close: () => void;
}

/**
 * Content component for the ModalWrapper.tsx, specifically for statss of elements.
 * @param stats - The header and stats of elements to be displayed in the modal content.
 */

export const ModalStatsContent: FC<ModalStatsContentProps> = ({ isVisible, close, statsContent }) => {
  const [dialogHeight, setDialogHeight] = useState<number | null>(null);
  const [footerHeight, setFooterHeight] = useState<number | null>(null);
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [isScrollale, setIsScrollable] = useState(false);

  return (
    <ModalWrapper
      isVisible={isVisible}
      close={close}
      variant={MODAL_VARIANT.stats}
      footer={statsContent.footerContent && <ModalFooterContent {...statsContent.footerContent} />}
      onDialogHeightChange={setDialogHeight}
      onFooterHeightChange={setFooterHeight}
    >
      <div className={styles.contentWrapper}>
        <ModalBodyScroll
          className={styles.modalStatsContainer}
          footerSize={footerHeight}
          containerHeight={dialogHeight}
          isVisible={isVisible}
          onScrollEnd={
            // istanbul ignore next
            () => {
              setIsScrollEnd(true);
            }
          }
          onScrollAway={
            // istanbul ignore next
            () => {
              setIsScrollEnd(false);
            }
          }
          isScrollable={
            // istanbul ignore next
            () => {
              setIsScrollable(true);
            }
          }
          scrollMargin={0}
        >
          <Tagline className={styles.tagline}>{statsContent.tagline}</Tagline>
          <h3 className={styles.modalStatsHeading}>{statsContent.title}</h3>
          <ol className={styles.modalStats}>
            {statsContent.stats.map((stat, index) => (
              <li
                key={`${stat.heading.toLowerCase().replaceAll(' ', '-')}-${index}`}
                className={styles.modalStatsElement}
              >
                <div className={styles.copyWrapper}>
                  <h4>{stat.heading}</h4>
                  <p className={styles.statsCopy}>{stat.copy}</p>
                </div>
                <AsxImage
                  className={styles.image}
                  alt={stat.image.alt}
                  src={stat.image.src}
                  data-testid="ModalStatsContentImage"
                  componentName="ModalStatsContent"
                />
              </li>
            ))}
          </ol>
        </ModalBodyScroll>
        <div className={classNames(styles.gradient, { [styles.scrollEnd]: isScrollEnd || !isScrollale })} />
      </div>
    </ModalWrapper>
  );
};

import classNames from 'classnames';
import { motion } from 'motion/react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { AsxImage, AsxMarkdownContent, FormattedLink, IconType } from 'src/components/atoms';
import {
  ModalStatsContent,
  ModalQuoteContent,
  QuoteContent,
  StatsContent,
  VideoWithPoster,
  TranscriptionFragments,
  NativeVideoPlayer,
} from 'src/components/common';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './FeaturedBanner.module.scss';
import { useFeaturedBanner } from './FeaturedBannerAnimations';

export interface FeaturedBannerProps {
  /**
   * Video content to be displayed in the banner.
   */
  video: {
    /**
     * Thumbnail image displayed before the video plays.
     */
    thumbnail?: ImageAttributes;
    /**
     * Video preview displayed before the video plays.
     */
    previewVideoSrc?: string;
    /**
     * URL source of the video.
     */
    videoSrc: string;
    /**
     * URL source for captions/subtitles.
     */
    captionsSrc?: string;
    /**
     * Array of transcription fragments for accessibility or additional content.
     */
    transcription?: Array<TranscriptionFragments>;
    /**
     * Call-to-action label for the video.
     */
    ctaLabel: string;
    /**
     * Quote for the video preview.
     */
    quote?: string;
    /**
     * Optional field for click tracking of video play.
     */
    playDataTrackLabel?: string;
  };

  /**
   * Main title of the banner, supporting markdown formatting.
   */
  title: string;

  /**
   * Subtitle content that includes an inline modal link and supporting text.
   */
  subTitle: {
    /**
     * Text for the in-text link that opens a modal.
     */
    linkModal: string;
    /**
     * Additional copy displayed next to the link.
     */
    copy: string;
  };

  /**
   * Call-to-action link with an icon.
   */
  link: {
    /**
     * Label text for the link.
     */
    label: string;
    /**
     * Icon displayed alongside the link.
     */
    icon: IconType;
  };

  /**
   * Decorative image displayed within the banner.
   */
  image: ImageAttributes;

  /**
   * Content for the modal triggered by the in-text link.
   */
  inTextLinkModalContent: QuoteContent;

  /**
   * Content for the modal triggered by the main CTA link.
   */
  linkModalContent: StatsContent;
}

export const FeaturedBanner: FunctionComponent<FeaturedBannerProps> = ({
  video,
  title,
  subTitle,
  link,
  image,
  linkModalContent,
  inTextLinkModalContent,
}) => {
  const { refs, parallaxStyles } = useFeaturedBanner();
  const { self, imageRef, copyRef, titleRef, videoRef } = refs;
  const { isLargeDevice, isSmallDesktop } = useDeviceSize();
  const [isInTextLinkModalVisible, setIsInTextLinkModalVisible] = useState(false);
  const [isLinkModalVisible, setIsLinkModalVisible] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleOpenInTextLink = useCallback(() => {
    setIsInTextLinkModalVisible(true);
  }, []);

  const handleOpenLinkModal = useCallback(() => {
    setIsLinkModalVisible(true);
  }, []);

  const handleCloseInTextLink = useCallback(() => {
    // istanbul ignore next
    setIsInTextLinkModalVisible(false);
  }, []);

  const handleCloseLinkModal = useCallback(() => {
    // istanbul ignore next
    setIsLinkModalVisible(false);
  }, []);

  useEffect(() => {
    setIsVideoPlaying(false);
  }, [isSmallDesktop]);

  return (
    <>
      <motion.div className={styles.componentWrapper} ref={self} style={parallaxStyles.selfOpacity}>
        <div className={styles.featuredBanner}>
          <motion.div className={styles.videoSection} style={parallaxStyles.pointerEvents}>
            {!isSmallDesktop && (
              <NativeVideoPlayer
                isVideoPlaying={!isSmallDesktop && isVideoPlaying}
                src={video.videoSrc}
                onVideoClose={() => setIsVideoPlaying(false)}
              />
            )}
            {!(!isSmallDesktop && isVideoPlaying) && (
              <motion.div
                className={classNames(styles.videoWrapper, video.quote && styles.hasQuote)}
                ref={videoRef}
                style={parallaxStyles.videoY}
              >
                {video.quote && <h5 className={styles.quote}>{video.quote}</h5>}
                <VideoWithPoster
                  playDataTrackLabel={video.playDataTrackLabel}
                  thumbnail={video.thumbnail}
                  video={video}
                  ctaLabel={video.ctaLabel}
                  previewVideoSrc={video.previewVideoSrc}
                  onVideoPlay={() => setIsVideoPlaying(true)}
                  isVideoPlaying={isVideoPlaying}
                  setIsVideoPlaying={setIsVideoPlaying}
                />
              </motion.div>
            )}
          </motion.div>

          <div className={styles.titleSection}>
            <motion.div ref={titleRef} style={parallaxStyles.titleY}>
              <AsxMarkdownContent copy={title} className={styles.title} renderAs="h2" />
            </motion.div>
            <motion.div ref={copyRef} style={parallaxStyles.copyY}>
              <p className={styles.copy} ref={copyRef}>
                <FormattedLink
                  className={styles.inTextLink}
                  showUnderline={false}
                  renderAsButton
                  text={subTitle.linkModal}
                  iconName="plus"
                  iconClassName={styles.inTextLinkIcon}
                  onClick={handleOpenInTextLink}
                  data-testid="animated-hero-banner-outro-modal-link"
                />
                {subTitle.copy}
              </p>

              {link && (
                <FormattedLink
                  renderAsButton
                  onClick={handleOpenLinkModal}
                  className={styles.link}
                  text={link.label}
                  iconName={link.icon}
                />
              )}
            </motion.div>
            {isLargeDevice && (
              <motion.div className={styles.imageWrapper} ref={imageRef} style={parallaxStyles.imageY}>
                <AsxImage
                  className={styles.image}
                  alt={image.alt}
                  src={image.src}
                  data-testid="image"
                  componentName="FeaturedBanner"
                />
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
      <ModalQuoteContent
        isVisible={isInTextLinkModalVisible}
        close={handleCloseInTextLink}
        quoteContent={{
          tagline: inTextLinkModalContent.tagline,
          quote: inTextLinkModalContent.quote,
          footerContent: {
            hasInvertedColors: inTextLinkModalContent.footerContent?.hasInvertedColors,
            copy: inTextLinkModalContent.footerContent?.copy,
            iconName: inTextLinkModalContent.footerContent?.iconName as IconType,
            link: inTextLinkModalContent.footerContent?.link
              ? {
                  linkCopy: inTextLinkModalContent.footerContent.link.linkCopy,
                  linkHref: inTextLinkModalContent.footerContent.link.linkHref,
                }
              : undefined,
          },
        }}
      />

      <ModalStatsContent
        isVisible={isLinkModalVisible}
        close={handleCloseLinkModal}
        statsContent={{
          tagline: linkModalContent.tagline,
          title: linkModalContent.title,
          stats: linkModalContent.stats,
          footerContent: {
            hasInvertedColors: linkModalContent.footerContent?.hasInvertedColors,
            iconName: linkModalContent.footerContent?.iconName as IconType,
            link: linkModalContent.footerContent?.link
              ? {
                  linkCopy: linkModalContent.footerContent.link.linkCopy,
                  linkHref: linkModalContent.footerContent.link.linkHref,
                }
              : undefined,
          },
        }}
      />
    </>
  );
};

import { createContext, FunctionComponent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { emitCountMetric, logger, truncateContext } from 'src/logger';
import { ORIGIN_DIMENSION, REFERER_ENCOUNTER, UTM_PARAMS } from 'src/logging-helpers/metrics-constants';

// Interface for UTM parameters
interface UTMParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export const RefererContext = createContext<string | null>(null);
interface HandleParamMetricsProps {
  children: React.ReactNode;
}

/**
 * Wrapper component that checks to see if the app is loaded with a ref tag (modeled as a "ref" query string).
 * If a ref tag is found, emit a metric.
 */
export const HandleParamMetrics: FunctionComponent<HandleParamMetricsProps> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [referer, setReferer] = useState<string | null>(null);

  useEffect(() => {
    const refString = searchParams.get('ref');
    if (refString) {
      setReferer(refString);
      const refContext = { [ORIGIN_DIMENSION]: refString };
      emitCountMetric(REFERER_ENCOUNTER, 1, refContext);
      logger.info('Referer tag found.', truncateContext(refContext));

      // clear out ref tag once captured to avoid emitting metric more than once per page ingress
      searchParams.delete('ref');
    }

    // Handle UTM parameters
    const utmParams: UTMParams = {};
    let hasUtmParams = false;

    // List of UTM parameters to check
    const utmFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

    utmFields.forEach((field) => {
      const value = searchParams.get(field);
      if (value) {
        utmParams[field as keyof UTMParams] = value;
        hasUtmParams = true;
        // Remove the parameter after capturing
        searchParams.delete(field);
      }
    });

    // If we found any UTM parameters, emit a metric with each param as a dimension
    if (hasUtmParams) {
      emitCountMetric(UTM_PARAMS, 1, utmParams);
      logger.info('UTM parameters found.', truncateContext(utmParams as Record<string, string>));
    }

    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return <RefererContext.Provider value={referer}>{children}</RefererContext.Provider>;
};

import classNames from 'classnames';
import { ReactElement, useEffect, useRef, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { BrandTabs, BrandTabsProps, Heading, HEADING_SIZE, PrimaryButtonV2 } from 'src/components/atoms';
import { AdvocatesComponent } from 'src/components/common';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { HEADING_TAG } from 'src/data/enums/Heading';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { parseTextFragments, t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './AnimatedHeroBanner.module.scss';
import { useBannerAnimation } from './useBannerAnimation';

type AnimatedHeroBannerProps = {
  /**  The title in this component is split into 2 fragments, the first one being normal text and the second one having a different
  color */
  title: string;
  /**  The subtitle in this component is the string seen above the CTAs */
  subtitle: string;
  /** This is our first CTA, it doesn't redirect anywhere, it will scroll to the eligibility section in the same page */
  eligibilityCta: {
    label: string;
  };
  /** This is our second CTA, this one does redirect to the Credits Form page */
  getStartedCta: {
    label: string;
    link: string;
  };
  /** This is the reference of our eligibility section, used for the eligiblity CTA that should scroll there */
  eligibilityCriteriaRef: React.MutableRefObject<HTMLDivElement | null>;
  /** The eyebrow is the text seen above the brand tabs */
  eyebrow: string;
  /** The brand tabs configuration and data, including tab options and their associated content. */
  brandTabs: BrandTabsProps;
  /** An array of quotes corresponding to each tab in `BrandTabs`. The active tab determines the displayed quote. */
  quotes: Array<string>;
  backgroundImage: ImageAttributes;
};

/** This component functions as the introduction of the page and consists of 2 sections that are triggered
 * by scroll animations (only on desktop, on mobile both are visible by default with no transition in between),
 * the first one hosting the brand tabs and the second one hosting the quote
 */
export function AnimatedHeroBanner({
  title,
  subtitle,
  eligibilityCta,
  getStartedCta,
  eyebrow,
  brandTabs,
  quotes,
  backgroundImage,
  eligibilityCriteriaRef,
}: AnimatedHeroBannerProps): ReactElement {
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);
  const { isSmallDesktop } = useDeviceSize();
  const { fragment1, fragment2 } = parseTextFragments(title);
  const quoteWrapperRef = useRef<HTMLDivElement | null>(null);

  const {
    activeTab,
    shouldShowQuote,
    brandTabsContainerRef,
    quoteContainerRef,
    handleScroll,
    handleWheelEvent,
    handleTabChange,
    handleTabClick,
    handleEligibilityCtaClick,
  } = useBannerAnimation(isSmallDesktop, eligibilityCriteriaRef);

  useEffect(() => {
    // We don't have animations on mobile
    if (!isSmallDesktop) return;

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', handleWheelEvent);

    // Cleanup listener
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', handleWheelEvent);
    };
  }, [isSmallDesktop, handleScroll, handleWheelEvent]);

  const [isQuoteWrapperFading, setIsQuoteWrapperFading] = useState(false);
  const [displayedTab, setDisplayedTab] = useState(activeTab);

  // This makes it so our quote fades out, is updated and then fades in
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // We are already cleaning up our timeout in the context of our if block, we can't clean it outside of it
    if (activeTab !== displayedTab) {
      setIsQuoteWrapperFading(true);

      const timeoutId = setTimeout(() => {
        setDisplayedTab(activeTab);

        setIsQuoteWrapperFading(false);
      }, 250);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [activeTab, displayedTab]);

  return isSmallDesktop ? (
    <div>
      <div className={styles.backgroundImageContainer}>
        <img
          className={classNames(styles.backgroundImage, shouldShowQuote && styles.animated)}
          src={backgroundImage.src}
          data-testid="animated-hero-banner-background-image"
          alt={backgroundImage.alt}
        />
      </div>
      <div
        data-track-label="Hero"
        className={classNames(styles.animatedHeroBanner, shouldShowQuote && styles.animated)}
        data-testid="animated-hero-banner"
      >
        <h1 className={styles.title} data-testid="animated-hero-banner-title">
          {fragment1} <span className={styles.titleFragment}>{fragment2}</span>
        </h1>
        <p className={styles.subtitle} data-testid="animated-hero-banner-subtitle">
          {subtitle}
        </p>
        <div className={styles.ctasContainer} data-testid="animated-hero-banner-ctas-container">
          <PrimaryButtonV2
            label={eligibilityCta.label}
            className={classNames(styles.eligibilityCta, styles.cta)}
            theme={SECTION_THEME.Gray}
            onClick={handleEligibilityCtaClick}
          />
          <PrimaryButtonV2
            label={getStartedCta.label}
            className={classNames(styles.getStartedCta, styles.cta)}
            icon="chevronRight"
            theme={SECTION_THEME.Dark}
            link={getStartedCta.link}
          />
        </div>
        <div
          className={classNames(styles.brandTabsContainer, shouldShowQuote && styles.animated)}
          ref={brandTabsContainerRef}
        >
          <p className={classNames(styles.eyebrow, shouldShowQuote && styles.animated)}>{eyebrow}</p>
          <BrandTabs
            {...brandTabs}
            onTabChange={handleTabChange}
            onTabClick={handleTabClick}
            shouldCardsExpand={shouldShowQuote}
          />
        </div>
      </div>
      <div data-track-label="Testimonials" ref={quoteContainerRef} className={styles.quoteContainer}>
        {shouldShowQuote && (
          <>
            <div className={styles.brandTabsPlaceholder} />
            <div
              className={classNames(
                styles.quoteWrapper,
                isQuoteWrapperFading && styles.fadeOut,
                !isQuoteWrapperFading && styles.fadeIn
              )}
              ref={quoteWrapperRef}
            >
              <Heading size={HEADING_SIZE.h4} as={HEADING_TAG.H4} key={displayedTab} className={styles.quote}>
                {quotes[displayedTab]}
              </Heading>
            </div>
            <p className={styles.scrollDown}>{t(commonBundle, 'primaryHeroScroll')}</p>
          </>
        )}
      </div>
    </div>
  ) : (
    <>
      <div data-track-label="Hero" className={styles.animatedHeroBanner} data-testid="animated-hero-banner">
        <h1 className={styles.title} data-testid="animated-hero-banner-title">
          {fragment1} <span className={styles.titleFragment}>{fragment2}</span>
        </h1>
        <p className={styles.subtitle} data-testid="animated-hero-banner-subtitle">
          {subtitle}
        </p>
        <div className={styles.ctasContainer} data-testid="animated-hero-banner-ctas-container">
          <PrimaryButtonV2
            label={eligibilityCta.label}
            className={classNames(styles.eligibilityCta, styles.cta)}
            theme={SECTION_THEME.Gray}
            onClick={handleEligibilityCtaClick}
          />
          <PrimaryButtonV2
            label={getStartedCta.label}
            className={classNames(styles.getStartedCta, styles.cta)}
            icon="chevronRight"
            theme={SECTION_THEME.Dark}
            link={getStartedCta.link}
          />
        </div>
      </div>
      <AdvocatesComponent
        dataTrackingLabel="Testimonials"
        eyebrow={eyebrow}
        backgroundImage={backgroundImage}
        brandTabs={brandTabs}
        quotes={quotes}
      />
    </>
  );
}

import { useEffect, useRef } from 'react';

import { logError } from 'src/logger';
import { MONS_SESSION_ID } from 'src/logging-helpers/metrics-constants';
import { getCookie, pdLandingPagePublisher } from 'src/logging-helpers/metrics-helpers';

export interface PDMetricData {
  label: string;
  sessionId: string;
  action?: string;
  timestamp: number;
}

interface TrackingOptions {
  // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
  threshold?: number;
  trackClicks?: boolean;
  trackViews?: boolean;
  delayMs?: number;
}

const DATA_TRACK_LABEL = 'data-track-label';
const DATA_TRACK_ACTION = 'data-track-action';
const DATA_TRACK_LABEL_SELECTOR = `[${DATA_TRACK_LABEL}]`;
const DATA_TRACK_CLICK_SELECTOR = `[${DATA_TRACK_LABEL}][${DATA_TRACK_ACTION}="click"]`;

const emitMetric = (data: PDMetricData) => {
  try {
    pdLandingPagePublisher(data);
  } catch (error) {
    logError('useComponentTracking', 'Failed to emit metric:', error);
  }
};

/**
 * A custom hook for tracking component views and clicks.
 * It uses Intersection Observer to detect when elements become visible in the viewport
 * and tracks clicks on specified elements. The hook emits metrics for these events.
 *
 * @param options - Configuration options for tracking
 * @param options.threshold - Visibility threshold for Intersection Observer (default: 0.75)
 * @param options.trackClicks - Whether to track clicks on all trackable elements (default: false)
 * @param options.trackViews - Whether to track views of elements (default: true)
 * @param options.delayMs - Delay in milliseconds before initializing observers (default: 1000)
 *
 * @returns void
 */
export const useComponentTracking = (options: TrackingOptions = {}) => {
  const { threshold = 0.75, trackClicks = false, trackViews = true, delayMs = 1000 } = options;
  const viewedSections = useRef(new Set<string>());
  const clickListeners = useRef(new Set<Element>()); // Track added click listeners
  const sessionId = getCookie(MONS_SESSION_ID);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const target = entry.target as HTMLElement;
          const label = target.getAttribute(DATA_TRACK_LABEL);
          const action = target.getAttribute(DATA_TRACK_ACTION) || undefined;

          if (label) {
            const metricData: PDMetricData = {
              label,
              action,
              sessionId,
              timestamp: Date.now(),
            };

            if (entry.isIntersecting && !viewedSections.current.has(label)) {
              if (trackViews && action !== 'click') {
                emitMetric(metricData);
                viewedSections.current.add(label);
              }
            }
          }
        });
      },
      { threshold }
    );

    // Delay observer initialization
    const observerTimeout = setTimeout(() => {
      document.querySelectorAll(DATA_TRACK_LABEL_SELECTOR).forEach((el) => observer.observe(el));
    }, delayMs);

    // Click event handler
    const handleClick = (event: Event) => {
      const target = (event.target as HTMLElement).closest(DATA_TRACK_LABEL_SELECTOR);
      if (!target) return;

      const label = target.getAttribute(DATA_TRACK_LABEL);
      const action = target.getAttribute(DATA_TRACK_ACTION) ?? 'click';

      if (label) {
        emitMetric({
          label,
          action,
          sessionId,
          timestamp: Date.now(),
        });
      }
    };

    // Delay click listener initialization
    const clickTimeout = setTimeout(() => {
      const elements = trackClicks
        ? document.querySelectorAll(DATA_TRACK_LABEL_SELECTOR)
        : document.querySelectorAll(DATA_TRACK_CLICK_SELECTOR);

      elements.forEach((el) => {
        if (!clickListeners.current.has(el)) {
          el.addEventListener('click', handleClick);
          clickListeners.current.add(el); // Store added listeners
        }
      });
    }, delayMs);

    return () => {
      clearTimeout(observerTimeout);
      clearTimeout(clickTimeout);
      observer.disconnect();

      // Clean up click listeners from stored elements
      clickListeners.current.forEach((el) => {
        el.removeEventListener('click', handleClick);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clickListeners.current.clear();
    };
  }, [threshold, trackClicks, trackViews, delayMs, sessionId]);
};

import { IconType } from 'src/components/atoms/icons/icons';

import { getLibraryDomainRoot } from './library-helpers';

/**
 * Determines whether a URL should open in a new tab
 * @param targetUrl - The URL to check
 * @returns boolean - true if the URL should open in a new tab, false otherwise
 */
export const openInNewTab = (targetUrl: string | undefined): boolean => {
  // Return false for undefined or empty strings
  if (!targetUrl) {
    return false;
  }

  // List of patterns that indicate relative URLs
  const relativePatterns = [
    '/', // Path relative to root
    '#', // Anchor links
  ];

  // Check if URL starts with any relative pattern
  if (relativePatterns.some((pattern) => targetUrl.startsWith(pattern))) {
    return false;
  }

  const siteDomainRoot = getLibraryDomainRoot().replace('https://library.', '').toLowerCase();
  return !targetUrl.toLowerCase().includes(siteDomainRoot);
};

/**
 * Determines the appropriate icon for a link based on whether it opens in a new tab and any provided icon override
 * @param url - The URL to check
 * @param iconOverride - Optional icon to use instead of the default
 * @returns IconType - The icon name to use
 */
export const getIcon = (url: string | undefined, iconOverride?: IconType): IconType => {
  if (openInNewTab(url)) {
    return 'chevronTopRight';
  }
  return iconOverride || 'chevronRight';
};

import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { CONTACT_FORM_PAGE_BUNDLE_NAME, FormStrings } from 'src/constants';
import { t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';

import { NavigationTabsDesktop } from './desktop/NavigationTabsDesktop';
import { NavigationTabsMobile } from './mobile/NavigationTabsMobile';

export const NAVIGATION_TABS_ELEMENT_ID = 'navigation-tabs-element-js';

export type TabContent = {
  /**
   * Object represting the image displayed in the tab
   */
  image: {
    /*
     String source url where the image is hosted
    */
    src: string;
    /*
     String alt text for a11y purposes
    */
    alt: string;
  };
  /**
   * String label displayed in the tab
   */
  label: string;
};

export interface NavigationTabsProps {
  activeTab: number;
  tabs: TabContent[];
  // Optional prop that can be used to remove the z-index override, allowing modals higher
  // in the z-index hirearchy to display on top
  shouldDisplayBelow?: boolean;
  onTabChange(index: number): void;
}

export const NavigationTabs: FunctionComponent<NavigationTabsProps> = ({ onTabChange, ...props }) => {
  const { isDesktop } = useDeviceTracker();
  const { isSmallDesktop } = useDeviceSize();
  const [formBundle] = useBundle(CONTACT_FORM_PAGE_BUNDLE_NAME);

  const navbarHeight = isSmallDesktop ? 96 : 72;
  const navigationTabsHeight = 96;
  const offsetHeight = navbarHeight + navigationTabsHeight;

  const handleTabChange = (index: number) => {
    const targetSection = document.getElementById('navigation-tabs-content') as HTMLElement | null;

    if (targetSection) {
      const top = isSmallDesktop
        ? window.scrollY + targetSection.getBoundingClientRect().top - 2 * navbarHeight
        : window.scrollY + targetSection.getBoundingClientRect().top - 2 * offsetHeight;

      window.scrollTo({ behavior: 'smooth', top });
    }

    onTabChange(index);
  };

  if (isDesktop) return <NavigationTabsDesktop onTabChange={handleTabChange} {...props} />;

  return (
    <NavigationTabsMobile
      modalTitle={t(formBundle, FormStrings.SELECT_OPTION)}
      onTabChange={handleTabChange}
      {...props}
    />
  );
};

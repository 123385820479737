import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { Icon, PrimaryButtonV2 } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { convertToKebabCase } from 'src/helpers';
import { CtaAttributes } from 'src/interfaces/CommonAttributes';

import styles from './Outro.module.scss';

const renderWithHighlight = (copy: string[]) =>
  copy.map((part, index) => {
    // Regular text
    if (index % 2 === 0) {
      return part;
    }

    // Highlighted text
    return (
      <span key={part.toLowerCase().replaceAll(' ', '-')} className={styles.highlight}>
        {part}
      </span>
    );
  });

interface OutroProps {
  /*
    String - outro title to be shown on the component
  */
  outroTitle: string;

  /*
    String - description shown below of theZ
  */
  description: string;

  /*
    Array - list of contents for the Outro ctas
  */
  ctas: CtaAttributes[];

  /*
    Object - content of the bottom link
  */
  link: CtaAttributes;
}

export const Outro: FunctionComponent<OutroProps> = ({ outroTitle, description, ctas, link }) => {
  const splittedOutroTitle = outroTitle.split('*');

  return (
    <div className={styles.outro}>
      <div className={styles.outroSection}>
        <h2 data-track-label="Outro" className={styles.outroTitle}>
          {renderWithHighlight(splittedOutroTitle)}
        </h2>
        <p className={styles.description}>{description}</p>
        <div className={styles.ctasContainer}>
          {ctas.map((cta, index) => (
            <PrimaryButtonV2
              key={`${convertToKebabCase(cta.label)}-${index}`}
              className={classNames(styles.cta, index === 0 && styles.firstCta, styles.clickEnabled)}
              contentClassName={styles.ctaContent}
              label={cta.label}
              link={cta.url}
              theme={index === 1 ? SECTION_THEME.Dark : SECTION_THEME.Gray}
              icon={index === 1 ? 'chevronRight' : undefined}
            />
          ))}
        </div>
        <a className={classNames(styles.link, styles.clickEnabled)} target="_blank" rel="noreferrer" href={link.url}>
          {link.label}
          <Icon className={styles.icon} name="chevronTopRight" />
        </a>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState, type ReactElement } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { AsxImage, Icon } from 'src/components/atoms';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './BrandTabs.module.scss';

/**
 * Represents the structure of a brand tab option.
 * @interface BrandTabOption
 */
interface BrandTabOption {
  /** The icon to be displayed for the brand. */
  brandIconImage: ImageAttributes;
  /** The profile image for the brand's associated user or entity. */
  profileImage: ImageAttributes;
  /** The name displayed on the profile card. */
  profileName: string;
  /** The occupation or description displayed on the profile card. */
  profileOccupation: string;
}

/**
 * Props for the BrandTabs component.
 * @interface BrandTabsProps
 */
export interface BrandTabsProps {
  /** Array of tab options, each representing a brand with associated profile data. */
  options: BrandTabOption[];
  /** Callback function triggered when the active tab changes. */
  onTabChange?: (index: number) => void;
  /** Callback function triggered when a tab is clicked. */
  onTabClick?: (index: number) => void;
  /** Optional additional class names to apply to the component for styling purposes. */
  className?: string;
  /** Optional boolean to dictate if cards should expand or should only be clickable, for example, in the credits hero banner */
  shouldCardsExpand?: boolean;
}

/**
 * Props for the ProfileCard component.
 * @interface ProfileCardProps
 */
interface ProfileCardProps {
  /** Indicates whether the profile card is active or highlighted. */
  isActive: boolean;
  /** The profile image to display. */
  profileImage: ImageAttributes;
  /** The name to display on the profile card. */
  profileName: string;
  /** The occupation or description to display on the profile card. */
  profileOccupation: string;
}

/**
 * The card is rendered within BrandTabs only on certain screen sizes.
 */
function ProfileCard({ isActive, profileImage, profileName, profileOccupation }: ProfileCardProps) {
  return (
    <div className={classNames(styles.profileWrapper, isActive && styles.isActive)} aria-hidden={!isActive}>
      <AsxImage
        className={styles.profileImage}
        src={profileImage.src}
        alt={profileImage.alt}
        componentName="ProfileCard"
      />
      <div className={styles.profileCopyWrapper}>
        <p className={styles.profileName}>{profileName}</p>
        <p className={styles.profileOccupation}>{profileOccupation}</p>
      </div>
    </div>
  );
}

export function BrandTabs({
  options,
  onTabChange,
  onTabClick,
  className,
  shouldCardsExpand,
}: BrandTabsProps): ReactElement {
  const [activeTab, setActiveTab] = useState(0);
  const [showButtonRight, setShowButtonRight] = useState(false);
  const [showButtonLeft, setShowButtonLeft] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const anchorListRef = useRef<HTMLDivElement>(null);
  const brandTabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const brandIconRefs = useRef<(HTMLImageElement | null)[]>([]);
  const greenLineRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const { isSmallDesktop } = useDeviceSize();

  const scrollDistance = brandTabRefs.current[activeTab]?.offsetWidth;

  const handleTabClick = (index: number) => {
    setActiveTab(index);

    if (onTabChange) {
      onTabChange(index);
    }

    if (onTabClick) {
      onTabClick(index);
    }
  };

  const showRightButtonLogic = useCallback(() => {
    const anchorListElement = anchorListRef.current;

    if (anchorListElement) {
      setShowButtonRight(anchorListElement.scrollWidth > anchorListElement.clientWidth);
    }
  }, [anchorListRef, setShowButtonRight]);

  useEffect(() => {
    showRightButtonLogic();
  }, [showRightButtonLogic]);

  useEffect(() => {
    const anchorListElement = anchorListRef.current;

    const handleResize = () => {
      showRightButtonLogic();

      if (anchorListElement) {
        setIsScrolledToEnd(
          anchorListElement.scrollLeft + anchorListElement.clientWidth >= anchorListElement.scrollWidth
        );
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const anchorListElement = anchorListRef.current;

    if (anchorListElement) {
      const handleListScroll = () => {
        setIsScrolledToEnd(
          anchorListElement.scrollLeft + anchorListElement.clientWidth >= anchorListElement.scrollWidth
        );
        setShowButtonLeft(anchorListElement.scrollLeft > 0);
      };

      anchorListElement.addEventListener('scroll', handleListScroll);

      return () => anchorListElement.removeEventListener('scroll', handleListScroll);
    }

    return undefined;
  }, []);

  // to make the green line that marks the active index centered across resolutions
  // istanbul ignore next
  useEffect(() => {
    brandTabRefs.current.forEach((tab, index) => {
      const brandIcon = brandIconRefs.current[index];
      const greenLine = greenLineRefs.current[index];

      if (brandIcon && greenLine && tab) {
        const iconRect = brandIcon.getBoundingClientRect();
        const tabRect = tab.getBoundingClientRect();

        const centerX = iconRect.left - tabRect.left + iconRect.width / 2;

        greenLine.style.left = `${centerX}px`;
      }
    });
  }, [activeTab, options]);

  useEffect(() => {
    if (isSmallDesktop) {
      if (shouldCardsExpand) {
        showRightButtonLogic();
        // Due to animations happening through the CSS and not triggering a layout change by itself we add this
        // safe guard to check in case of edge cases where it may take longer to update than expected
        setTimeout(() => {
          showRightButtonLogic();
        }, 300);
      } else {
        setShowButtonRight(false);

        setIsScrolledToEnd(false);
      }
    }
  }, [
    shouldCardsExpand,
    anchorListRef.current?.clientWidth,
    anchorListRef.current?.scrollWidth,
    activeTab,
    isSmallDesktop,
  ]);

  // istanbul ignore next
  const handleScrollRight = () => {
    const anchorListElement = anchorListRef.current;

    if (anchorListElement) {
      anchorListElement.scrollBy({ left: scrollDistance, behavior: 'smooth' });
    }
  };

  // istanbul ignore next
  const handleScrollLeft = () => {
    const anchorListElement = anchorListRef.current;

    if (anchorListElement && scrollDistance) {
      anchorListElement.scrollBy({ left: -scrollDistance, behavior: 'smooth' });
    }
  };

  return (
    <div className={classNames(styles.brandTabsContainer, className)}>
      <div className={styles.brandTabsWrapper}>
        <div
          className={classNames(
            styles.brandTabs,
            showButtonLeft && styles.showButtonLeft,
            showButtonRight && !isScrolledToEnd && styles.showButtonRight
          )}
          role="tablist"
          ref={anchorListRef}
        >
          {options.map((option, index) => {
            const isActive = index === activeTab;
            const tabId = `tab-${index + 1}`;
            const panelId = `tabpanel-${index + 1}`;
            const maxWidth = brandTabRefs.current[index]?.scrollWidth;

            return (
              <button
                data-testid={`button ${option.profileName}`}
                ref={(brandTabRef) => {
                  brandTabRefs.current[index] = brandTabRef;
                }}
                type="button"
                role="tab"
                aria-selected={isActive}
                onClick={() => handleTabClick(index)}
                className={classNames(
                  styles.brandTab,
                  shouldCardsExpand && styles.animated,
                  isActive && shouldCardsExpand && styles.isActive
                )}
                id={tabId}
                aria-controls={panelId}
                key={`${option.profileName} ${index}`}
                style={{ width: isActive && shouldCardsExpand ? maxWidth : '' }}
              >
                <div
                  className={styles.greenLine}
                  ref={(greenLineRef) => {
                    greenLineRefs.current[index] = greenLineRef;
                  }}
                />
                <img
                  className={styles.brandIcon}
                  src={option.brandIconImage.src}
                  alt={option.brandIconImage.alt}
                  ref={(brandIconRef) => {
                    brandIconRefs.current[index] = brandIconRef;
                  }}
                />
                {isSmallDesktop && (
                  <ProfileCard
                    isActive={isActive}
                    profileImage={option.profileImage}
                    profileName={option.profileName}
                    profileOccupation={option.profileOccupation}
                  />
                )}
              </button>
            );
          })}
        </div>
        <div className={classNames(styles.buttonWrapperLeft, showButtonLeft && styles.showButtonLeft)}>
          <button
            type="button"
            className={styles.scrollButton}
            onClick={handleScrollLeft}
            aria-label={t(commonBundle, 'button_previous')}
            data-testid="left-button"
          >
            <Icon name="chevronRight" />
          </button>
        </div>
        <div
          className={classNames(
            styles.buttonWrapperRight,
            showButtonRight && !isScrolledToEnd && styles.showButtonRight
          )}
        >
          <button
            type="button"
            className={styles.scrollButton}
            onClick={handleScrollRight}
            aria-label={t(commonBundle, 'button_next')}
            data-testid="right-button"
          >
            <Icon name="chevronRight" />
          </button>
        </div>
      </div>

      {!isSmallDesktop &&
        options.map((option, index) => {
          const isActive = index === activeTab;
          return (
            index === activeTab && (
              <ProfileCard
                isActive={isActive}
                profileImage={option.profileImage}
                profileName={option.profileName}
                profileOccupation={option.profileOccupation}
                key={`${option.profileImage.alt} ${index}`}
              />
            )
          );
        })}
    </div>
  );
}

var endpointMap = {
	en: {
		assetgraphic: "translation-en.json",
		common: "translation-en.json",
		"pages.contactform": "translation-en.json",
		"pages.creditspage": "translation-en.json",
		"pages.creditspagefaq": "translation-en.json",
		"pages.datainitiativepage": "translation-en.json",
		"pages.focusarea": "translation-en.json",
		"pages.gettingstartedpage": "translation-en.json",
		"pages.homepage": "translation-en.json",
		"pages.measurement": "translation-en.json",
		"pages.scienceexchange": "translation-en.json",
		"pages.searchresultspage": "translation-en.json",
		"resources.casestudy": "translation-en.json",
		"resources.collaborators": "translation-en.json",
		"resources.data": "translation-en.json",
		"resources.guidance": "translation-en.json",
		"resources.methodology": "translation-en.json",
		"resources.playbook": "translation-en.json",
		"resources.policy": "translation-en.json",
		"resources.secondaryhero": "translation-en.json",
		"resources.tool": "translation-en.json",
		"templates.error": "translation-en.json",
		"templates.hmd": "translation-en.json",
		"templates.notfound": "translation-en.json"
	},
	"en-US": {
		assetgraphic: "translation-en.json",
		common: "translation-en.json",
		"pages.contactform": "translation-en.json",
		"pages.creditspage": "translation-en.json",
		"pages.creditspagefaq": "translation-en.json",
		"pages.datainitiativepage": "translation-en.json",
		"pages.focusarea": "translation-en.json",
		"pages.gettingstartedpage": "translation-en.json",
		"pages.homepage": "translation-en.json",
		"pages.measurement": "translation-en.json",
		"pages.scienceexchange": "translation-en.json",
		"pages.searchresultspage": "translation-en.json",
		"resources.casestudy": "translation-en.json",
		"resources.collaborators": "translation-en.json",
		"resources.data": "translation-en.json",
		"resources.guidance": "translation-en.json",
		"resources.methodology": "translation-en.json",
		"resources.playbook": "translation-en.json",
		"resources.policy": "translation-en.json",
		"resources.secondaryhero": "translation-en.json",
		"resources.tool": "translation-en.json",
		"templates.error": "translation-en.json",
		"templates.hmd": "translation-en.json",
		"templates.notfound": "translation-en.json"
	},
	und: {
		assetgraphic: "translation.json",
		common: "translation.json",
		"pages.contactform": "translation.json",
		"pages.creditspage": "translation.json",
		"pages.creditspagefaq": "translation.json",
		"pages.datainitiativepage": "translation.json",
		"pages.focusarea": "translation.json",
		"pages.gettingstartedpage": "translation.json",
		"pages.homepage": "translation.json",
		"pages.measurement": "translation.json",
		"pages.scienceexchange": "translation.json",
		"pages.searchresultspage": "translation.json",
		"resources.casestudy": "translation.json",
		"resources.collaborators": "translation.json",
		"resources.data": "translation.json",
		"resources.guidance": "translation.json",
		"resources.methodology": "translation.json",
		"resources.playbook": "translation.json",
		"resources.policy": "translation.json",
		"resources.secondaryhero": "translation.json",
		"resources.tool": "translation.json",
		"templates.error": "translation.json",
		"templates.hmd": "translation.json",
		"templates.notfound": "translation.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };

import { useScroll, useTransform } from 'motion/react';
import { useRef } from 'react';

import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

export const useTextAndCard = () => {
  const { isMediumDesktop } = useDeviceSize();

  const self = useRef(null);
  const smallImageRef = useRef(null);
  const bigImageRef = useRef(null);
  const bigImageMobileRef = useRef(null);
  const titleRef = useRef(null);
  const copyRef = useRef(null);
  const linkRef = useRef(null);
  const cardRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: self,
    offset: ['start end', 'end start'],
  });

  const smallImageY = useTransform(scrollYProgress, [0, 1], ['250px', '-200px']);
  const smallImageOpacity = useTransform(scrollYProgress, [0.6, 0.8], ['1', '0']);
  const bigImageOpacity = useTransform(scrollYProgress, [0.6, 0.8], ['1', '0']);
  const bigImageY = useTransform(
    scrollYProgress,
    [0, 1],
    [isMediumDesktop ? '200px' : '300px', isMediumDesktop ? '-150px' : '-100px']
  );
  const bigImageYMobile = useTransform(scrollYProgress, [0, 1], ['100px', '-100px']);
  const titleY = useTransform(scrollYProgress, [0, 1], ['90px', '-90px']);
  const copyY = useTransform(scrollYProgress, [0, 1], ['70px', '-70px']);
  const linkY = useTransform(scrollYProgress, [0, 1], ['60px', '-60px']);
  const cardY = useTransform(scrollYProgress, [0, 1], ['-50px', '50px']);
  const cardYMobile = useTransform(scrollYProgress, [0, 1], ['150px', '-150px']);
  const selfOpacity = useTransform(scrollYProgress, [0.7, 0.9], ['1', '0']);

  return {
    refs: { self, smallImageRef, bigImageRef, bigImageMobileRef, copyRef, linkRef, cardRef, titleRef },
    parallaxStyles: {
      smallImage: { y: smallImageY, opacity: smallImageOpacity },
      bigImage: { y: bigImageY, opacity: bigImageOpacity },
      bigImageMobile: { y: bigImageYMobile },
      titleY: { y: titleY },
      copyY: { y: copyY },
      linkY: { y: linkY },
      cardY: { y: cardY },
      cardYMobile: { y: cardYMobile },
      selfOpacity: { opacity: selfOpacity },
    },
  };
};

export const CREDITS_THESCIENCE_VIDEO_1_SUBTITLES = `WEBVTT

00:02.252 --> 00:05.255
We don't have time for incremental steps anymore.

00:05.338 --> 00:09.342
We need to both decarbonize
in our corporate value chains,

00:09.342 --> 00:13.430
but we also need
to stabilize our tropical forests,

00:13.430 --> 00:15.181
and we need to invest in nature.

00:16.558 --> 00:19.769
The Intergovernmental Panel on Climate
Change have said that we have to actually

00:19.769 --> 00:23.982
bring global emissions down as close
to zero as possible by mid-century,

00:23.982 --> 00:28.570
and then balance all remaining emissions
with the removal of CO2 from the atmosphere.

00:29.070 --> 00:34.784
To stabilize temperatures
at 1.5°C by the end of the century,

00:34.909 --> 00:39.205
there is no doubt that we're going
to have to offset all remaining emissions

00:39.289 --> 00:42.459
by removing CO2
from the atmosphere.

00:43.293 --> 00:45.336
There are climate solutions

00:45.336 --> 00:48.590
that are available but require more investment right now.

00:49.007 --> 00:50.008
So we at Amazon

00:50.008 --> 00:54.137
are focused on three primary segments:
reducing tropical deforestation,

00:54.471 --> 00:57.140
forest restoration
and technological carbon removal.

00:57.140 --> 00:59.309
We're not aiming for incremental progress.

00:59.559 --> 01:03.354
We're using carbon credits as a tool
to achieve climate breakthroughs.

01:04.189 --> 01:06.649
So 30% of the emissions we have on the planet

01:06.649 --> 01:08.401
comes from our destruction of nature.

01:08.568 --> 01:11.529
And, really, deforestation is the lead
cause there.

01:11.529 --> 01:14.532
There's no way to avoid runaway
climate change without

01:14.532 --> 01:15.867
putting an end to tropical deforestation.

01:17.827 --> 01:20.455
So the LEAF Coalition works
at a jurisdictional scale,

01:20.455 --> 01:24.125
enables a higher quality solution where
we're leveraging public sector mechanisms

01:24.167 --> 01:26.127
to address the drivers of deforestation.

01:26.127 --> 01:28.338
It's legal protections for the forest,

01:28.338 --> 01:29.756
enforcement of those protections,

01:29.756 --> 01:31.341
recognition of indigenous rights,

01:31.341 --> 01:33.009
partnerships with indigenous peoples.

01:33.176 --> 01:37.847
We have laws, rules, principles, safeguards,

01:37.847 --> 01:42.060
a transparency program that
can receive resources

01:42.060 --> 01:46.231
and invite indigenous people to share the benefits.

01:48.775 --> 01:49.776
The global need for carbon

01:49.776 --> 01:53.446
removal is on the order of several billion
tons of CO2 per year.

01:53.738 --> 01:57.242
We want to do as much of that as possible
through natural means, which are a lower cost,

01:57.242 --> 01:59.702
higher co-benefits
for people on the planet.

01:59.828 --> 02:05.333
We've collaborated with external experts
to really advance the benchmark for

02:05.333 --> 02:10.505
how monitoring and verification is done
in forest restoration projects.

02:11.089 --> 02:13.716
Restoring degraded areas recovers

02:13.716 --> 02:17.303
80% of carbon storage within 20 years,

02:17.637 --> 02:22.183
meaning it is one of the most cost
effective solutions for climate goals.

02:22.433 --> 02:24.769
Nature-based solutions
can only get us so far.

02:24.769 --> 02:26.020
We have to also develop

02:26.020 --> 02:29.023
technological means for removing carbon
from the atmosphere.

02:29.482 --> 02:30.942
We're investing in

02:30.942 --> 02:33.820
those technologies that we think
have the greatest potential to scale

02:33.820 --> 02:36.197
and that are ready for
commercial scale deployment.

02:36.197 --> 02:37.699
And right now that's direct air capture.

02:39.409 --> 02:40.702
We're now making

02:40.702 --> 02:44.581
available our carbon credits
that we have originated to companies

02:44.581 --> 02:46.207
who are in a similar journey to us.

02:46.291 --> 02:50.503
We've put the due diligence in,
we put all of our scientific investigation in.

02:50.879 --> 02:54.465
We want to offer these solutions
to everyone in our value

02:54.465 --> 02:55.592
chain who's aligned with us.

02:55.592 --> 03:00.680
That means Amazon business customers, our
suppliers and Climate Pledge signatories.

03:00.680 --> 03:04.434
Those companies also need to meet
some buyer qualification criteria,

03:04.434 --> 03:07.562
which means that they need to have a goal
for net zero carbon

03:07.604 --> 03:11.482
no later than 2050, be decarbonizing
across all three scopes,

03:11.566 --> 03:13.359
Be reporting their emissions,

03:13.359 --> 03:16.404
and have goals in line with the latest climate science.

03:18.364 --> 03:19.407
There's been a lot of attention on

03:19.407 --> 03:22.619
the voluntary carbon market on the quality
of the credit, and rightly so.

03:22.619 --> 03:25.580
What really matters
is the quality of the solution.

03:25.580 --> 03:26.956
Can these things actually add up?

03:26.956 --> 03:28.541
And what we're offering is both.

03:30.543 --> 03:34.881
This is the biggest challenge
that we have ever been faced with.

03:35.256 --> 03:40.094
We have the opportunity
to involve and engage the private sector.

03:40.178 --> 03:43.765
And that, I think, is the most interesting
innovation that's happening right now.

03:44.682 --> 03:47.477
We need everyone in this effort.

03:47.477 --> 03:52.607
This is the time to join us in these
solutions to lean in and invest in nature.
`;

import { FunctionComponent, useContext, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { SaveASXFormResponse } from '@amzn/coral_com-amazon-sssapinterface-model-operations';
import { AsxFormType, FormMetadata } from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';
import { KatProgress } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';

import { SSSAPIClientContext } from 'src/clients/SSSAPIClientProvider';
import { BackButton } from 'src/components/atoms';
import { PageSection } from 'src/components/common';
import { ThankYouPage } from 'src/components/templates/thank-you/ThankYouPage';
import { RefererContext } from 'src/components/wrappers';
import {
  COMMON_BUNDLE_NAME,
  CONTACT_FORM_PAGE_BUNDLE_NAME,
  FORM_OVERRIDES_QUERY_PARAM,
  LOADING_ARIA_LABEL,
  METADATA_QUERY_PARAM,
  SAVE_FORM_ERROR_LABEL,
  SUBMITTING_ARIA_LABEL,
  FormStrings,
} from 'src/constants';
import { t } from 'src/helpers';
import { useApi } from 'src/hooks/use-api';
import { emitCountMetric, logError } from 'src/logger';
import { SAVE_ASX_FORM_ERROR } from 'src/logging-helpers/metrics-constants';

import { ContactForm, ContactFormValues } from './ContactForm';
import styles from './ContactFormPage.module.scss';

const DEFAULT_FORM_VALUES: Partial<ContactFormValues> = {
  formType: Object.values(AsxFormType)[0], // If there's no form override for the form type we always want to default to the first one
};

// TODO fix tests
export const ContactFormPage: FunctionComponent = () => {
  const [contactFormBundle, isContactFormBundleLoading] = useBundle(CONTACT_FORM_PAGE_BUNDLE_NAME);
  const [commonBundle, isCommonBundleLoading] = useBundle(COMMON_BUNDLE_NAME);

  const { saveASXForm } = useContext(SSSAPIClientContext) ?? {};
  const refTag = useContext(RefererContext);

  const [searchParams] = useSearchParams();
  const maybeFormOverrides = searchParams.get(FORM_OVERRIDES_QUERY_PARAM);
  const formMetadata = searchParams.get(METADATA_QUERY_PARAM);
  const initialFormValues = maybeFormOverrides ? JSON.parse(maybeFormOverrides) : DEFAULT_FORM_VALUES;

  const [formType, setFormType] = useState<AsxFormType>(initialFormValues.formType);
  const [error, setError] = useState<Error>();

  const customErrorHandler = (err: Error) => {
    if (err) {
      setError(err);
      emitCountMetric(SAVE_ASX_FORM_ERROR, 1);
      logError('ContactFormPage', 'Error while saving form', err);
    }
  };

  const { isLoading, response, callApi } = useApi<SaveASXFormResponse>(customErrorHandler);

  const handleFormTypeChange = (value: string) => {
    const newFormType = value as AsxFormType;
    setFormType(newFormType);
  };

  const handleFormSubmit: SubmitHandler<ContactFormValues> = (formValues) => {
    const metadata = FormMetadata.clone({
      ...(refTag && { refTag }),
      ...(formMetadata && JSON.parse(formMetadata)),
    });
    callApi(saveASXForm, formValues, metadata);
  };

  const areBundlesLoading = isCommonBundleLoading || isContactFormBundleLoading;

  if (areBundlesLoading) {
    return (
      <div role="status">
        <KatProgress katAriaLabel={t(commonBundle, LOADING_ARIA_LABEL)} indeterminate size="medium" type="circular" />
      </div>
    );
  }

  // Thank You Page
  if (response && response.id) {
    return (
      <ThankYouPage
        isBackButton
        submissionStatus="success"
        copy={t(contactFormBundle, FormStrings.FEEDBACK_SUBMITTED_LABEL)}
        title={t(contactFormBundle, FormStrings.THANKS_LABEL)}
        copyPosition="aboveTitle"
        cta={t(contactFormBundle, FormStrings.PREVIOUS_PAGE_CTA)}
      />
    );
  }
  if (error) {
    return (
      <ThankYouPage
        copy={t(contactFormBundle, FormStrings.RETRY_SUBMISSION_LABEL)}
        title={t(contactFormBundle, FormStrings.FEEDBACK_SUBMISSION_FAILURE_HEADING)}
        cta={t(contactFormBundle, FormStrings.TRY_AGAIN_PAGE_CTA)}
        isBackButton
      />
    );
  }

  return (
    <PageSection>
      <div className={styles.backButtonContainer}>
        <BackButton variant="default" className={styles.backButton} />
      </div>
      <div className={styles.contactFormWrapper}>
        <ContactForm
          contactBundle={contactFormBundle}
          initialFormValues={initialFormValues}
          handleFormSubmit={handleFormSubmit}
          handleFormTypeChange={handleFormTypeChange}
          selectedFormType={formType}
        />
        {isLoading && (
          <div role="status">
            <KatProgress
              katAriaLabel={t(commonBundle, SUBMITTING_ARIA_LABEL)}
              indeterminate
              size="medium"
              type="circular"
            />
          </div>
        )}
        {error && <div>{t(contactFormBundle, SAVE_FORM_ERROR_LABEL)}</div>}
      </div>
    </PageSection>
  );
};

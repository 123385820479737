import classNames from 'classnames';
import { FC } from 'react';

import { Icon, IconType } from 'src/components/atoms';

import styles from './ModalFooterContent.module.scss';

export interface ModalFooterContentProps {
  iconName?: IconType;
  copy?: string;
  link?: { linkCopy: string; linkHref: string };
  hasInvertedColors?: boolean;
}

/**
 * Content component for the ModalWrapper.tsx, specifically for statss of elements.
 * @param stats - The header and stats of elements to be displayed in the modal content.
 */
export const ModalFooterContent: FC<ModalFooterContentProps> = ({ iconName, copy, link, hasInvertedColors }) => (
  <div className={styles.modalFooter}>
    {link && (
      <a href={link.linkHref} className={styles.linkWrapper} target="_blank" rel="noreferrer">
        {iconName && (
          <div className={styles.footerIconWrapper}>
            <Icon
              name={iconName}
              className={classNames(styles.footerIcon, hasInvertedColors && styles.invertedColors)}
            />
          </div>
        )}
        <p className={styles.footerParagraph}>
          {copy && copy} {link.linkCopy}
        </p>
        <Icon name="chevronTopRight" />
      </a>
    )}
  </div>
);

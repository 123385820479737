import { useScroll, useTransform } from 'motion/react';
import { useRef } from 'react';

import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

export const useFeaturedBanner = () => {
  const self = useRef(null);
  const videoRef = useRef(null);
  const titleRef = useRef(null);
  const copyRef = useRef(null);
  const imageRef = useRef(null);

  const { isSmallDesktop } = useDeviceSize();

  const { scrollYProgress } = useScroll({
    target: self,
    offset: ['start end', 'end start'],
  });

  const videoY = useTransform(
    scrollYProgress,
    [0, 1],
    [isSmallDesktop ? '0px' : '150px', isSmallDesktop ? '50px' : '-100px']
  );
  const titleY = useTransform(scrollYProgress, [0, 1], ['85px', '-85px']);
  const copyY = useTransform(scrollYProgress, [0, 1], ['70px', '-70px']);
  const imageY = useTransform(scrollYProgress, [0, 1], ['250px', '-250px']);
  const selfOpacity = useTransform(scrollYProgress, [0.7, 0.9], ['1', '0']);
  const pointerEvents = useTransform(scrollYProgress, [0, 0.69, 0.7], ['auto', 'auto', 'none']);

  return {
    refs: { self, imageRef, copyRef, titleRef, videoRef },
    parallaxStyles: {
      imageY: { y: imageY },
      copyY: { y: copyY },
      titleY: { y: titleY },
      videoY: { y: videoY },
      selfOpacity: { opacity: selfOpacity },
      pointerEvents: { pointerEvents },
    },
  };
};

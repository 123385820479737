import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, useCallback, useEffect, useRef } from 'react';

import styles from './ModalBodyScroll.module.scss';

export interface ModalBodyScrollProps extends PropsWithChildren {
  footerSize?: number | null;
  headerHeight?: number;
  containerHeight?: number | null;
  className?: string;
  isVisible?: boolean;
  scrollMargin?: number;
  onScrollEnd?: () => void; // Callback when scroll reaches bottom
  onScrollAway?: () => void; // Callback when scrolling away from bottom
  isScrollable?: () => void; // Callback when scrolling is possible
}

export const ModalBodyScroll: FunctionComponent<ModalBodyScrollProps> = ({
  footerSize = 0,
  headerHeight = 86,
  containerHeight,
  children,
  className,
  isVisible,
  scrollMargin = 30,
  onScrollEnd,
  onScrollAway,
  isScrollable,
}) => {
  const copyWrapperRef = useRef<HTMLDivElement>(null);
  const isAtBottomRef = useRef(false);

  const handleResize = useCallback(() => {
    if (containerHeight && copyWrapperRef.current && isVisible && footerSize) {
      const bodyHeight = containerHeight - headerHeight - footerSize;
      const copyWrapperHeight = copyWrapperRef.current.getBoundingClientRect().height;

      if (copyWrapperHeight > bodyHeight) {
        isScrollable?.();
      }

      if (copyWrapperHeight >= bodyHeight) {
        copyWrapperRef.current.style.height = `${bodyHeight - scrollMargin}px`;
      }
    }
  }, [containerHeight, headerHeight, footerSize, isVisible, isScrollable, copyWrapperRef]);

  // istanbul ignore next
  const handleScroll = useCallback(() => {
    if (copyWrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = copyWrapperRef.current;
      const bottomScrollMargin = 30;
      const isAtBottom = scrollTop + clientHeight + bottomScrollMargin >= scrollHeight;

      if (isAtBottom && !isAtBottomRef.current) {
        onScrollEnd?.();
      }

      if (!isAtBottom && isAtBottomRef.current) {
        onScrollAway?.();
      }

      isAtBottomRef.current = isAtBottom;
    }
  }, [onScrollEnd, onScrollAway, scrollMargin, copyWrapperRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    const wrapper = copyWrapperRef.current;
    if (wrapper) {
      wrapper.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (wrapper) {
        wrapper.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleResize, handleScroll]);

  return (
    <div className={classNames(className, styles.modalBodyScroll)} ref={copyWrapperRef} data-testid="wrapper">
      {children}
    </div>
  );
};

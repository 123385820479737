import { useScroll, useTransform, useSpring } from 'motion/react';
import { useRef } from 'react';

export const useGroupAnimation = () => {
  const groupRef = useRef(null);
  const featuredBannerRef = useRef(null);
  const textRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: groupRef,
    offset: ['start end', 'end start'],
  });

  // Create a threshold at 50% scroll progress
  const isPastHalf = useTransform(scrollYProgress, [0.5, 0.51], [0, 1]);

  // Smooth the transition between colors using useSpring
  const smoothTransition = useSpring(isPastHalf, {
    stiffness: 300,
    damping: 30,
  });

  // Change color instantly when passing 50% threshold
  const groupColor = useTransform(smoothTransition, [0, 1], ['#232F3E', '#F2F7F7']);
  const featuredBannerOpacity = useTransform(smoothTransition, [0, 1], ['0', '1']);
  const textOpacity = useTransform(scrollYProgress, [0.8, 0.9], ['0', '1']);

  return {
    refs: { groupRef, featuredBannerRef, textRef },
    colorStyles: {
      groupColor: { backgroundColor: groupColor },
      featuredBannerOpacity: { opacity: featuredBannerOpacity },
      textOpacity: { opacity: textOpacity },
    },
  };
};

import classNames from 'classnames';
import { HTMLAttributes, ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { useBundle } from '@amzn/react-arb-tools';
import base from '@amzn/sss-website-theme/dist/theme/base.module.scss';

import { Icon } from 'src/components/atoms';
import { IconType } from 'src/components/atoms/icons/icons';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { t, splitLastWordFromText } from 'src/helpers';

import styles from './FormattedLink.module.scss';

interface FormattedLinkProps extends HTMLAttributes<HTMLAnchorElement | HTMLButtonElement> {
  text: string;
  link?: string;
  bold?: boolean;
  variant?: 'raised' | 'outlined';
  showIcon?: boolean;
  iconName?: IconType;
  onClick?: () => void;
  isInternal?: boolean;
  openNewTab?: boolean;
  renderAsButton?: boolean;
  showUnderline?: boolean;
  iconClassName?: string;
}

interface FormattedLinkContentProps {
  text: string;
  bold: boolean;
  showIcon: boolean;
  iconName: IconType;
  variant: 'raised' | 'outlined';
  openNewTab?: boolean;
  showUnderline?: boolean;
  iconClassName?: string;
}

function FormattedLinkContent({
  text,
  bold,
  showIcon,
  iconName,
  variant,
  openNewTab,
  showUnderline,
  iconClassName,
}: FormattedLinkContentProps): ReactElement {
  const [initialCopy, lastWord] = splitLastWordFromText(text);
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  return (
    <span
      className={classNames(styles.textWrapper, bold && styles.bold, showUnderline === false && styles.noUnderline)}
    >
      <span className={classNames(styles.text, bold && styles.bold)}>{initialCopy} </span>
      <span className={classNames(styles.text, bold && styles.bold, styles.lastWord)}>
        {lastWord}&nbsp;
        {showIcon && (
          <div className={classNames(styles.iconsWrapper, variant === 'outlined' && styles.iconsWrapperOutlined)}>
            <Icon name={iconName} className={classNames(styles.icon, iconClassName)} />
          </div>
        )}
      </span>
      {openNewTab && <span className={base.visuallyHidden}>{t(commonBundle, 'new_tab')}</span>}
    </span>
  );
}

export function FormattedLink({
  text,
  link,
  showIcon = true,
  iconName = 'chevronTopRight',
  variant = 'raised',
  bold = false,
  className,
  onClick,
  isInternal,
  openNewTab,
  renderAsButton,
  showUnderline,
  iconClassName,
  ...props
}: FormattedLinkProps): ReactElement {
  const linkProps = {
    className: classNames(styles.externalLink, className, 'button-link'),
    ...props,
  };

  if (renderAsButton) {
    return (
      <button onClick={onClick} {...linkProps} type="button">
        <FormattedLinkContent
          text={text}
          bold={bold}
          showIcon={showIcon}
          iconName={iconName}
          variant={variant}
          openNewTab={openNewTab}
          showUnderline={showUnderline}
          iconClassName={iconClassName}
        />
      </button>
    );
  }

  const Element = isInternal ? Link : 'a';
  const elementProps: any = isInternal
    ? { to: link || '#', state: { previousUrl: window.location.href } }
    : { href: link, target: openNewTab ? '_blank' : undefined, rel: openNewTab ? 'noreferrer' : undefined };

  return (
    <Element {...elementProps} {...linkProps} onClick={onClick}>
      <FormattedLinkContent
        text={text}
        bold={bold}
        showIcon={showIcon}
        iconName={iconName}
        variant={variant}
        openNewTab={openNewTab}
        showUnderline={showUnderline}
        iconClassName={iconClassName}
      />
    </Element>
  );
}

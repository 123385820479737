import classNames from 'classnames';
import { FunctionComponent, useRef, useState } from 'react';

import { AsxImage, AsxMarkdownContent, Tagline } from 'src/components/atoms';
import {
  MODAL_VARIANT,
  ModalBodyScroll,
  ModalFooterContent,
  ModalFooterContentProps,
  ModalWrapper,
} from 'src/components/common';
import { ImageWithTaglinesAttributes } from 'src/interfaces/CommonAttributes';

import styles from './ModalImageContent.module.scss';

export interface ImageContent {
  title: string;
  tagline: string;
  copy: string;
  image: ImageWithTaglinesAttributes;
  isSmallVariant?: boolean;
  footerContent?: ModalFooterContentProps;
  invertedColor?: boolean;
}

export interface ModalImageContentProps {
  imageContent: ImageContent;
  isVisible: boolean;
  close: () => void;
}

export const ModalImageContent: FunctionComponent<ModalImageContentProps> = ({ isVisible, close, imageContent }) => {
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [dialogHeight, setDialogHeight] = useState<number | null>(null);
  const [footerHeight, setFooterHeight] = useState<number | null>(null);

  return (
    <ModalWrapper
      isVisible={isVisible}
      close={close}
      variant={MODAL_VARIANT.image}
      footer={imageContent.footerContent && <ModalFooterContent {...imageContent.footerContent} />}
      invertedColor={imageContent.invertedColor}
      onDialogHeightChange={setDialogHeight}
      onFooterHeightChange={setFooterHeight}
    >
      <div className={styles.modalImageContainer}>
        <div
          className={classNames(styles.imageWrapper, imageContent.isSmallVariant && styles.isSmallVariant)}
          ref={imageContainerRef}
        >
          {imageContent.image.imageTaglineOne && imageContent.image.imageTaglineTwo && (
            <div className={styles.taglinesWrapper}>
              <Tagline className={styles.imageTagline}>{imageContent.image.imageTaglineOne}</Tagline>
              <Tagline className={styles.imageTagline}>{imageContent.image.imageTaglineTwo}</Tagline>
            </div>
          )}
          <AsxImage
            className={styles.image}
            alt={imageContent.image.alt}
            src={imageContent.image.src}
            data-testid="ModalImageContentImage"
            componentName="ModalImageContent"
          />
        </div>
        <ModalBodyScroll
          className={classNames(styles.copyWrapper, imageContent.isSmallVariant && styles.isSmallVariant)}
          footerSize={footerHeight}
          containerHeight={dialogHeight}
          headerHeight={imageContainerRef.current?.getBoundingClientRect().height || 0}
          isVisible={isVisible}
        >
          <Tagline className={styles.tagline}>{imageContent.tagline}</Tagline>
          <h4 className={styles.modalImageHeading}>{imageContent.title}</h4>
          <AsxMarkdownContent className={styles.copy} copy={imageContent.copy} />
        </ModalBodyScroll>
      </div>
    </ModalWrapper>
  );
};

import classNames from 'classnames';
import { useInView } from 'motion/react';
import { RefObject, useCallback, useEffect, useRef, type FunctionComponent } from 'react';

import { AsxMarkdownContent, FormattedLink, Heading, HEADING_SIZE, Tagline } from 'src/components/atoms';
import { DynamicListItem, Tooltip } from 'src/components/common';
import { HEADING_TAG } from 'src/data/enums/Heading';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';

import styles from './DynamicItem.module.scss';

type DynamicListProps = DynamicListItem & {
  /**
   * Indicates if the item is the first in the list.
   */
  isFirst: boolean;

  /**
   * Indicates if the item is the last in the list.
   */
  isLast: boolean;

  /**
   * Determines if the item is currently active (e.g., highlighted or in focus).
   */
  isActive: boolean;

  /**
   * Callback triggered when the active state of the item changes.
   */
  onActiveChange: () => void;

  /**
   * Optional flag to determine if a modal is currently visible, affecting styles and behavior.
   */
  isModalVisible?: boolean;

  /**
   * The index of the item within the list.
   */
  index?: number;
  /**
   * Boolean for knowing if it's svg or media variant.
   */
  hasSvgProps?: boolean;

  fixedTitleRef?: RefObject<HTMLDivElement>;
};

// serves to avoid animations to be too abrupt and to prevent race conditions with useEffec/useCallback states as well
const SCROLL_INTO_VIEW_DELAY = 250;

export const DynamicItem: FunctionComponent<DynamicListProps> = ({
  body,
  title,
  link,
  tagline,
  isActive,
  isFirst,
  isLast,
  index,
  tooltipTheme = SECTION_THEME.Light,
  tooltipAttributes,
  hasSvgProps,
  fixedTitleRef,
  onActiveChange,
}) => {
  const { isDesktop } = useDeviceTracker();
  const self = useRef<HTMLLIElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const wasTabPressedRef = useRef(false);

  const selfInView = useInView(self);

  const ITEM_TRIGGER_OFFSET_PX = 20;

  // istanbul ignore next
  const handleScroll = useCallback(
    (e: Event) => {
      if (!self.current) return;
      // If we're scrolling into view due to a focus event we want to allow the tooltip
      // to be rendered on scroll, otherwise we want to prevent it

      if (!isDesktop || hasSvgProps) {
        const distanceTopPx = self.current.getBoundingClientRect().top;
        const distanceBottomPx = window.innerHeight - self.current.getBoundingClientRect().bottom;

        const triggerOffset = isDesktop
          ? window.innerHeight / 2 - self.current.offsetHeight
          : window.innerHeight / 4 - self.current.offsetHeight;

        // scaleFactor added for fine-tuning active index across resolutions

        const shouldBeActive =
          (isLast || distanceTopPx > triggerOffset) && (isFirst || distanceBottomPx > triggerOffset);

        if (shouldBeActive) {
          onActiveChange();
        }
      } else {
        if (!fixedTitleRef?.current) return;
        if (!selfInView) return;

        const fixedTitleRect = fixedTitleRef.current.getBoundingClientRect();

        const parentTopDistancePx = fixedTitleRect.top + fixedTitleRect.height;

        const currentTopDistancePx =
          self.current.getBoundingClientRect().top +
          self.current.getBoundingClientRect().height -
          ITEM_TRIGGER_OFFSET_PX;

        const itemIsBelowFixedTitle = currentTopDistancePx > parentTopDistancePx;

        if (itemIsBelowFixedTitle) {
          onActiveChange();

          // scroll events will trigger on index order, once we find the first element that should be active we stop
          // the propagation of the event, so this item remains as active
          e.stopImmediatePropagation();
        }
      }
    },
    [isFirst, isLast, onActiveChange, isDesktop, hasSvgProps, fixedTitleRef, selfInView]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // istanbul ignore next
  useEffect(() => {
    const externalLink = self.current?.querySelector<HTMLAnchorElement | HTMLButtonElement>('a, .button-link');

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        wasTabPressedRef.current = true;
      }
    };

    const handleFocus = (event: Event): void => {
      const focusEvent = event as FocusEvent;

      if (wasTabPressedRef.current) {
        setTimeout(() => {
          const target = focusEvent.target as HTMLElement;

          if (hasSvgProps) {
            target.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else if (isDesktop) {
            itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }, SCROLL_INTO_VIEW_DELAY);
      }
      wasTabPressedRef.current = false; // Reset after focus
    };

    document.addEventListener('keydown', handleKeyDown);
    externalLink?.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      externalLink?.removeEventListener('focus', handleFocus);
    };
  }, [hasSvgProps, isDesktop]);

  return (
    <li
      ref={self}
      className={classNames(styles.dynamicItem, !isActive && styles.disabled, !hasSvgProps && styles.imageVariant)}
      data-testid="dynamicItem"
    >
      {hasSvgProps ? (
        <>
          {tagline && <Tagline className={styles.tagline}>{tagline}</Tagline>}
          <Heading className={styles.title} as={HEADING_TAG.H3} size={HEADING_SIZE.h3}>
            {title}
          </Heading>
          <p className={styles.copy}>{body}</p>

          {link && (
            <FormattedLink
              className={styles.externalLink}
              iconName={link.icon}
              text={link.text}
              {...(link.onClickOverride && { onClick: link.onClickOverride })}
              {...(link.href && { link: link.href })}
              bold
              data-testid="dynamicItemLink"
              data-cta-index={index}
              openNewTab={Boolean(link.href)}
              aria-label={link.href ? undefined : 'Opens a modal'}
              renderAsButton={!link.href}
            />
          )}
        </>
      ) : (
        <div className={styles.imageVariantItem} ref={itemRef}>
          <div className={styles.imageVariantLine} />

          <div className={styles.imageVariantBodyWrapper}>
            <p className={styles.imageVariantTitle}>{title}</p>
            {tooltipAttributes && tooltipTheme ? (
              <span className={styles.imageVariantBody}>
                <Tooltip copy={body} tooltipAttributes={tooltipAttributes} tooltipTheme={tooltipTheme} />
              </span>
            ) : (
              <div className={styles.markdownText}>
                <AsxMarkdownContent copy={body} className={styles.imageVariantMarkdown} />
              </div>
            )}
          </div>
        </div>
      )}
    </li>
  );
};

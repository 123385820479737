import classNames, { Argument } from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';

import { KatCard } from '@amzn/katal-react';

import { Tagline } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './BentoCard.module.scss';

export enum BENTO_CARD_VARIANT {
  STANDARD = 'standard',
  GRAPHIC = 'graphic',
}

/**
 * Receives children which will be displayed as the content of the card
 */
interface BentoCardProps {
  /**
   * Optional - ClassName to apply to the component
   */
  className?: Argument;

  /**
   * Optional - Theme to be applied
   */
  theme?: SECTION_THEME;

  /**
   * Small text that will be displayed in the tagline of the card
   */
  label: string;
}

export const BentoCard: FunctionComponent<PropsWithChildren<BentoCardProps>> = ({
  className,
  theme = SECTION_THEME.Light,
  children,
  label,
}) => (
  <KatCard className={classNames(className, styles.card, styles[theme])}>
    <div className={styles.cardContent}>
      <div className={styles.cardHeader}>
        <Tagline className={styles.tagline}>{label}</Tagline>
      </div>
      {children}
    </div>
  </KatCard>
);

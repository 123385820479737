import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';

import { Icon } from 'src/components/atoms';
import { TooltipModalWrapper } from 'src/components/common/tooltip-modal/TooltipModalWrapper';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { convertToKebabCase } from 'src/helpers';
import { TooltipAttributes } from 'src/interfaces/CommonAttributes';

import styles from './Tooltip.module.scss';

interface TooltipProps {
  copy: string;
  canBeOpened?: boolean;
  tooltipAttributes?: TooltipAttributes[];
  tooltipTheme?: SECTION_THEME;
}

interface HighlightedSectionProps {
  tooltipData?: TooltipAttributes;
  copy: string;
  onTooltipOpen(): void;
  onTooltipClose(): void;
  tooltipTheme: SECTION_THEME;
  tooltipIsOpened: boolean;
}

const HighlightedSection: FunctionComponent<HighlightedSectionProps> = ({
  copy,
  tooltipData,
  tooltipTheme,
  tooltipIsOpened,
  onTooltipOpen,
  onTooltipClose,
}) => (
  <>
    <button
      key={convertToKebabCase(copy)}
      type="button"
      className={classNames(styles.highlight, styles.dark)}
      onClick={onTooltipOpen}
      data-testid="tooltip-highlight-cta"
    >
      {copy}{' '}
      <Icon
        key={convertToKebabCase(copy)}
        className={classNames(styles.icon, styles[tooltipTheme], tooltipIsOpened && styles.isOpen)}
        name="info"
      />
    </button>
    {tooltipData && (
      <TooltipModalWrapper
        key={convertToKebabCase(tooltipData.description)}
        theme={tooltipTheme}
        isVisible={tooltipIsOpened}
        image={tooltipData.image}
        cta={tooltipData.cta}
        description={tooltipData.description}
        close={onTooltipClose}
      />
    )}
  </>
);

export const Tooltip: FunctionComponent<TooltipProps> = ({ copy, canBeOpened, tooltipAttributes, tooltipTheme }) => {
  const [currentOpenedTooltip, setCurrentOpenedTooltip] = useState<number | null>(null);

  const handleTooltipVisibility = (tooltipIndex: number | null) => setCurrentOpenedTooltip(tooltipIndex);
  const splittedText = copy.split('*');

  useEffect(() => {
    if (canBeOpened) return;
    handleTooltipVisibility(null);
  }, [canBeOpened]);

  return tooltipAttributes ? (
    <>
      {
        // After splitting the text we iterate over each section and render it
        // differently if we deduce it should be highlighted
        splittedText.map((part, index) => {
          if (index % 2 === 0) {
            return part;
          }

          // Since we're iterating over regular and highligted texts we get the index
          // counting only the highilghted sections, to know what's the index
          // of the section that is going to be clicked
          const highlightIndex = Math.floor(index / 2);

          return (
            <HighlightedSection
              key={convertToKebabCase(part)}
              copy={part}
              tooltipData={tooltipAttributes[highlightIndex]}
              tooltipTheme={tooltipTheme ?? SECTION_THEME.Light}
              tooltipIsOpened={highlightIndex === currentOpenedTooltip}
              onTooltipOpen={() => handleTooltipVisibility(highlightIndex)}
              onTooltipClose={() => handleTooltipVisibility(null)}
            />
          );
        })
      }
    </>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{copy}</>
  );
};

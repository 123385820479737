import classNames, { Argument } from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';

import { KatCard } from '@amzn/katal-react';

import { AsxImage, PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './BentoImage.module.scss';

/**
 * Receives children which will be displayed as the content of the card
 */
interface BentoImageProps {
  /**
   * Optional - ClassName to apply to the kat-card component
   */
  className?: Argument;

  /**
   * Optional - ClassName to apply to the container within the kat-card component
   */
  contentClassName?: Argument;

  /**
   * Optional - Theme to be applied
   */
  theme?: SECTION_THEME;

  /**
   * Thumbnail of the card
   */
  image: ImageAttributes;

  /**
   * Optional callback to be called when the card is clicked
   */
  onClick?(): void;
}

export const BentoImage: FunctionComponent<PropsWithChildren<BentoImageProps>> = ({
  className,
  contentClassName,
  theme = SECTION_THEME.Light,
  children,
  image,
  onClick,
}) => (
  <KatCard className={classNames(className, styles.card, styles[theme])} onClick={onClick}>
    <div className={classNames(styles.cardContent, contentClassName)}>
      <div className={styles.cardHeader}>
        <AsxImage className={styles.image} componentName="BentoImage" {...image} />
        <PrimaryButton
          className={styles.plusCta}
          icon="plus"
          theme={SECTION_THEME.Dark}
          buttonSize={PRIMARY_BUTTON_SIZE.Small}
        />
      </div>
      {children}
    </div>
  </KatCard>
);

import { ReactElement, forwardRef } from 'react';

import { Heading, HEADING_SIZE } from 'src/components/atoms/heading/Heading';
import styles from 'src/components/common/dynamic-list/DynamicList.module.scss';
import { DynamicListSVG } from 'src/components/common/dynamic-list/DynamicListSVG';
import { HEADING_TAG } from 'src/data/enums/Heading';

type DesktopHeaderProps = {
  heading: string;
  activeSlide: number;
};

// This dummy component exists to improve code readability
// it is tested on DynamicList.test.tsx suite
export const DesktopHeader = forwardRef<HTMLDivElement, DesktopHeaderProps>(
  ({ heading, activeSlide }, ref): ReactElement => (
    <div ref={ref} className={styles.imageContainer}>
      {heading && (
        <Heading as={HEADING_TAG.H2} size={HEADING_SIZE.h2}>
          {heading}
        </Heading>
      )}
      {DynamicListSVG && <DynamicListSVG role="img" activeSlide={activeSlide} />}
    </div>
  )
);

DesktopHeader.displayName = 'DesktopHeader';

// eslint-disable-next-line no-restricted-imports
import { getLibraryDomainRoot } from 'src/helpers/library-helpers';

// Converts a string to sentence case (i.e where just the first letter of the string is capitalized
export const convertToSentenceCase = (str: string) =>
  str.toLowerCase().replace(/^\w/, (char) => char.toLocaleUpperCase());

// Converts a string to kebab case (i.e all letters are lower case and words separated by hyphens "-")
export const convertToKebabCase = (str?: string) => (str ? str.toLowerCase().replaceAll(' ', '-') : '');

export const formatUrl = (url?: string) => {
  if (!url) return '';
  // if the string is already a valid url, simply return
  return url.startsWith('http') || url.startsWith('www') ? url : `${getLibraryDomainRoot()}${url}`;
};

export const splitLastWordFromText = (copy: string) => {
  const initialCopyArr = copy.split(' ');
  const lastWord = initialCopyArr.pop();
  const initialCopy = initialCopyArr.join(' ');

  return [initialCopy, lastWord];
};

export const addRequiredLabelSymbol = (inputLabel: string) => `${inputLabel} *`;

export const titleFormatter: (value: string) => string = (value) => value.replace(/_/g, ' ');

export const validateEmptyTextInput = (inputValue: string, outputString: string) => !!inputValue.trim() || outputString;

export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0');
  const secs = (seconds % 60).toString().padStart(2, '0');
  return `${minutes}:${secs}`;
};

// This helper function will scan a string and split anything that's found inside two "*" characters into a separate fragment,
// (i.e "split this *title*", would leave us with a "split this" fragment and a "title" fragment), we use this when we have a
// string that needs to have a certain part styled differently, like changing the color or alignment of the last words of a title
export const parseTextFragments = (title: string) => {
  const match = title.match(/(.*?)\*(.*?)\*/);
  if (match) {
    return { fragment1: match[1], fragment2: match[2] };
  }
  return { fragment1: title, fragment2: '' }; // Fallback if no match is found
};

// Helper function to convert our captions time format (HH:MM:SS:FF) to seconds
function captionsTimeToSeconds(time: string) {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

// Helper function to parse the captions string into transcription format
export const parseCaptionsToTranscription = (captions: string) => {
  const lines = captions.trim().split('\n'); // Split the string by line breaks
  const transcription: { time: number; content: string }[] = [];

  let currentContent = ''; // To store multi-line caption content
  let currentTime = ''; // To store the timestamp for the current caption

  for (const line of lines) {
    // Check if the line contains a timestamp
    if (line.includes(' - ')) {
      // If there's a previous timestamp + content combo, push it to the transcription
      if (currentTime && currentContent) {
        transcription.push({
          time: captionsTimeToSeconds(currentTime),
          content: currentContent.trim(), // Remove trailing whitespace
        });
      }

      // Extract the startTime from the current timestamp line
      [currentTime] = line.split(' - ');
      currentContent = ''; // Reset content for the next caption
    } else {
      // Append the current line to the caption content
      currentContent += ` ${line}`; // Add a space between lines
    }
  }

  // Push the last timestamp + content combo after the loop finishes
  if (currentTime && currentContent) {
    transcription.push({
      time: captionsTimeToSeconds(currentTime),
      content: currentContent.trim(),
    });
  }

  return transcription;
};

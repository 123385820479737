import { FunctionComponent } from 'react';

import { AsxImage, Heading, HEADING_SIZE, Icon } from 'src/components/atoms';
import { BENTO_CARD_VARIANT, BentoCard } from 'src/components/common';
import { HEADING_TAG } from 'src/data/enums/Heading';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './CardsDashboard.module.scss';

/*
   Object - card content, displays a label, title and description and a graphic svg
  */
interface StandardCardProp {
  /*
    Tagline that will be displayed at the top left of the card
    */
  label: string;

  /*
    Title of the card
    */
  title: string;

  /*
    Body text of the card
    */
  description: string;
}

interface ImageCardProp extends StandardCardProp {
  image: ImageAttributes;
}

interface CardsDashboardProps {
  /*
   Object - first card content, is a simple image
  */
  firstCard: ImageAttributes;

  /*
   Object - second card content with the corresponding variant
   (only the second card can have different variants)
  */
  secondCard: (StandardCardProp | ImageCardProp) & {
    variant: BENTO_CARD_VARIANT;
  };

  /*
   Object - third card content
  */
  thirdCard: StandardCardProp;

  /*
   Object - fourth card content, is just a simple image
  */
  fourthCard: ImageAttributes;
}

export const CardsDashboard: FunctionComponent<CardsDashboardProps> = ({
  firstCard,
  secondCard,
  thirdCard,
  fourthCard,
}) => {
  const { isSmallDesktop } = useDeviceSize();
  return (
    <div className={styles.cardsDashboard}>
      <AsxImage className={styles.card} {...firstCard} componentName="CardsDashboard" />
      <BentoCard theme={SECTION_THEME.Dark} className={styles.card} label={secondCard.label}>
        <Heading className={styles.cardHeading} as={HEADING_TAG.H3} size={HEADING_SIZE.h3}>
          {secondCard.title}
        </Heading>
        {secondCard.variant === BENTO_CARD_VARIANT.GRAPHIC ? (
          <>
            <p className={styles.cardDescription}>{secondCard.description}</p>
            <Icon
              className={styles.cardGraphic}
              name={isSmallDesktop ? 'cardsDashboardGraphicDesktop1' : 'cardsDashboardGraphicMobile1'}
            />
          </>
        ) : (
          <>
            <p className={styles.cardImageDescription}>{secondCard.description}</p>
            <AsxImage
              className={styles.cardImage}
              {...(secondCard as ImageCardProp).image}
              componentName="CardsDashboard"
            />
          </>
        )}
      </BentoCard>
      <BentoCard className={styles.card} label={thirdCard.label}>
        <div className={styles.cardContent}>
          <Heading className={styles.cardHeading} as={HEADING_TAG.H3} size={HEADING_SIZE.h2}>
            {thirdCard.title}
          </Heading>

          <p className={styles.cardDescription}>{thirdCard.description}</p>
        </div>
      </BentoCard>
      <AsxImage className={styles.card} {...fourthCard} componentName="CardsDashboard" />
    </div>
  );
};

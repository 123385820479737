import { ReactElement } from 'react';

import { AsxMarkdownContent, FormattedLink, Heading, HEADING_SIZE, IconType } from 'src/components/atoms';
import { HEADING_TAG } from 'src/data/enums/Heading';

import styles from './FaqItem.module.scss';

type FaqItemProps = {
  index: number;
  question: string;
  answer: string;
  link?: {
    label: string;
    url: string;
    icon: IconType;
  };
};

export function FaqItem({ index, question, answer, link }: FaqItemProps): ReactElement {
  return (
    <div>
      <span className={styles.divider} />
      <div className={styles.faqItemContent}>
        <span className={styles.faqItemIndex}>{String(index + 1).padStart(2, '0')}</span>
        <Heading as={HEADING_TAG.H5} size={HEADING_SIZE.h5} className={styles.question}>
          {question}
        </Heading>
        <div>
          <AsxMarkdownContent className={styles.answer} copy={answer} />
          {link && (
            <div className={styles.answerLinkWrapper} data-testid="answer-link">
              <FormattedLink text={link.label} link={link.url} bold iconName={link.icon} openNewTab />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import classNames from 'classnames';
import Plyr from 'plyr-react';
import type { APITypes } from 'plyr-react';
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ComponentProps } from 'react';

import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { PrimaryButtonV2 } from 'src/components/atoms';
import { ModalBodyScroll } from 'src/components/common';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { formatTime } from 'src/helpers/formatting-helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import plyrStyleVariables from './PlyrOverrides.module.scss';
import { mobileControls, desktopControls } from './VideoButtons';
import styles from './VideoPlayer.module.scss';

export interface TranscriptionFragments {
  /**
   * Number expressed as SECONDS corresponding to which part of the video is the transcription fragment corresponding to,
   * for example, a time of 52 would indicate that this transcription fragment starts at second 00:52
   */
  time: number;
  /**
   * String corresponding to the text of that transcription fragment
   */
  content: string;
}

interface VideoPlayerProps {
  /**
   * Function passed down from the VideoWithPreviews component that is going to be executed whenever we exit fullscreen
   */
  onFullscreenExit?: () => void;
  /**
   * Ref corresponding to the parent element of this VideoPlayer component
   */
  containerRef: React.RefObject<HTMLDivElement>;
  /**
   * Boolean corresponding to the current status of the video (played or paused)
   */
  isVideoPlaying: boolean;
  /**
   * Array of transcriptionFragments used for the transcription of the video
   */
  transcription?: Array<TranscriptionFragments>;
  /**
   * String corresponding to the source of the video being played
   */
  videoSrc: string;
  /**
   * String corresponding to the source of the captions for the video
   */
  captionsSrc?: string;
}

/**
 * This component is the one responsible for the playing of the video itself, including controls, captions and transcription
 */
export const VideoPlayer = forwardRef<APITypes, VideoPlayerProps>(
  ({ onFullscreenExit, containerRef, isVideoPlaying, transcription, videoSrc, captionsSrc }, ref) => {
    const [showTranscript, setShowTranscript] = useState(false);
    const inactivityTimer = useRef<NodeJS.Timeout | null>(null);
    const { isSmallDesktop } = useDeviceSize();
    const transcriptionContainerRef = useRef<HTMLDivElement>(null);

    const [isScrollEnd, setIsScrollEnd] = useState(false);

    useEffect(() => {
      const transcriptButton = containerRef.current?.querySelector('[data-plyr="transcript"]');
      const plyrElement = containerRef.current?.querySelector('.plyr');

      if (!transcriptButton || !plyrElement) return;

      // Hide controls after 8 seconds of inactivity, if there's any kind of user interaction we reset that timer
      const resetInactivityTimer = () => {
        if (inactivityTimer.current) {
          clearTimeout(inactivityTimer.current);
        }

        plyrElement.classList.remove('plyr--hide-controls');

        inactivityTimer.current = setTimeout(() => {
          plyrElement.classList.add('plyr--hide-controls');
        }, 8000);
      };

      const handleActivity = () => {
        resetInactivityTimer();
      };

      plyrElement.addEventListener('mousemove', handleActivity);
      plyrElement.addEventListener('click', handleActivity);

      resetInactivityTimer();

      // Toggle transcript inside the player
      const handleTranscriptClick = () => {
        setShowTranscript((prev) => {
          const isEnablingTranscript = !prev;

          if (isEnablingTranscript) {
            plyrElement.classList.add('plyr--transcription--enabled');
            transcriptButton.classList.add('plyr__control--pressed');
          } else {
            plyrElement.classList.remove('plyr--transcription--enabled');
            transcriptButton.classList.remove('plyr__control--pressed');
          }

          return isEnablingTranscript;
        });
      };

      // Handle fullscreen change/exit
      const handleFullscreenChange = () => {
        if (containerRef.current && !document.fullscreenElement && onFullscreenExit) {
          onFullscreenExit();
          plyrElement.classList.remove('plyr--transcription--enabled');
          transcriptButton.classList.remove('plyr__control--pressed');
          setShowTranscript(false);
        }
      };

      document.addEventListener('fullscreenchange', handleFullscreenChange);
      transcriptButton.addEventListener('click', handleTranscriptClick);

      // Cleanup listeners
      // eslint-disable-next-line consistent-return
      return () => {
        if (inactivityTimer.current) {
          clearTimeout(inactivityTimer.current);
        }
        plyrElement.removeEventListener('mousemove', handleActivity);
        plyrElement.removeEventListener('click', handleActivity);
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
        transcriptButton.removeEventListener('click', handleTranscriptClick);
      };
    }, [containerRef, onFullscreenExit]);

    const playerProps: ComponentProps<typeof Plyr> = useMemo(
      () => ({
        source: {
          type: 'video',
          sources: [{ src: videoSrc }],
          // TODO: test captions and style them
          // currently running into ¿CORS? issues where I can't load the file from the library nor the cloudfront URL
          ...(captionsSrc && {
            tracks: [
              {
                kind: 'captions',
                label: '',
                src: URL.createObjectURL(new Blob([captionsSrc], { type: 'text/vtt' })),
                srcLang: 'en',
                default: true,
              },
            ],
          }),
        },
        options: {
          clickToPlay: false,
          controls: isSmallDesktop ? desktopControls : mobileControls,
          hideControls: false,
        },
      }),
      // isSmallDesktop dependency shouldn't be added here because otherwise on resize the component re renders which causes the player
      // to close
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [videoSrc, captionsSrc]
    );

    // Exit full screen when clicking the close button
    const handleCloseButton = () => {
      if (containerRef.current) {
        document.exitFullscreen();
      }
    };

    const handleResize = useCallback(() => {
      if (!transcriptionContainerRef.current || !containerRef.current) return;
      const videoPlayer = containerRef.current.querySelector('.plyr');

      if (!videoPlayer) return;

      // useDeviceSize computes asynchronously, causing issues sometimes with the resize event
      // so we compute it by hand only on this scenario
      const isCurrentlySmallDesktop =
        Math.min(window.innerWidth, window.screen.width) > parseInt(variables.smallDesktop, 10);

      if (isCurrentlySmallDesktop) {
        transcriptionContainerRef.current.style.removeProperty('margin-top');
      } else {
        transcriptionContainerRef.current.style.marginTop = `calc(${videoPlayer.getBoundingClientRect().height}px + ${plyrStyleVariables.videoPlayerPadding})`;
      }

      setShowTranscript(videoPlayer.classList.contains('plyr--transcription--enabled'));
    }, [containerRef]);

    useEffect(() => {
      if (showTranscript) {
        handleResize();
        window.addEventListener('resize', handleResize);
      } else {
        window.removeEventListener('resize', handleResize);
        transcriptionContainerRef.current?.style.removeProperty('margin-top');
      }
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [showTranscript, handleResize]);

    return (
      <div
        className={classNames(
          styles.playerContainer,
          isVideoPlaying ? styles.active : styles.hidden,
          showTranscript && styles.transcription,
          !captionsSrc && styles.noCaptions,
          !transcription && styles.noTranscription
        )}
        ref={containerRef}
        data-testid="player-container"
      >
        <div className={classNames(styles.playerWrapper, showTranscript && styles.transcription)}>
          <Plyr ref={ref} {...playerProps} />
          {isVideoPlaying && (
            <div className={classNames(styles.closeButtonWrapper, showTranscript && styles.transcription)}>
              <PrimaryButtonV2
                icon="close"
                onClick={handleCloseButton}
                className={classNames(styles.closeButton, showTranscript && styles.transcription)}
                hasHoverAnimation={false}
                testId="close-video-button"
                theme={isSmallDesktop ? SECTION_THEME.Teal : SECTION_THEME.Gray}
              />
            </div>
          )}
          {showTranscript && (
            <div className={styles.transcriptionWrapper}>
              <div className={styles.transcriptionContainer}>
                <div ref={transcriptionContainerRef} className={styles.transcriptionHeader}>
                  <p className={styles.transcriptionTitle}>Transcript</p>
                </div>
                <ModalBodyScroll
                  className={styles.transcriptionFragmentsContainer}
                  onScrollEnd={
                    // istanbul ignore next
                    () => {
                      setIsScrollEnd(true);
                    }
                  }
                  onScrollAway={
                    // istanbul ignore next
                    () => {
                      setIsScrollEnd(false);
                    }
                  }
                >
                  {transcription &&
                    transcription.map((fragment) => (
                      <div
                        className={styles.transcriptionFragment}
                        key={fragment.time}
                        data-testid="transcription-fragment"
                      >
                        <p className={styles.transcriptionText}>{fragment.content}</p>
                        <p className={styles.transcriptionTime}>{formatTime(fragment.time)}</p>
                      </div>
                    ))}
                </ModalBodyScroll>
                <div className={classNames(styles.gradient, { [styles.scrollEnd]: isScrollEnd })} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

VideoPlayer.displayName = 'VideoPlayer';

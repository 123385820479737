import { motion } from 'motion/react';
import { forwardRef, FunctionComponent, Ref } from 'react';

import {
  AsxImage,
  AsxMarkdownContent,
  FormattedLink,
  IconType,
  PRIMARY_BUTTON_V2_VARIANT,
  PrimaryButtonV2,
} from 'src/components/atoms';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './TextAndCard.module.scss';
import { useTextAndCard } from './TextAndCardAnimations';

export interface TextAndCardProps {
  /* Text component props */
  title: string;

  subTitle: string;
  link?: { label: string; href: string; icon: IconType };
  /* Card props */
  card: { title: string; copy: string; list: Array<string>; ctas: { primaryLabel: string; secondaryLabel: string } };
  /* Small decorative image */
  smallImage: ImageAttributes;
  /* Big decorative image */
  bigImage: ImageAttributes;
  dataTrackLabel?: string;
}

const TitleTag: FunctionComponent<{ title: string }> = ({ title }) => {
  const words = title.split(' ');
  const lastTwoWords = words.slice(-2).join(' ');
  const remainingWords = words.slice(0, -2).join(' ');

  return (
    <h2 className={styles.title}>
      {remainingWords && `${remainingWords} `}
      <span className={styles.highlitedTitle}>{lastTwoWords}</span>
    </h2>
  );
};

export const TextAndCard = forwardRef<HTMLDivElement, TextAndCardProps>(
  ({ title, subTitle, link, card, smallImage, bigImage, dataTrackLabel }, forwardedRef: Ref<HTMLDivElement>) => {
    const { refs, parallaxStyles } = useTextAndCard();
    const { self, smallImageRef, bigImageRef, bigImageMobileRef, copyRef, linkRef, cardRef, titleRef } = refs;
    const { isSmallDesktop } = useDeviceSize();

    return (
      <motion.div
        data-track-label={dataTrackLabel}
        className={styles.componentWrapper}
        ref={self}
        style={parallaxStyles.selfOpacity}
      >
        <div className={styles.textAndCard} ref={forwardedRef}>
          <div className={styles.titleAndImageSection}>
            <div className={styles.titleWrapper}>
              <motion.div ref={titleRef} style={parallaxStyles.titleY}>
                <TitleTag title={title} />
              </motion.div>
              <motion.p className={styles.copy} ref={copyRef} style={parallaxStyles.copyY}>
                {subTitle}
              </motion.p>
              <motion.div ref={linkRef} style={parallaxStyles.linkY}>
                {link && (
                  <FormattedLink
                    className={styles.link}
                    text={link.label}
                    iconName={link.icon}
                    link={link.href}
                    openNewTab
                  />
                )}
              </motion.div>
            </div>
            {isSmallDesktop && (
              <div className={styles.imagesWrapper}>
                <motion.div
                  className={styles.smallImageWrapper}
                  style={isSmallDesktop && parallaxStyles.smallImage}
                  ref={smallImageRef}
                >
                  <AsxImage
                    className={styles.smallImage}
                    alt={smallImage.alt}
                    src={smallImage.src}
                    data-testid="smallImage"
                    componentName="TextAndCard"
                  />
                </motion.div>
                <motion.div className={styles.bigImageWrapper} ref={bigImageRef} style={parallaxStyles.bigImage}>
                  <AsxImage
                    className={styles.bigImage}
                    alt={bigImage.alt}
                    src={bigImage.src}
                    data-testid="bigImage"
                    componentName="TextAndCard"
                  />
                </motion.div>
              </div>
            )}
          </div>
          <motion.div
            className={styles.cardWrapper}
            ref={cardRef}
            style={isSmallDesktop ? parallaxStyles.cardY : parallaxStyles.cardYMobile}
          >
            <div className={styles.cardTextWrapper}>
              <h4 className={styles.cardTitle}>{card.title}</h4>
              <div className={styles.cardCopyWrapper}>
                <AsxMarkdownContent className={styles.cardCopy} copy={card.copy} theme={SECTION_THEME.Dark} />
                <div className={styles.cardListItems}>
                  {card.list.map((itemCopy, index) => (
                    <span
                      className={styles.cardListItem}
                      key={`${itemCopy.toLowerCase().replaceAll(' ', '-')}-${index}`}
                    >
                      <div className={styles.cardListItemNumberWrapper}>
                        <p className={styles.cardListItemNumber}>{`0${index + 1}`}</p>
                      </div>
                      <p className={styles.cardListItemCopy}>{itemCopy}</p>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.ctasWrapper}>
              <PrimaryButtonV2
                label={card.ctas.primaryLabel}
                data-testid="primaryCTA"
                variant={PRIMARY_BUTTON_V2_VARIANT.SecondaryNoIcon}
                link={ROUTES.DEBORAH_FAQ}
              />
              <PrimaryButtonV2
                label={card.ctas.secondaryLabel}
                link={ROUTES.DEBORAH_INTEREST}
                theme={SECTION_THEME.Dark}
                icon="chevronRight"
                data-testid="secondaryCTA"
              />
            </div>
          </motion.div>
          {!isSmallDesktop && (
            <motion.div
              className={styles.bigImageMobileWrapper}
              ref={bigImageMobileRef}
              style={parallaxStyles.bigImageMobile}
            >
              <AsxImage
                className={styles.bigImage}
                alt={bigImage.alt}
                src={bigImage.src}
                data-testid="bigImage"
                componentName="TextAndCard"
              />
            </motion.div>
          )}
        </div>
      </motion.div>
    );
  }
);

TextAndCard.displayName = 'TextAndCard';

import { useEffect, useState } from 'react';

type UseDeviceTracker = {
  isXSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isXLarge: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  width: number;
  height: number;
};

export enum DEVICE_BREAKPOINTS {
  SMALL = 480,
  MEDIUM = 768,
  LARGE = 1024,
  XLARGE = 1440,
}

export const useDeviceTracker = (): UseDeviceTracker => {
  const [width, setWidth] = useState<number>(Math.min(window.innerWidth, window.screen.width));
  const [height, setHeight] = useState<number>(Math.min(window.innerHeight, window.screen.height));

  const handleWindowSizeChange = () => {
    setWidth(Math.min(window.innerWidth, window.screen.width));
    setHeight(Math.min(window.innerHeight, window.screen.height));
  };
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isXSmall = width < DEVICE_BREAKPOINTS.SMALL;
  const isSmall = width >= DEVICE_BREAKPOINTS.SMALL;
  const isMedium = width >= DEVICE_BREAKPOINTS.MEDIUM;
  const isLarge = width >= DEVICE_BREAKPOINTS.LARGE;
  const isXLarge = width >= DEVICE_BREAKPOINTS.XLARGE;

  const isMobile = (isXSmall || isSmall) && !isMedium;
  const isTablet = isMedium && !isLarge;
  const isDesktop = isLarge || isXLarge;

  return { isXSmall, isSmall, isMedium, isLarge, isXLarge, isMobile, isTablet, isDesktop, width, height };
};

import { useCallback, useState, type ReactElement } from 'react';

import { AccordionComponentCard, ACCORDION_VARIANT, Accordion, AccordionProps, IconType } from 'src/components/atoms';
import { HEADING_TAG } from 'src/data/enums/Heading';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './AccordionsComponentContent.module.scss';

export type AccordionsComponentContentProps = {
  theme?: SECTION_THEME;
  cardTitle: string;
  cardSubtitle: string;
  cardImage: ImageAttributes;
  icon: IconType;
  accordions: Array<AccordionProps>;
  showCardHeader?: boolean;
  titleSize?: HEADING_TAG;
};

export function AccordionsComponentContent({
  theme = SECTION_THEME.Light,
  cardTitle,
  cardSubtitle,
  cardImage,
  accordions,
  showCardHeader = true,
  titleSize = HEADING_TAG.H4,
}: AccordionsComponentContentProps): ReactElement {
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number>(0);

  const handleAccordionClick = useCallback((index: number) => {
    setOpenAccordionIndex((previousIndex) => (previousIndex === index ? -1 : index));
  }, []);

  const onAccordionClick = useCallback(
    (index: number) => () => {
      handleAccordionClick(index);
    },
    [handleAccordionClick]
  );

  return (
    <div className={styles.AccordionsComponentContent}>
      {showCardHeader && (
        <AccordionComponentCard
          theme={theme}
          title={cardTitle}
          subtitle={cardSubtitle}
          image={{
            src: cardImage.src,
            alt: cardImage.alt,
          }}
        />
      )}
      <div className={styles.accordions}>
        {accordions.map((accordion, index) => {
          const key = `${accordion.title}-${index}`;
          return (
            <Accordion
              titleSize={titleSize}
              variant={ACCORDION_VARIANT.AccordionComponent}
              key={key}
              theme={theme}
              isOpen={index === openAccordionIndex}
              onClick={onAccordionClick(index)}
              {...accordion}
            />
          );
        })}
      </div>
    </div>
  );
}

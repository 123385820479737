import classNames from 'classnames';
import { useInView } from 'motion/react';
import { useEffect, useRef, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { BrandTabs, BrandTabsProps, Heading, HEADING_SIZE } from 'src/components/atoms';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { HEADING_TAG } from 'src/data/enums/Heading';
import { t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './AdvocatesComponent.module.scss';

type AdvocatesComponentProps = {
  backgroundImage: ImageAttributes;
  eyebrow?: string;
  brandTabs: BrandTabsProps;
  quotes: Array<string>;
  isStandAlone?: boolean;
  dataTrackingLabel?: string;
};

export function AdvocatesComponent({
  backgroundImage,
  eyebrow,
  brandTabs,
  quotes,
  isStandAlone,
  dataTrackingLabel,
}: AdvocatesComponentProps) {
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);
  const { isSmallDesktop } = useDeviceSize();
  // This is set to -1 initially on desktop so we can set it to 0 once the component is in view
  const [activeTab, setActiveTab] = useState(isSmallDesktop ? -1 : 0);
  const [displayedTab, setDisplayedTab] = useState(0);
  const [isQuoteWrapperFading, setIsQuoteWrapperFading] = useState(false);
  const self = useRef<HTMLDivElement>(null);

  const selfInView = useInView(self);

  const quoteAnimationDelay = isSmallDesktop ? 250 : 100;

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (selfInView && activeTab === -1) {
      handleTabClick(0);
    }
  }, [selfInView, activeTab]);

  // This makes it so our quote fades out, is updated and then fades in
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // We are already cleaning up our timeout in the context of our if block, we can't clean it outside of it
    if (activeTab !== displayedTab) {
      setIsQuoteWrapperFading(true);

      const timeoutId = setTimeout(() => {
        setDisplayedTab(activeTab);

        setIsQuoteWrapperFading(false);
      }, quoteAnimationDelay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [activeTab, displayedTab, isSmallDesktop]);

  return (
    <div data-track-label={dataTrackingLabel} ref={self} className={styles.quoteContainer}>
      <div className={styles.backgroundImageContainer}>
        <img
          className={styles.backgroundImage}
          src={backgroundImage.src}
          data-testid="animated-hero-banner-background-image"
          alt={backgroundImage.alt}
        />
      </div>
      <p className={styles.eyebrow}>{eyebrow}</p>
      <BrandTabs {...brandTabs} onTabClick={handleTabClick} shouldCardsExpand={activeTab > -1} />
      <div
        className={classNames(
          styles.quoteWrapper,
          isQuoteWrapperFading && styles.fadeOut,
          !isQuoteWrapperFading && styles.fadeIn
        )}
      >
        <Heading size={HEADING_SIZE.h4} as={HEADING_TAG.H4} key={displayedTab} className={styles.quote}>
          {quotes[displayedTab]}
        </Heading>
      </div>
      <p className={classNames(styles.scrollDown, isStandAlone && styles.standAlone)}>
        {t(commonBundle, 'primaryHeroScroll')}
      </p>
    </div>
  );
}

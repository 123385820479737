import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';

import { PDMetricData } from 'src/hooks/useComponentTracking';
import { logger } from 'src/logger';
import {
  HMD_FEEDBACK_SUBJECT_OTHER,
  HMD_METHOD_DIMENSION,
  RATING_COMMENT_PART_DIMENSION,
  RATING_SUBJECT_DIMENSION,
  RATING_URL_DIMENSION,
  RATING_USER_AGENT_DIMENSION,
  RATING_VALUE_DIMENSION,
} from 'src/logging-helpers/metrics-constants';
import { initialMetricsPublisher } from 'src/metrics';

export interface HmdFormData {
  rating: string;
  comment?: string;
  subject?: string;
}

export const CHUNK_COMMENT_CHAR_LENGTH = 256;

/**
 * This function helps integrate an HMD form submission publish metrics via Katal. https://code.amazon.com/packages/KatalMetricsHmdBackend/blobs/mainline/--/src/connectPublisher.ts
 * @param hmdData - The data from the HMD form
 * @param {(metricsPublisher: KatalMetricsPublisher) => void} [addMetrics] - An optional function for publishing custom metrics (outside of standard HMD schema).
 * @param {string} [ratingURL] - An optional string that specifies the URL of the HMD submission
 */
export function hmdConnectPublisher(
  hmdData: HmdFormData,
  addMetrics?: (metricsPublisher: KatalMetricsPublisher) => void,
  ratingURL?: string
): void {
  const actionMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod(HMD_METHOD_DIMENSION);

  actionMetricsPublisher.publishCounterMonitor(RATING_VALUE_DIMENSION, parseInt(hmdData.rating, 10));

  if (hmdData.comment) {
    const numberOfChunks = Math.floor(hmdData.comment.length / CHUNK_COMMENT_CHAR_LENGTH);
    for (let i = 0; i <= numberOfChunks; i += 1) {
      actionMetricsPublisher.publishString(
        `${RATING_COMMENT_PART_DIMENSION}${i + 1}`,
        hmdData.comment.substr(CHUNK_COMMENT_CHAR_LENGTH * i, CHUNK_COMMENT_CHAR_LENGTH)
      );
    }
  }

  actionMetricsPublisher.publishStringTruncate(RATING_URL_DIMENSION, ratingURL ?? window.location.href);
  actionMetricsPublisher.publishStringTruncate(RATING_USER_AGENT_DIMENSION, navigator.userAgent);

  if (addMetrics) {
    addMetrics(actionMetricsPublisher);
  }

  // Adding this constant for Spectrometer display, but can be re-configured later
  actionMetricsPublisher.publishStringTruncate(RATING_SUBJECT_DIMENSION, HMD_FEEDBACK_SUBJECT_OTHER);
}

export function pdLandingPagePublisher(data: PDMetricData) {
  const pdMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('PDLandingPage');
  pdMetricsPublisher.publishString('componentName', data.label);
  pdMetricsPublisher.publishString('action', data.action ?? 'view');
  pdMetricsPublisher.publishCounter('timestamp', data.timestamp);
  if (data.sessionId) {
    pdMetricsPublisher.publishString('sessionId', data.sessionId);
  }
}

/**
 * Returns cookie value from browsers Cookies store, or undefined if the cookie isn't found.
 */
export const getCookie = (name: string): any => {
  try {
    // document.cookie will return list of cookies delimited by ; (cookie1=value1; cookie2=value2)
    return document.cookie
      .split(';')
      .filter((cookie) => cookie.split('=')[0] === name)[0]
      .split('=')[1];
  } catch (e) {
    // if cookie is unavailable.
    logger.debug(`unable to get cookie: ${name}`);
    return undefined;
  }
};

/**
 * Returns true if the current stage is prod
 */
export const isProdStage = () => {
  const stage = 'prod';
  return stage.toLowerCase() === 'prod';
};

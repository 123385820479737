import { DisplayType } from '@amzn/coral_com-amazon-sssapinterface-model';

import { WEBLAB_TREATMENT, getTreatment, WEBLAB_NAME } from 'src/clients/weblab-client';

export const getLibraryDomainRoot = () => `https://library.exchange.aboutamazon.com`;

export enum MEDIA_TYPE {
  IMAGE = 'image',
  VIDEO = 'video',
  PDF = 'pdf',
  SUBTITLES = 'subtitle',
}

export const MAIN_DESKTOP_IMAGE_ROOT = 'https://library.exchange.aboutamazon.com/locale/en-us/resource-type/image/variation/desktop';

/**
 * This will provide the prefix for an image url in the content resource library.
 * @param isSmallDesktop whether this is a small desktop or not
 */
export const getImageRoot = (isSmallDesktop: boolean): string => {
  const imageVariant = isSmallDesktop ? 'desktop' : 'mobile';
  return `https://library.exchange.aboutamazon.com/locale/en-us/resource-type/image/variation/${imageVariant}`;
};

export const getCreditsAssetRoot = (isSmallDesktop: boolean, mediaType: MEDIA_TYPE): string => {
  const basePath = `https://library.exchange.aboutamazon.com/credits/locale/en-us/resource-type`;

  if (mediaType === MEDIA_TYPE.PDF) {
    return `${basePath}/${mediaType}`;
  }

  if (mediaType === MEDIA_TYPE.SUBTITLES) {
    return `${basePath}/${MEDIA_TYPE.VIDEO}/${mediaType}`;
  }

  const assetVariant = isSmallDesktop ? 'desktop' : 'mobile';
  return `${basePath}/${mediaType}/variation/${assetVariant}`;
};

/**
 * The display type (i.e. desktop vs mobile) based on the screen width size
 * @param isSmallDesktop whether this is a small desktop or not
 */
export const getDisplayType = (isSmallDesktop: boolean) => (isSmallDesktop ? DisplayType.STANDARD : DisplayType.MOBILE);

export const shouldRenderFluidPageSection = (): boolean =>
  WEBLAB_TREATMENT.T1 === getTreatment(WEBLAB_NAME.ASX_FLUID_PAGE_SECTION);

export const shouldRenderDeborahLandingPage = (): boolean =>
  WEBLAB_TREATMENT.T1 === getTreatment(WEBLAB_NAME.ASX_DEBORAH_LANDING_PAGE);

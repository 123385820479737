import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AsxFormType, ModuleIngress } from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';
import { useBundle } from '@amzn/react-arb-tools';

import { Dropdown, DropdownOption, Heading, HEADING_SIZE, PrimaryButton } from 'src/components/atoms';
import { CONTACT_FORM_KEYS } from 'src/components/pages/contact/ContactForm';
import { pageIngressToTopic } from 'src/components/pages/contact/form-helpers';
import {
  COMMON_BUNDLE_NAME,
  FORM_OVERRIDES_QUERY_PARAM,
  METADATA_QUERY_PARAM,
  PAGE_INGRESS_BY_ROUTE,
} from 'src/constants';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';

import styles from './FooterBanner.module.scss';

export function FooterBanner(): ReactElement {
  const location = useLocation();
  const [bundle, isBundleLoading] = useBundle(COMMON_BUNDLE_NAME);
  // Don't render the FooterBanner on the following routes.
  const footerBannerExcludedRoutes = [
    ROUTES.CONTACT_US,
    ROUTES.DEBORAH_INTEREST,
    ROUTES.DEBORAH,
    ROUTES.DEBORAH_FAQ,
  ].map((route) => route.toString());

  const [updatedDropdownOptions, setUpdatedDropdownOptions] = useState<DropdownOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>();

  useEffect(() => {
    if (!isBundleLoading) {
      setUpdatedDropdownOptions([
        {
          text: t(bundle, 'footerBanner_dropdownOptionOne') ?? '',
          value: AsxFormType.GENERAL_FEEDBACK,
        },
        {
          text: t(bundle, 'footerBanner_dropdownOptionTwo') ?? '',
          value: AsxFormType.GENERAL_CONTENT_INTAKE,
        },
      ]);

      // Default to general feedback option
      setSelectedOption(AsxFormType.GENERAL_FEEDBACK);
    }
  }, [bundle, isBundleLoading]);

  const handleDropdownChange = useCallback(
    // Event type is any because it's a customEvent coming from Katal
    (event: any) => {
      const selectedDropdownOptionValue = event.detail.value;

      // Update the options by removing the 'icon' property from all
      // Then find the selected option and add the 'icon' property
      const newDropdownOptions: Array<DropdownOption> = updatedDropdownOptions.map((option) => ({
        ...option,
        icon: option.text === selectedDropdownOptionValue ? 'check' : undefined, // Apply icon only to the selected option
      }));

      setSelectedOption(selectedDropdownOptionValue);
      setUpdatedDropdownOptions(newDropdownOptions);
    },
    [updatedDropdownOptions]
  );

  // Get current route with trailing slashes removed
  const currentRoute = location.pathname.replace(/\/+$/, '');
  const pageIngress = PAGE_INGRESS_BY_ROUTE[currentRoute];
  const topic = pageIngressToTopic[pageIngress];

  // Set up query param overrides that will be sent to contact page
  const queryStrings: Record<string, string> = {
    [FORM_OVERRIDES_QUERY_PARAM]: JSON.stringify({
      [CONTACT_FORM_KEYS.FORM_TYPE]: selectedOption,
      ...(topic && { [CONTACT_FORM_KEYS.FORM_TOPICS]: [topic] }),
    }),
    [METADATA_QUERY_PARAM]: JSON.stringify({
      // TODO: update with actual metadata keys and values once they are in the SSSAPI Model
      pageIngress,
      moduleIngress: ModuleIngress.FOOTER,
    }),
  };

  if (footerBannerExcludedRoutes.includes(currentRoute)) return <div />;

  return (
    <div className={styles.footerBannerWrapper}>
      <div className={styles.textWrapper}>
        <Heading className={styles.heading} size={HEADING_SIZE.h2}>
          {t(bundle, 'footerBanner_title')}
        </Heading>
        <p className={styles.description}>{t(bundle, 'footerBanner_description')}</p>
      </div>
      <div className={styles.dropdownWrapper}>
        <p className={styles.dropdownLabel}>{t(bundle, 'footerBanner_dropdownLabel')}</p>
        <Dropdown
          className={styles.dropdown}
          options={updatedDropdownOptions}
          onChange={handleDropdownChange}
          reversedIconOrder
        />
        <PrimaryButton
          className={styles.cta}
          theme={SECTION_THEME.Dark}
          label={t(bundle, 'footerBanner_ctaLabel') ?? ''}
          icon="chevronRight"
          role="button"
          contentClassName={styles.ctaContent}
          link={ROUTES.CONTACT_US}
          queryStrings={queryStrings}
        />
      </div>
    </div>
  );
}

import { FunctionComponent } from 'react';

import { ResourceType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { useBundle } from '@amzn/react-arb-tools';

import { Card, Grid, PageSection, PrimaryHero, SecondaryBanner, Slider, TextComponent } from 'src/components/common';
import { AssetBanner } from 'src/components/common/asset-banner/AssetBanner';
import { useFocusAreaCardsAccessible } from 'src/components/pages/home/hooks/useFocusAreaCards';
import { ClimatePledgeSection } from 'src/components/templates/ClimatePledgeSection';
import { CASE_STUDY_RESOURCE_BUNDLE_NAME, CLOUDFRONT_PREFIX, HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/constants';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import AllResources from 'src/fixtures/structures/resources';
import { extractAssetDisplayType, formatUrl, getDisplayType, getImageRoot, t } from 'src/helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

export const HomePage: FunctionComponent = () => {
  const [homepageBundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const [caseStudyBundle] = useBundle(CASE_STUDY_RESOURCE_BUNDLE_NAME);
  const { getCtaByResource } = useCommonStrings();
  const { isSmallDesktop } = useDeviceSize();
  const displayType = getDisplayType(isSmallDesktop);

  const imageRoot = getImageRoot(isSmallDesktop);

  const carbonCreditsCardResource = AllResources['carbonneutralization-casestudies-5'];

  const focusAreaCardsAccessible = useFocusAreaCardsAccessible();
  return (
    <>
      <PrimaryHero
        heading={t(homepageBundle, 'hero_title') ?? ''}
        description={t(homepageBundle, 'hero_description')}
        image={{ src: `${imageRoot}/home-hero.png` }}
        card={{
          target: '_self',
          title: t(homepageBundle, 'hero_redirectionCardTitle'),
          copy: t(homepageBundle, 'hero_redirectionCardDescription'),
          image: {
            src: `${CLOUDFRONT_PREFIX}/credits/locale/en-us/resource-type/image/variation/desktop/credits-ingresspoint-1.jpg`,
            alt: t(homepageBundle, 'hero_redirectionCardImageAltText'),
          },
          link: ROUTES.DEBORAH,
          ctaPosition: 'bottom',
        }}
      />
      <PageSection>
        {/* TODO: The Slider component uses TextComponent which has the below bug. */}
        {/* TODO: Copy is required when it should be optional and text not full-width due to copy still injected in dom */}
        {/* TODO: https://app.asana.com/0/1207500805232850/1207595341447795 */}
        {/* TODO: Also, extra padding on the slides causes it not to align with the heading: https://app.asana.com/0/1207500805232850/1207595341447807 */}
        {/* TODO: Add alt text to these card images when fixed (strings are already created): https://app.asana.com/0/1207500805232850/1207615185237716 */}
        <Slider heading={t(homepageBundle, 'news_title') ?? ''} displayContents>
          <Card
            title={t(caseStudyBundle, carbonCreditsCardResource.display?.title)}
            subtitle={t(caseStudyBundle, carbonCreditsCardResource.display?.shortDescription)}
            image={
              carbonCreditsCardResource.assets.secondaryAssets?.thumbnail && {
                src: formatUrl(
                  extractAssetDisplayType(carbonCreditsCardResource.assets.secondaryAssets?.thumbnail, displayType)
                    ?.assetUrl
                ),
                alt: carbonCreditsCardResource.assets.secondaryAssets?.thumbnail?.[0].altText,
              }
            }
            link={{
              label: getCtaByResource(carbonCreditsCardResource.resourceType as ResourceType).label,
              url: formatUrl(carbonCreditsCardResource.assets.primaryAsset[0].assetUrl),
            }}
            icon="chevronRight"
            skipAccessibleLabeling
          />
          <Card
            title={t(homepageBundle, 'news_card2Title')}
            subtitle={t(homepageBundle, 'news_card2Description')}
            image={{
              src: `${imageRoot}/home-newsandhighlights-measurement.png`,
              alt: t(homepageBundle, 'news_card2ImageAltText'),
            }}
            link={{
              label: t(homepageBundle, 'news_card2CTA') ?? '',
              url: ROUTES.MEASUREMENT,
            }}
            openInSameTab
            icon="chevronRight"
            skipAccessibleLabeling
          />
          <Card
            title={t(homepageBundle, 'news_card3Title')}
            subtitle={t(homepageBundle, 'news_card3Description')}
            image={{
              src: `${imageRoot}/home-newsandhighlights-DPM.png`,
              alt: t(homepageBundle, 'news_card3ImageAltText'),
            }}
            link={{
              label: t(homepageBundle, 'news_card3CTA') ?? '',
              url: ROUTES.SCIENCE_EXCHANGE,
            }}
            openInSameTab
            icon="chevronRight"
            skipAccessibleLabeling
          />
        </Slider>
      </PageSection>

      <AssetBanner
        description={t(homepageBundle, 'approach_description') ?? ''}
        heading={t(homepageBundle, 'approach_title') ?? ''}
        cta={{
          link: ROUTES.GETTING_STARTED,
          copy: t(homepageBundle, 'approach_ctaLabel') ?? '',
        }}
      />

      <PageSection theme="slate-dark">
        <TextComponent
          copy={t(homepageBundle, 'focusAreas_description')}
          heading={t(homepageBundle, 'focusAreas_title') ?? ''}
        />
        <Grid cards={focusAreaCardsAccessible} />
      </PageSection>

      <PageSection>
        <SecondaryBanner
          imagePrimary={{
            src: `${imageRoot}/home-thescienceexchange-1.png`,
            alt: t(homepageBundle, 'scienceExchange_imageOneAltText') ?? '',
          }}
          imageSecondary={{
            src: `${imageRoot}/home-thescienceexchange-2.png`,
            alt: t(homepageBundle, 'scienceExchange_imageTwoAltText') ?? '',
          }}
          description={t(homepageBundle, 'scienceExchange_description') ?? ''}
          title={t(homepageBundle, 'scienceExchange_title') ?? ''}
          subTitle={t(homepageBundle, 'scienceExchange_subTitle') ?? ''}
          theme={SECTION_THEME.Light}
          cta={{
            label: t(homepageBundle, 'scienceExchange_ctaLabel') ?? '',
            link: ROUTES.SCIENCE_EXCHANGE,
          }}
        />
      </PageSection>

      <ClimatePledgeSection />
    </>
  );
};

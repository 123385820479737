import classNames from 'classnames';
import { HTMLAttributes, useCallback, useEffect, useRef, useState, type FunctionComponent } from 'react';

import { IconType } from 'src/components/atoms';
import {
  DynamicItem,
  TranscriptionFragments,
  DynamicMedia,
  FixedTaglineAndTitle,
  SlideCounter,
} from 'src/components/common';
import { SUBTITLE_CLASS } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { convertToKebabCase } from 'src/helpers';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import { ImageAttributes, TooltipAttributes } from 'src/interfaces/CommonAttributes';

import styles from './DynamicList.module.scss';
import { DesktopHeader } from './headers/DesktopHeader';
import { MobileHeader } from './headers/MobileHeader';

export type DynamicListItem = {
  body: string;
  title: string;
  tagline?: string;
  link?: { text: string; href?: string; icon?: IconType; onClickOverride?: () => void };
  tooltipTheme?: SECTION_THEME;
  tooltipAttributes?: TooltipAttributes[];
};

export interface DynamicListProps extends HTMLAttributes<HTMLDivElement> {
  items: Array<DynamicListItem>;
  isModalVisible?: boolean;
  focusIndex?: number;
  svgProps?: { heading: string; subtitle: string; SVGAriaLabel: string };
  mediaProps?: {
    media: {
      image?: ImageAttributes;
      lottie?: { animationData: object; alt: string };
      video?: {
        thumbnail: ImageAttributes;
        videoSrc: string;
        captionsSrc?: string;
        transcription?: Array<TranscriptionFragments>;
      };
      quote?: string;
      byLine?: string;
    }[];
    mediaTitle: string;
    mediaTagline: string;
  };
  activeTab?: number;
}

export const DynamicList: FunctionComponent<DynamicListProps> = ({
  className,
  svgProps,
  items,
  isModalVisible,
  focusIndex,
  mediaProps,
  activeTab,
  ...props
}) => {
  // TODO add stories for for variants https://issues.amazon.com/issues/ASX-314

  const { isDesktop } = useDeviceTracker();

  const self = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const fixedTitleRef = useRef<HTMLDivElement>(null);

  const [activeSlide, setActiveSlide] = useState(0);

  const getContentHeight = (element: HTMLElement) => {
    const heightWithPaddings = element.clientHeight;
    const elementComputedStyle = window.getComputedStyle(element, null);

    return (
      heightWithPaddings - parseFloat(elementComputedStyle.paddingTop) - parseFloat(elementComputedStyle.paddingBottom)
    );
  };

  // istanbul ignore next
  const handleResize = useCallback(() => {
    const hasRequiredElements = self.current && imageContainerRef.current && listRef.current;

    if (hasRequiredElements) {
      const lastItem = listRef.current.lastChild as HTMLElement;
      const imageHeight = getContentHeight(imageContainerRef.current);

      const additionalBottomPaddingPx = imageHeight - lastItem.offsetHeight;
      listRef.current.style.paddingBottom =
        !!svgProps && !mediaProps ? `${additionalBottomPaddingPx}px` : `${lastItem.offsetHeight}px`;

      if (!isDesktop) {
        self.current.style.removeProperty('padding-bottom');
        self.current.style.removeProperty('top');
        return;
      }

      imageContainerRef.current.style.top = `10%`;

      const distanceToBottomPx = (window.innerHeight - imageContainerRef.current.offsetHeight) / 2;
      self.current.style.paddingBottom = `${distanceToBottomPx}px`;
    }

    const hasMediaElements = mediaProps && imageRef.current && fixedTitleRef.current && listRef.current;

    if (hasMediaElements) {
      const last = listRef.current.lastChild as HTMLElement;

      const imageHeight = imageRef.current.getBoundingClientRect().height;
      const lastItemHeight = last.getBoundingClientRect().height;
      const fixedTitleHeight = fixedTitleRef.current.getBoundingClientRect().height;

      const fixedTitleMargin = imageHeight - fixedTitleHeight;

      const lastItemMarginBottom = lastItemHeight - fixedTitleMargin;

      last.style.marginBottom = `${imageHeight - lastItemHeight - fixedTitleHeight}px`;
      fixedTitleRef.current.style.marginBottom = `${fixedTitleMargin}px`;
      listRef.current.style.marginTop = `-${fixedTitleMargin}px`;

      if (fixedTitleMargin < lastItemHeight) {
        listRef.current.style.marginBottom = `${lastItemMarginBottom}px`;
      } else {
        listRef.current.style.marginBottom = '0';
      }
    }
  }, [isDesktop, mediaProps, svgProps]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [self, handleResize]);

  useEffect(() => {
    (self.current?.querySelector(`[data-cta-index='${focusIndex}']`) as HTMLElement)?.focus();
  }, [focusIndex]);

  // to prevent bugs we reset active slide when content changes
  useEffect(() => {
    if (activeTab === undefined) return;
    requestAnimationFrame(() => setActiveSlide(0));
  }, [activeTab]);

  return (
    <>
      <div
        ref={self}
        className={classNames(className, styles.dynamicList, svgProps && styles.svgVariant)}
        id={styles.dynamicList}
        {...props}
      >
        {svgProps &&
          (isDesktop ? (
            <DesktopHeader heading={svgProps.heading} activeSlide={activeSlide} />
          ) : (
            <MobileHeader
              heading={svgProps.heading}
              subtitle={svgProps.subtitle}
              activeSlide={activeSlide}
              items={items}
            />
          ))}
        {mediaProps && (
          <DynamicMedia
            ref={imageRef}
            media={mediaProps.media}
            activeSlide={activeSlide}
            mediaTagline={mediaProps.mediaTagline}
            mediaTitle={mediaProps.mediaTitle}
          />
        )}

        <div className={styles.list}>
          {mediaProps && isDesktop && (
            <div className={styles.fixedTitle} ref={fixedTitleRef}>
              <FixedTaglineAndTitle mediaTagline={mediaProps.mediaTagline} mediaTitle={mediaProps.mediaTitle} />
              <SlideCounter activeSlide={activeSlide} totalSlides={items.length} />
            </div>
          )}
          {isDesktop && svgProps && <p className={classNames(styles.subtitle, SUBTITLE_CLASS)}>{svgProps.subtitle}</p>}
          <ul ref={listRef}>
            {isDesktop ? (
              <>
                {items.map((item, index) => (
                  <DynamicItem
                    hasSvgProps={!!svgProps && !mediaProps}
                    key={`${convertToKebabCase(item.title)}_${index}`}
                    {...item}
                    isFirst={index === 0}
                    isLast={index === items.length - 1}
                    onActiveChange={() => setActiveSlide(index)}
                    isActive={index === activeSlide}
                    isModalVisible={isModalVisible}
                    index={index}
                    fixedTitleRef={fixedTitleRef}
                  />
                ))}
              </>
            ) : (
              <>
                {items.map(
                  (item, index) =>
                    index !== items.length - 1 && (
                      <DynamicItem
                        key={`${convertToKebabCase(item.title)}_${index}`}
                        hasSvgProps={!!svgProps && !mediaProps}
                        {...item}
                        isFirst={index === 0}
                        isLast
                        onActiveChange={() => setActiveSlide(index)}
                        isActive={index === activeSlide}
                        isModalVisible={isModalVisible}
                        index={index}
                        fixedTitleRef={fixedTitleRef}
                      />
                    )
                )}
              </>
            )}
          </ul>
        </div>
      </div>

      {!isDesktop && (
        <ul>
          {items.map(
            (item, index) =>
              index === items.length - 1 && (
                <DynamicItem
                  key={`${convertToKebabCase(item.title)}_${index}`}
                  hasSvgProps={!!svgProps && !mediaProps}
                  {...item}
                  isFirst={index === 0}
                  isLast
                  onActiveChange={() => setActiveSlide(index)}
                  isActive={index === activeSlide}
                  isModalVisible={isModalVisible}
                  index={index}
                  fixedTitleRef={fixedTitleRef}
                />
              )
          )}
        </ul>
      )}
    </>
  );
};

import { motion } from 'motion/react';
import { useEffect, useRef, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { Outro, Tagline } from 'src/components/atoms';
import { BentoCards, BentoCardContent, BENTO_CARD_MODAL_STATUS } from 'src/components/atoms/bento-cards/BentoCards';
import { CardsDashboard } from 'src/components/atoms/cards-dashboard/CardsDashboard';
import { HeroAsset } from 'src/components/atoms/hero-asset/HeroAsset';
import { NavigationTabs } from 'src/components/atoms/navigation-tabs/NavigationTabs';
import {
  AdvocatesComponent,
  AnimatedHeroBanner,
  PageSection,
  CustomGraphic,
  TextAndCard,
  DynamicList,
  FeaturedBanner,
  TextComponent,
  BENTO_CARD_VARIANT,
} from 'src/components/common';
import { CREDITS_THESCIENCE_VIDEO_1_SUBTITLES } from 'src/components/common/video-with-poster/credits-thescience-video-1';
import { CLOUDFRONT_PREFIX, COMMON_BUNDLE_NAME, CREDITS_BUNDLE_NAME } from 'src/constants';
import { HEADING_TAG } from 'src/data/enums/Heading';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t, parseCaptionsToTranscription, getCreditsAssetRoot, MEDIA_TYPE } from 'src/helpers';
import { useComponentTracking } from 'src/hooks/useComponentTracking';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import { useGroupAnimation } from 'src/hooks/useGroupAnimation';
import featuredBannerFallback from 'src/images/fallback/featured-banner-fallback.jpg';
import carbonCapture from 'src/lotties/carbon-capture-desktop.json';
import carbonRemovalAnimationDesktop from 'src/lotties/carbon-removal-desktop.json';
import carbonRemovalAnimationMobile from 'src/lotties/carbon-removal-mobile.json';

import styles from './ProjectDeborah.module.scss';
import { featuredBannerCaptions } from './captions/featuredBannerCaptions';

// TODO: Replace all mocked assets with original ones SIM: https://issues.amazon.com/issues/ASX-332

// First tab is reducing deforestation
const RESTORATION_TAB_INDEX = 1;
const CARBON_REMOVAL_TAB_INDEX = 2;

export const ProjectDeborah = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [bentoCardsModalIsVisible, setBentoCardsModalIsVisible] = useState(false);
  const eligibilityCriteriaRef = useRef<HTMLDivElement | null>(null);
  useComponentTracking();

  const [creditsBundle] = useBundle(CREDITS_BUNDLE_NAME);
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const { isDesktop, isMedium } = useDeviceTracker();

  const { refs, colorStyles } = useGroupAnimation();
  const { groupRef, featuredBannerRef, textRef } = refs;

  const handleOpenBentoCard = (newStatus: BENTO_CARD_MODAL_STATUS) => {
    setBentoCardsModalIsVisible(newStatus !== BENTO_CARD_MODAL_STATUS.None);
  };

  // Display a plain image thumbnail on mobile and a video on desktop
  const deforestationSecondMediaAsset = isDesktop
    ? {
        video: {
          videoSrc: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.VIDEO)}/credits-segments-deforestation-ourapproach-2.mp4`,
          thumbnail: {
            src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-ourapproach-2.jpg`,
            alt: t(creditsBundle, 'creditsPage_segments_dynamicList_secondMedia_altText_1'),
          },
        },
        byLine: t(creditsBundle, 'creditsPage_segments_dynamicList_secondMedia_byLine_1'),
      }
    : {
        image: {
          src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-ourapproach-2.jpg`,
          alt: t(creditsBundle, 'creditsPage_segments_dynamicList_secondMedia_altText_1'),
        },
        byLine: t(creditsBundle, 'creditsPage_segments_dynamicList_secondMedia_byLine_1'),
      };

  const segmentsAssets = [
    {
      heroAsset: {
        src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-hero-1.jpg`,
        alt: t(creditsBundle, 'creditsPage_segments_assetHero_altText_1'),
      },
      dynamicList: {
        mediaProps: {
          mediaTitle: t(creditsBundle, 'creditsPage_segments_dynamicList_title_1'),
          mediaTagline: t(creditsBundle, 'creditsPage_segments_dynamicList_label_1'),
          media: [
            {
              image: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-ourapproach-1.jpg`,
                alt: t(creditsBundle, 'creditsPage_segments_dynamicList_firstMedia_altText_1'),
              },
              byLine: t(creditsBundle, 'creditsPage_segments_dynamicList_firstMedia_byLine_1'),
            },
            deforestationSecondMediaAsset,
          ],
        },
        items: [
          {
            title: t(creditsBundle, 'creditsPage_segments_dynamicList_firstItem_title_1'),
            body: t(creditsBundle, 'creditsPage_segments_dynamicList_firstItem_textContent_1'),
            tooltipAttributes: [
              {
                description: t(creditsBundle, 'creditsPage_segments_dynamicList_firstItem_tooltip_content_1'),
                cta: {
                  label: t(creditsBundle, 'creditsPage_segments_dynamicList_firstItem_tooltip_linkLabel_1'),
                  url: 'https://www.leafcoalition.org/',
                },
              },
            ],
          },
          {
            title: t(creditsBundle, 'creditsPage_segments_dynamicList_secondItem_title_1'),
            body: t(creditsBundle, 'creditsPage_segments_dynamicList_secondItem_textContent_1'),
          },
        ],
      },
      primaryBentoCards: {
        firstCard: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-ourquality-1.jpg`,
        secondCard: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-ourquality-3.jpg`,
      },
      secondaryBentoCards: {
        firstCard: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-ourquality-1.jpg`,
        firstCardModal: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-ourquality-2.jpg`,
        secondCard: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-ourquality-3.jpg`,
        secondCardModal: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-ourquality-4.jpg`,
      },
    },
    {
      heroAsset: {
        src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-hero-1.jpg`,
        alt: t(creditsBundle, 'creditsPage_segments_assetHero_altText_2'),
      },
      cardsDashboard: {
        firstCard: {
          src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-thechallenge-1.jpg`,
          alt: t(creditsBundle, 'creditsPage_segments_cardsDashboard_firstCard_altText_2'),
        },
        secondCard: {
          src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-thechallenge-2.jpg`,
          alt: t(creditsBundle, 'creditsPage_segments_cardsDashboard_secondCard_altText_2'),
        },
        fourthCard: {
          src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-thechallenge-3.jpg`,
          alt: t(creditsBundle, 'creditsPage_segments_cardsDashboard_fourthCard_altText_2'),
        },
      },
      dynamicList: {
        mediaProps: {
          mediaTitle: t(creditsBundle, 'creditsPage_segments_dynamicList_title_2'),
          mediaTagline: t(creditsBundle, 'creditsPage_segments_dynamicList_label_2'),
          media: [
            {
              image: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-ourapproach-1.jpg`,
                alt: t(creditsBundle, 'creditsPage_segments_dynamicList_firstMedia_altText_2'),
              },
              byLine: t(creditsBundle, 'creditsPage_segments_dynamicList_firstMedia_byLine_2'),
            },
            {
              image: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-ourapproach-2.jpg`,
                alt: t(creditsBundle, 'creditsPage_segments_dynamicList_secondMedia_altText_2'),
              },
            },
          ],
        },

        items: [
          {
            title: t(creditsBundle, 'creditsPage_segments_dynamicList_firstItem_title_2'),
            body: t(creditsBundle, 'creditsPage_segments_dynamicList_firstItem_textContent_2'),
            tooltipAttributes: [
              {
                description: t(creditsBundle, 'creditsPage_segments_dynamicList_firstItem_tooltip_content_2'),
              },
            ],
          },
          {
            title: t(creditsBundle, 'creditsPage_segments_dynamicList_secondItem_title_2'),
            body: t(creditsBundle, 'creditsPage_segments_dynamicList_secondItem_textContent_2'),
          },
        ],
      },
      secondaryBentoCards: {
        firstCard: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-ourquality-1.jpg`,
        firstCardModal: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-ourquality-2.jpg`,
        secondCard: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-ourquality-3.jpg`,
        secondCardModal: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-restoration-ourquality-4.jpg`,
      },
    },
    {
      heroAsset: {
        src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-tcr-hero-1.jpg`,
        alt: t(creditsBundle, 'creditsPage_segments_assetHero_altText_3'),
      },
      dynamicList: {
        mediaProps: {
          mediaTitle: t(creditsBundle, 'creditsPage_segments_dynamicList_title_3'),
          mediaTagline: t(creditsBundle, 'creditsPage_segments_dynamicList_label_3'),
          media: [
            {
              image: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-tcr-ourapproach-1.jpg`,
                alt: t(creditsBundle, 'creditsPage_segments_dynamicList_firstMedia_altText_3'),
              },
            },
            {
              lottie: {
                animationData: carbonCapture,
                alt: t(creditsBundle, 'creditsPage_segments_dynamicList_secondMedia_altText_3'),
              },
            },
          ],
        },
        items: [
          {
            title: t(creditsBundle, 'creditsPage_segments_dynamicList_firstItem_title_3'),
            body: t(creditsBundle, 'creditsPage_segments_dynamicList_firstItem_textContent_3'),
          },
          {
            title: t(creditsBundle, 'creditsPage_segments_dynamicList_secondItem_title_3'),
            body: t(creditsBundle, 'creditsPage_segments_dynamicList_secondItem_textContent_3'),
          },
        ],
      },
      hasAnimatedGraphic: true,
      secondaryBentoCards: {
        firstCard: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-tcr-ourquality-1.jpg`,
        firstCardModal: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-tcr-ourquality-2.jpg`,
        secondCard: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-tcr-ourquality-3.jpg`,
      },
    },
  ];

  // This resets our coordinates on page load to make sure the banner animation will be visible instead of the page
  // going back to the previous scroll position
  useEffect(() => {
    // Disable native scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    // Scroll to top of the page
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AnimatedHeroBanner
        title={t(creditsBundle, 'creditsPage_heroBanner_title')}
        subtitle={t(creditsBundle, 'creditsPage_heroBanner_subtitle')}
        eligibilityCta={{ label: t(creditsBundle, 'creditsPage_heroBanner_eligibilityCtaLabel') }}
        getStartedCta={{
          label: t(creditsBundle, 'creditsPage_heroBanner_getStartedCtaLabel'),
          link: ROUTES.DEBORAH_INTEREST,
        }}
        eligibilityCriteriaRef={eligibilityCriteriaRef}
        eyebrow={t(creditsBundle, 'creditsPage_heroBanner_testimonials_eyebrow')}
        brandTabs={{
          options: [
            {
              brandIconImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-logo-1.png`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_brand_altText-1'),
              },
              profileImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-headshot-1.jpg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerHeadshot_altText-1'),
              },
              profileName: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerName-1'),
              profileOccupation: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerTitle-1'),
            },
            {
              brandIconImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-logo-2.svg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_brand_altText-2'),
              },
              profileImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-headshot-2.jpg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerHeadshot_altText-2'),
              },
              profileName: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerName-2'),
              profileOccupation: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerTitle-2'),
            },
            {
              brandIconImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-logo-3.svg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_brand_altText-3'),
              },
              profileImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-headshot-3.jpg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerHeadshot_altText-3'),
              },
              profileName: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerName-3'),
              profileOccupation: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerTitle-3'),
            },
            {
              brandIconImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-logo-4.svg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_brand_altText-4'),
              },
              profileImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-headshot-4.jpg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerHeadshot_altText-4'),
              },
              profileName: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerName-4'),
              profileOccupation: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerTitle-4'),
            },
            {
              brandIconImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-logo-5.svg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_brand_altText-5'),
              },
              profileImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-headshot-5.jpg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerHeadshot_altText-5'),
              },
              profileName: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerName-5'),
              profileOccupation: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerTitle-5'),
            },
            {
              brandIconImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-logo-7.svg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_brand_altText-7'),
              },
              profileImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-headshot-7.jpg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerHeadshot_altText-7'),
              },
              profileName: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerName-7'),
              profileOccupation: t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerTitle-7'),
            },
          ],
        }}
        quotes={[
          t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerQuote-1'),
          t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerQuote-2'),
          t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerQuote-3'),
          t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerQuote-4'),
          t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerQuote-5'),
          t(creditsBundle, 'creditsPage_heroBanner_testimonials_customerQuote-7'),
        ]}
        backgroundImage={{
          src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-testimonials-1.jpg`,
          alt: t(creditsBundle, 'creditsPage_heroBanner_background_image_altText'),
        }}
      />
      <motion.div ref={groupRef} style={colorStyles.groupColor}>
        <TextAndCard
          dataTrackLabel="Eligibility"
          ref={eligibilityCriteriaRef}
          title={t(creditsBundle, 'creditsPage_textAndCard_title')}
          subTitle={t(creditsBundle, 'creditsPage_textAndCard_subtitle')}
          link={{
            label: t(creditsBundle, 'creditsPage_textAndCard_link_label'),
            href: 'https://sustainability.aboutamazon.com/carbon-neutralization.pdf',
            icon: 'download',
          }}
          card={{
            title: t(creditsBundle, 'creditsPage_textAndCard_card_title'),
            copy: t(creditsBundle, 'creditsPage_textAndCard_card_copy'),
            list: [
              t(creditsBundle, 'creditsPage_textAndCard_card_list_item_1'),
              t(creditsBundle, 'creditsPage_textAndCard_card_list_item_2'),
              t(creditsBundle, 'creditsPage_textAndCard_card_list_item_3'),
            ],
            ctas: {
              primaryLabel: t(creditsBundle, 'creditsPage_textAndCard_card_cta_1_label'),
              secondaryLabel: t(creditsBundle, 'creditsPage_textAndCard_card_cta_2_label'),
            },
          }}
          bigImage={{
            src: `${getCreditsAssetRoot(isMedium, MEDIA_TYPE.IMAGE)}/credits-accesscarboncredits-1.jpg`,
            alt: t(creditsBundle, 'creditsPage_textAndCard_big_image_alt'),
          }}
          smallImage={{
            src: `${getCreditsAssetRoot(isMedium, MEDIA_TYPE.IMAGE)}/credits-accesscarboncredits-2.jpg`,
            alt: t(creditsBundle, 'creditsPage_textAndCard_small_image_alt'),
          }}
        />
        <motion.div
          data-track-label="The Science Speaks For Itself"
          ref={featuredBannerRef}
          style={colorStyles.featuredBannerOpacity}
        >
          <FeaturedBanner
            title={`${t(creditsBundle, 'creditsPage_featuredBanner_title')}`}
            subTitle={{
              linkModal: t(creditsBundle, 'creditsPage_featuredBanner_textContent_link'),
              copy: t(creditsBundle, 'creditsPage_featuredBanner_textContent'),
            }}
            link={{
              label: t(creditsBundle, 'creditsPage_featuredBanner_ctaLabel'),
              icon: 'plus',
            }}
            image={{
              src: `${CLOUDFRONT_PREFIX}/credits/locale/en-us/resource-type/image/variation/desktop/credits-thescience-1.jpg`,
              alt: t(creditsBundle, 'creditsPage_featuredBanner_image_alt'),
            }}
            video={{
              transcription: parseCaptionsToTranscription(featuredBannerCaptions),
              videoSrc: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.VIDEO)}/credits-thescience-video-1.mp4`,
              captionsSrc: CREDITS_THESCIENCE_VIDEO_1_SUBTITLES,
              previewVideoSrc: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.VIDEO)}/credits-thescience-videothumb-1.mp4`,
              ctaLabel: t(commonBundle, 'video_play'),
              playDataTrackLabel: 'Science Speaks Video Play',
              thumbnail: { src: featuredBannerFallback },
            }}
            linkModalContent={{
              title: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_title'),
              tagline: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_label'),
              stats: [
                {
                  heading: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_number_1'),
                  copy: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_text_1'),
                  image: {
                    src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-thescience-2.jpg`,
                    alt: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_text_1_alt'),
                  },
                },
                {
                  heading: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_number_2'),
                  copy: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_text_2'),
                  image: {
                    src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-thescience-3.jpg`,
                    alt: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_text_2_alt'),
                  },
                },
                {
                  heading: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_number_3'),
                  copy: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_text_3'),
                  image: {
                    src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-thescience-4.jpg`,
                    alt: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_text_3_alt'),
                  },
                },
                {
                  heading: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_number_4'),
                  copy: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_text_4'),
                  image: {
                    src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-thescience-5.jpg`,
                    alt: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_statItem_text_4_alt'),
                  },
                },
              ],
              footerContent: {
                hasInvertedColors: true,
                iconName: 'library',
                link: {
                  linkCopy: t(creditsBundle, 'creditsPage_featuredBanner_statsModal_footerLinkLabel'),
                  linkHref: 'https://sustainability.aboutamazon.com/2023-report',
                },
              },
            }}
            inTextLinkModalContent={{
              tagline: t(creditsBundle, 'creditsPage_featuredBanner_textContent_link'),
              quote: t(creditsBundle, 'creditsPage_featuredBanner_quoteModal_quote'),
              footerContent: {
                iconName: 'info',
                copy: t(creditsBundle, 'creditsPage_featuredBanner_quoteModal_footerLinkLabel_copy'),
                link: {
                  linkCopy: t(creditsBundle, 'creditsPage_featuredBanner_quoteModal_footerLinkLabel'),
                  linkHref: 'https://www.ipcc.ch/sr15/chapter/spm/',
                },
              },
            }}
          />
        </motion.div>
      </motion.div>

      <PageSection className={styles.segmentsTitleWrapper}>
        <motion.h5 className={styles.segmentsTitle} ref={textRef} style={colorStyles.textOpacity}>
          {t(creditsBundle, 'creditsPage_segments_title')}
        </motion.h5>
      </PageSection>

      <div className={styles.navigationAnchorSection}>
        <NavigationTabs
          activeTab={activeTab}
          onTabChange={(index) => setActiveTab(index)}
          shouldDisplayBelow={bentoCardsModalIsVisible}
          tabs={[
            {
              image: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-navigationtab-1.jpg`,
                alt: t(creditsBundle, 'creditsPage_segments_navigationTabs_altText_1'),
              },
              label: t(creditsBundle, 'creditsPage_segments_navigationTabs_title_1'),
            },
            {
              image: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-navigationtab-2.jpg`,
                alt: t(creditsBundle, 'creditsPage_segments_navigationTabs_altText_2'),
              },
              label: t(creditsBundle, 'creditsPage_segments_navigationTabs_title_2'),
            },
            {
              image: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-navigationtab-3.jpg`,
                alt: t(creditsBundle, 'creditsPage_segments_navigationTabs_altText_3'),
              },
              label: t(creditsBundle, 'creditsPage_segments_navigationTabs_title_3'),
            },
          ]}
        />
        <PageSection className={styles.segmentsSection}>
          <div
            id="navigation-tabs-content"
            {...(isDesktop && {
              role: 'tabpanel',
              'aria-labelledby': `navigation-tab-${activeTab + 1}`,
            })}
          >
            <HeroAsset
              title={t(creditsBundle, `creditsPage_segments_navigationTabs_title_${activeTab + 1}`)}
              image={segmentsAssets[activeTab].heroAsset}
            />

            {RESTORATION_TAB_INDEX === activeTab && (
              // TODO create component for this https://issues.amazon.com/issues/ASX-486
              <div
                data-track-label={t(creditsBundle, `creditsPage_segments_textComponent_label_${activeTab + 1}`)}
                className={styles.restorationTabSection}
              >
                <Tagline className={styles.tagline}>
                  {t(creditsBundle, `creditsPage_segments_textComponent_label_${activeTab + 1}`)}
                </Tagline>
                <TextComponent
                  className={styles.textComponent}
                  headingClassName={styles.textComponentHeading}
                  contentClassName={styles.textComponentContent}
                  headingSize={HEADING_TAG.H3}
                  theme={SECTION_THEME.Light}
                  heading={t(creditsBundle, `creditsPage_segments_textComponent_title_${activeTab + 1}`)}
                  copy={t(creditsBundle, `creditsPage_segments_textComponent_description_${activeTab + 1}`)}
                  id="text-component"
                />
              </div>
            )}

            {segmentsAssets[activeTab].primaryBentoCards && (
              <BentoCards
                hideImageCardsOnMobile
                onBentoModalChange={handleOpenBentoCard}
                label={t(creditsBundle, `creditsPage_segments_textComponent_label_${activeTab + 1}`)}
                title={t(creditsBundle, `creditsPage_segments_textComponent_title_${activeTab + 1}`)}
                titleSize={activeTab === RESTORATION_TAB_INDEX ? HEADING_TAG.H5 : HEADING_TAG.H4}
                description={t(creditsBundle, `creditsPage_segments_textComponent_description_${activeTab + 1}`)}
                tooltipAttributes={[
                  {
                    cta: {
                      url: 'https://www.nasa.gov/science-research/earth-science/nasa-satellites-help-quantify-forests-impacts-on-global-carbon-budget/',
                      label: t(creditsBundle, 'creditsPage_segments_textComponent_tooltip_linkLabel'),
                      icon: 'chevronTopRight',
                    },
                    description: `${t(commonBundle, 'source')}`,
                  },
                ]}
                tooltipTheme={SECTION_THEME.Light}
                firstCard={{
                  label: t(creditsBundle, `creditsPage_segments_cardsDashboard_secondCard_label_${activeTab + 1}`),
                  title: t(creditsBundle, `creditsPage_segments_cardsDashboard_secondCard_title_${activeTab + 1}`),
                  description: t(
                    creditsBundle,
                    `creditsPage_segments_cardsDashboard_secondCard_textContent_${activeTab + 1}`
                  ),
                }}
                secondCard={{
                  src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-segments-deforestation-thechallenge-3.jpg`,
                  alt: t(creditsBundle, `creditsPage_segments_thechallenge_altText_1`),
                }}
              />
            )}

            {segmentsAssets[activeTab].cardsDashboard && (
              <CardsDashboard
                firstCard={segmentsAssets[activeTab].cardsDashboard!.firstCard}
                secondCard={{
                  label: t(creditsBundle, `creditsPage_segments_cardsDashboard_firstCard_label_${activeTab + 1}`),
                  title: t(creditsBundle, `creditsPage_segments_cardsDashboard_firstCard_title_${activeTab + 1}`),
                  description: t(
                    creditsBundle,
                    `creditsPage_segments_cardsDashboard_firstCard_textContent_${activeTab + 1}`
                  ),
                  variant:
                    activeTab === RESTORATION_TAB_INDEX ? BENTO_CARD_VARIANT.STANDARD : BENTO_CARD_VARIANT.GRAPHIC,
                  // we know certainly that cardsDashboard will not be undefined on the second tab
                  // but we need to explicictly state it so typescript won't error due to type inference
                  image:
                    activeTab === RESTORATION_TAB_INDEX
                      ? segmentsAssets[activeTab].cardsDashboard!.secondCard
                      : undefined,
                }}
                thirdCard={{
                  label: t(creditsBundle, `creditsPage_segments_cardsDashboard_secondCard_label_${activeTab + 1}`),
                  title: t(creditsBundle, `creditsPage_segments_cardsDashboard_secondCard_title_${activeTab + 1}`),
                  description: t(
                    creditsBundle,
                    `creditsPage_segments_cardsDashboard_secondCard_textContent_${activeTab + 1}`
                  ),
                }}
                fourthCard={segmentsAssets[activeTab].cardsDashboard!.fourthCard}
              />
            )}

            {segmentsAssets[activeTab].hasAnimatedGraphic && (
              <CustomGraphic
                tagline={t(creditsBundle, 'creditsPage_customGraphic_tagline')}
                heading={t(creditsBundle, 'creditsPage_customGraphic_heading')}
                copy={t(creditsBundle, 'creditsPage_customGraphic_copy')}
                lottieAnimation={isMedium ? carbonRemovalAnimationDesktop : carbonRemovalAnimationMobile}
                lottieAlt={t(creditsBundle, 'creditsPage_customGraphic_lottie_alt')}
                emissionsCopy={t(creditsBundle, 'creditsPage_customGraphic_emissions_copy')}
                details={{
                  detailFirst: t(creditsBundle, 'creditsPage_customGraphic_detail_first'),
                  detailSecond: t(creditsBundle, 'creditsPage_customGraphic_detail_second'),
                  detailThird: t(creditsBundle, 'creditsPage_customGraphic_detail_third'),
                  detailFourth: t(creditsBundle, 'creditsPage_customGraphic_detail_fourth'),
                  detailFifth: t(creditsBundle, 'creditsPage_customGraphic_detail_fifth'),
                }}
                footerContent={{
                  footerParagraph: t(creditsBundle, 'creditsPage_customGraphic_footer_paragraph'),
                  footerLinkLabel: t(creditsBundle, 'creditsPage_customGraphic_footer_link_label'),
                  footerLinkHref: t(creditsBundle, 'creditsPage_customGraphic_footer_link_href'),
                  footerCTA: t(creditsBundle, 'creditsPage_customGraphic_footer_cta'),
                }}
                modalContent={{
                  image: {
                    src: isDesktop
                      ? `${CLOUDFRONT_PREFIX}/credits/locale/en-us/resource-type/image/variation/desktop/custom-graphic-modal-desktop.png`
                      : `${CLOUDFRONT_PREFIX}/credits/locale/en-us/resource-type/image/variation/mobile/custom-graphic-modal-mobile.png`,
                    alt: t(creditsBundle, 'creditsPage_customGraphic_modal_image_alt'),
                  },
                  title: t(creditsBundle, 'creditsPage_customGraphic_modal_title'),
                  copy: t(creditsBundle, 'creditsPage_customGraphic_modal_copy'),
                  tagline: t(creditsBundle, 'creditsPage_customGraphicModal_tagline'),
                  footerContent: {
                    iconName: 'info',
                    link: {
                      linkCopy: t(creditsBundle, 'creditsPage_customGraphic_footer_link_label'),
                      linkHref: t(creditsBundle, 'creditsPage_customGraphic_footer_link_href'),
                    },
                    copy: t(creditsBundle, 'creditsPage_customGraphic_footer_paragraph'),
                  },
                }}
              />
            )}

            <PageSection>
              <DynamicList {...segmentsAssets[activeTab].dynamicList} activeTab={activeTab} />
            </PageSection>

            {segmentsAssets[activeTab].secondaryBentoCards && (
              <BentoCards
                onBentoModalChange={handleOpenBentoCard}
                label={t(creditsBundle, `creditsPage_segments_bentoCards_label_${activeTab + 1}`)}
                title={t(creditsBundle, `creditsPage_segments_bentoCards_title_${activeTab + 1}`)}
                titleSize={activeTab === 1 ? HEADING_TAG.H5 : HEADING_TAG.H4}
                description={t(creditsBundle, `creditsPage_segments_bentoCards_textContent_${activeTab + 1}`)}
                tooltipAttributes={
                  activeTab !== CARBON_REMOVAL_TAB_INDEX
                    ? [
                        {
                          description: t(
                            creditsBundle,
                            `creditsPage_segments_bentoCards_tooltip_content_${activeTab + 1}`
                          ),
                          cta: {
                            label: t(
                              creditsBundle,
                              `creditsPage_segments_bentoCards_tooltip_linkLabel_${activeTab + 1}`
                            ),
                            icon: 'chevronTopRight',
                            url:
                              t(creditsBundle, `creditsPage_segments_bentoCards_tooltip_link_${activeTab + 1}`) || '#',
                          },
                        },
                      ]
                    : undefined
                }
                tooltipTheme={SECTION_THEME.Light}
                firstCard={{
                  label: t(creditsBundle, `creditsPage_segments_bentoCards_firstCard_label_${activeTab + 1}`),
                  title: t(creditsBundle, `creditsPage_segments_bentoCards_firstCard_title_${activeTab + 1}`),
                  image: {
                    src: segmentsAssets[activeTab].secondaryBentoCards.firstCard,
                    alt: t(creditsBundle, `creditsPage_segments_bentoCards_firstCard_altText_${activeTab + 1}`),
                  },
                  modal: {
                    label: t(creditsBundle, `creditsPage_segments_bentoCards_firstCard_label_${activeTab + 1}`),
                    title: t(creditsBundle, `creditsPage_segments_bentoCards_firstCard_title_${activeTab + 1}`),
                    image: {
                      src: segmentsAssets[activeTab].secondaryBentoCards.firstCardModal,
                      alt: t(creditsBundle, `creditsPage_segments_bentoCards_firstCard_altText_${activeTab + 1}`),
                      ...(activeTab === 0 && {
                        imageTaglineOne: t(creditsBundle, 'creditsPage_segments_bentoCards_firstCard_firstTagline_1'),
                        imageTaglineTwo: t(creditsBundle, 'creditsPage_segments_bentoCards_firstCard_secondTagline_1'),
                      }),
                    },
                    modalContent: t(
                      creditsBundle,
                      `creditsPage_segments_bentoCards_firstCard_modalContent_${activeTab + 1}`
                    ),
                    link: [RESTORATION_TAB_INDEX, CARBON_REMOVAL_TAB_INDEX].includes(activeTab)
                      ? {
                          url: t(creditsBundle, `creditsPage_segments_bentoCards_firstCard_modalLink_${activeTab + 1}`),
                          label: t(
                            creditsBundle,
                            `creditsPage_segments_bentoCards_firstCard_modalLinkLabel_${activeTab + 1}`
                          ),
                          icon: activeTab === RESTORATION_TAB_INDEX ? 'library' : undefined,
                          isSource: false,
                        }
                      : undefined,
                  },
                }}
                secondCard={
                  segmentsAssets[activeTab].secondaryBentoCards.secondCardModal
                    ? ({
                        label: t(creditsBundle, `creditsPage_segments_bentoCards_secondCard_label_${activeTab + 1}`),
                        title: t(creditsBundle, `creditsPage_segments_bentoCards_secondCard_title_${activeTab + 1}`),
                        image: {
                          src: segmentsAssets[activeTab].secondaryBentoCards.secondCard,
                          alt: t(creditsBundle, `creditsPage_segments_bentoCards_secondCard_altText_${activeTab + 1}`),
                        },
                        ...(segmentsAssets[activeTab].secondaryBentoCards.secondCardModal && {
                          modal: {
                            image: {
                              src: segmentsAssets[activeTab].secondaryBentoCards.secondCardModal,
                              alt: t(
                                creditsBundle,
                                `creditsPage_segments_bentoCards_secondCard_altText_${activeTab + 1}`
                              ),
                            },
                            label: t(
                              creditsBundle,
                              `creditsPage_segments_bentoCards_secondCard_label_${activeTab + 1}`
                            ),
                            title: t(
                              creditsBundle,
                              `creditsPage_segments_bentoCards_secondCard_title_${activeTab + 1}`
                            ),
                            modalContent: t(
                              creditsBundle,
                              `creditsPage_segments_bentoCards_secondCard_modalContent_${activeTab + 1}`
                            ),
                            link: {
                              url: t(
                                creditsBundle,
                                `creditsPage_segments_bentoCards_secondCard_modalLink_${activeTab + 1}`
                              ),
                              label: t(
                                creditsBundle,
                                `creditsPage_segments_bentoCards_secondCard_modalLinkLabel_${activeTab + 1}`
                              ),
                              icon: 'infoOutlined',
                              ...(activeTab === RESTORATION_TAB_INDEX && {
                                icon: 'library',
                                isSource: false,
                              }),
                            },
                          },
                        }),
                      } as BentoCardContent)
                    : {
                        src: segmentsAssets[activeTab].secondaryBentoCards.secondCard,
                        alt: t(creditsBundle, `creditsPage_segments_bentoCards_secondCard_altText_${activeTab + 1}`),
                      }
                }
              />
            )}
          </div>
        </PageSection>
      </div>

      <AdvocatesComponent
        dataTrackingLabel="Advocates"
        isStandAlone
        brandTabs={{
          options: [
            {
              brandIconImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-advocates-logo-1.svg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateHeadshot_altText-1'),
              },
              profileImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-advocates-headshot-1.jpg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateHeadshot_altText-1'),
              },
              profileName: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateName-1'),
              profileOccupation: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateTitle-1'),
            },
            {
              brandIconImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-advocates-logo-2.svg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateHeadshot_altText-2'),
              },
              profileImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-advocates-headshot-2.jpg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateHeadshot_altText-2'),
              },
              profileName: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateName-2'),
              profileOccupation: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateTitle-2'),
            },
            {
              brandIconImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-advocates-logo-3.svg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateHeadshot_altText-3'),
              },
              profileImage: {
                src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-advocates-headshot-3.jpg`,
                alt: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateHeadshot_altText-3'),
              },
              profileName: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateName-3'),
              profileOccupation: t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateTitle-3'),
            },
          ],
        }}
        quotes={[
          t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateQuote-1'),
          t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateQuote-2'),
          t(creditsBundle, 'creditsPage_heroBanner_advocates_advocateQuote-3'),
        ]}
        backgroundImage={{
          src: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.IMAGE)}/credits-advocates-1.jpeg`,
          alt: t(creditsBundle, 'creditsPage_heroBanner_advocates_background_image_altText'),
        }}
      />
      <Outro
        outroTitle={t(creditsBundle, 'creditsPage_segments_outro_secondTitle')}
        description={t(creditsBundle, 'creditsPage_segments_outro_description')}
        ctas={[
          {
            label: t(creditsBundle, 'creditsPage_segments_outro_firstCtaLabel'),
            url: ROUTES.DEBORAH_FAQ,
          },
          {
            label: t(creditsBundle, 'creditsPage_segments_outro_secondCtaLabel'),
            url: ROUTES.DEBORAH_INTEREST,
          },
        ]}
        link={{
          label: t(creditsBundle, 'creditsPage_segments_outro_linkLabel'),
          url: `${getCreditsAssetRoot(isDesktop, MEDIA_TYPE.PDF)}/California%20AB%201305%20Disclosure.pdf`,
        }}
      />
    </>
  );
};

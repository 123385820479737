import { FC, useState } from 'react';

import variables from '@amzn/sss-website-theme/dist/theme/index.module.scss';

import { Quote, Tagline } from 'src/components/atoms';
import {
  ModalFooterContentProps,
  ModalFooterContent,
  MODAL_VARIANT,
  ModalWrapper,
  ModalBodyScroll,
} from 'src/components/common';

import styles from './ModalQuoteContent.module.scss';

export interface QuoteContent {
  tagline: string;
  quote: string;
  footerContent?: ModalFooterContentProps;
}
export interface ModalQuoteContentProps {
  isVisible: boolean;
  close: () => void;
  quoteContent: QuoteContent;
}

/**
 * Content component for the ModalWrapper.tsx, specifically for statss of elements.
 * @param stats - The header and stats of elements to be displayed in the modal content.
 */
export const ModalQuoteContent: FC<ModalQuoteContentProps> = ({ isVisible, close, quoteContent }) => {
  const [dialogHeight, setDialogHeight] = useState<number | null>(null);
  const [footerHeight, setFooterHeight] = useState<number | null>(null);

  return (
    <ModalWrapper
      onDialogHeightChange={setDialogHeight}
      isVisible={isVisible}
      close={close}
      variant={MODAL_VARIANT.quote}
      footer={quoteContent.footerContent && <ModalFooterContent {...quoteContent.footerContent} />}
      onFooterHeightChange={setFooterHeight}
    >
      {isVisible && (
        <div className={styles.contentWrapper}>
          <ModalBodyScroll
            className={styles.modalQuoteContainer}
            containerHeight={dialogHeight}
            isVisible={isVisible}
            footerSize={footerHeight}
          >
            <Tagline className={styles.tagline}>{quoteContent.tagline}</Tagline>
            <Quote
              className={styles.quote}
              copy={quoteContent.quote}
              baseColor="rgba(0, 97, 97, 0.50)"
              paintColor={variables.greenPrimary}
            />
          </ModalBodyScroll>
        </div>
      )}
    </ModalWrapper>
  );
};
